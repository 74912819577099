import React, { Component, Fragment } from 'react'
import PhoneInput from 'react-phone-input-2'
import LanguageIcon from '@material-ui/icons/Language'
import Flag from 'react-world-flags'

import {
    formatUrl,
    cleanTheWebSiteUrlAndReturnPlainURL
} from '../../../utils/misc'

export default class SupplierFilter extends Component {

    componentDidMount() {
        this.props.activeTab(this.props.currentTab)
    }

    render() {

        let showMobileOrTelNo = false
        const {
            tabs,
            number,
            website,
            currentTab,
            numberExtra
        } = this.props
        if (numberExtra || number) {
            showMobileOrTelNo = true
        }
        const formatPhoneNumber = phone => "+" + phone.substring(0, 2) + " " + "(" + phone.substring(2, 3) + ")" + " " + phone.substring(3, 6) + " " + phone.substring(6, 9) + " " + phone.substring(9, 12);

        return (
            <section className="filter filter--supplier-profile">
                <div className="supplier-profile__contact-details">
                    {(number || numberExtra) && (
                        <div className="supplier__telephone-container">
                            <a href={`${'tel:' + (number || numberExtra)}`}>
                                {(() => {
                                    if (number.substring(0, 2) === "61" || numberExtra.substring(0, 2) === "61") {
                                        return <Fragment> <Flag code={'AU'} /> {formatPhoneNumber(number)} </Fragment>
                                    } else {
                                        return <PhoneInput
                                            country={'au'}
                                            color="primary"
                                            disabled="true"
                                            value={number || numberExtra}
                                        />
                                    }

                                })()}
                            </a>
                        </div>
                    )}
                    {website && (
                        <a target="_blank" href={website ? `${formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(website))}` : ''} className="supplier-profile__website">
                            {/* <img src={wwwIcon} alt="www icon" />Website */}
                            <LanguageIcon className="customIconsStyle" />Website
                        </a>
                    )}
                </div>
                <ul className="supplier-profile__tabs">
                    {tabs.map((tab, key) => <li key={key} onClick={e => this.props.goToTab(key)} className={key === currentTab ? "supplier-profile__list supplier-profile__list--active" : "supplier-profile__list"}>{tab}</li>)}
                </ul>
            </section>
        )
    }
}