/*
 * Actions
 */
export const ActionTypes = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    SET_USER_TYPE: 'SET_USER_TYPE',  
    GOTO_SECONDARY: 'GOTO_SECONDARY',  
    RESET_PASSWORD: 'RESET_PASSWORD',
    GOTO_NEXT_STEP: 'GOTO_NEXT_STEP',
    CHANGE_STEP_USER: 'CHANGE_STEP_USER',
    SET_NEW_USER_TYPE: 'SET_NEW_USER_TYPE',    
    REGISTRATION_COMPLETED: 'REGISTRATION_COMPLETED',
    SET_SECONDARY_USER_TYPE: 'SET_SECONDARY_USER_TYPE',    
}
