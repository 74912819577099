import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { TextField } from '@material-ui/core'

import Header from '../Global/Header'
import removeAccents from 'remove-accents'

import { 
    sessionEnd,
    checkLoginStatus, 
    clearLocalStorage,
    updateTheDisplayNameSessionInAction ,
    sendRevokeTokenRequestForLoginCredentialsChanges
} from '../../actions/sessionActions'
import { 
    supplierProfileContactFormUpdate, 
    retrieveAllSupplierContactInformation, 
    postCollectedSupplierContactInformation 
} from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

class ContactInformation extends Component {

    constructor(props) {
        super(props)
        this.topOfPageRef = React.createRef()
        this.state = { 
            values: "",
            telNo: "",
            surname: "",
            mobileNo: "",
            firstName: "",        
            emailAddress: "",
            originalUserEmailAddress: "",
            errorFields: {           
                surname: false,
                firstName: false,
                emailAddress: false
            },
            touched: {
                surname: false,
                firstName: false,
                emailAddress: false            
            },        
            errorsInTheForm: false,
            successInTheForm: false,
            loggedInRealStatus: true
        }
    }

    componentDidMount () {
        setTimeout(() => {
            if (this.topOfPageRef && this.topOfPageRef.current) this.topOfPageRef.current.scrollIntoView()
        }, 100)
        this.props.checkLoginStatus().then(data => {
            if (!data) {
                this.setState({ loggedInRealStatus: false })
            } else {
                this.props.retrieveAllSupplierContactInformation().then(data => {   
                    if (data !== null) {
                        this.setState({
                            telNo: data.tel_no,                            
                            surname: removeAccents(data.surname),
                            mobileNo: data.mobile_no,
                            firstName: removeAccents(data.first_name),
                            emailAddress: removeAccents(data.email_address),
                            originalUserEmailAddress: removeAccents(data.email_address),                        
                            errorsInTheForm: (!data.first_name || !data.surname || !data.email_address ) ? true : false
                        })                        
                    }          
                })    
            }                 
        })
    }

    back = e => {
        e.preventDefault()
        const {
            telNo, 
            surname,
            mobileNo,
            firstName,
            emailAddress                                    
        } = this.state
        if (this.props.formUpdated) {
            if (!this.state.errorsInTheForm) {  
                const postData = {
                    supplierContactFirstName: removeAccents(firstName),
                    supplierContactSurame: removeAccents(surname),
                    supplierContactEmail: removeAccents(emailAddress),
                    supplierContactTelno: telNo,
                    supplierContactMobileNo: mobileNo
                } 
                this.props.postCollectedSupplierContactInformation(postData).then(data => {
                    if (data.status === "supplier_contact_information_updated") {
                        this.props.showNotification('Your contact information have been updated!.', 'success')
                        this.props.prevStep()
                    } else {
                        this.props.showNotification('Something went wrong! Please contact support...', 'error')
                        setTimeout(() => this.props.hideNotification(), 5000)                
                    }
                })        
            }
        } else {
            this.props.prevStep()
        }        
    }

    updateFormHandler = field => event => {  
        if ((field !== 'mobileNo') && (field !== 'telNo')) {
            this.setState({ 
                [field]: event.target.value, 
                errorFields: {
                    [field]: (!event.target.value) ? true : false
                },
                errorsInTheForm: (!event.target.value) ? true : false
            })                        
            this.props.supplierProfileContactFormUpdate([field], event.target.value)         
        } else {
            this.setState({ [field]: event })                        
            this.props.supplierProfileContactFormUpdate([field], event)         
        }
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    saveAndContinue = e => {
        e.preventDefault()
        const {
            telNo, 
            surname,
            mobileNo,
            firstName,
            emailAddress,
            originalUserEmailAddress                                    
        } = this.state        
        if (this.props.formUpdated) {
            if (!this.state.errorsInTheForm) {  
                const postData = {
                    supplierContactFirstName: firstName,
                    supplierContactSurame: surname,
                    supplierContactEmail: emailAddress,
                    supplierContactTelno: telNo,
                    supplierContactMobileNo: mobileNo
                }        
                this.props.postCollectedSupplierContactInformation(postData).then(data => {
                    if (originalUserEmailAddress == emailAddress) {
                        if (data.status === "supplier_contact_information_updated") {
                            this.props.updateTheDisplayNameSessionInAction(postData.supplierContactFirstName)
                            this.props.showNotification('Your contact information have been updated!.', 'success')
                            this.props.nextStep()
                        } else {
                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                            setTimeout(() => this.props.hideNotification(), 2500)                
                        }    
                    } else {                        
                        this.props.sendRevokeTokenRequestForLoginCredentialsChanges(emailAddress)
                        this.props.showNotification('Your login information have been updated! Please wait while until we update your account.', 'success')                                                
                        // LocalStorageService.updateLocalStorageSingleItem('prevUrl', '/profile')
                        setTimeout(() => {                            
                            // this.props.clearLocalStorage()
                            // window.location.href = "/gallery"          
                            this.props.hideNotification()                                      
                            this.props.nextStep()
                        }, 2500)
                    }
                })        
            }
        } else {
            this.props.nextStep()
        }
    }

    render() {

        const {  
            loading 
        } = this.props

        const { 
            values,
            telNo,  
            touched,          
            surname,
            mobileNo, 
            firstName,
            errorFields,
            emailAddress,
            errorsInTheForm,
            loggedInRealStatus           
        } = this.state 
        
        if (!loggedInRealStatus) return <Redirect to={'/'} />

        return (
            <>
                <Header />
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div> 
                <div ref={this.topOfPageRef} className="profile__contact-info">
                    <div className="profile__section">
                        <span className="profile__section-content-tips">* Indicates a required field</span>
                        <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Tell us a little about yourself</h6>
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile">                                
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--fullwidth two-grid-view-full-width-wrapper">
                                            <div className="two-grid-view">
                                                <TextField
                                                    fullWidth
                                                    margin="small"                                                
                                                    variant="outlined"                                                     
                                                    label="Firstname"   
                                                    inputProps={{style: {fontSize: 12}}}                                                
                                                    onBlur={this.handleBlur('firstName')}                                                
                                                    onChange={this.updateFormHandler('firstName')}     
                                                    value={values.contactPersonFirstName || firstName}                       
                                                    InputLabelProps={{style: {fontSize: 12}}, { required: true }}                                                                                                 
                                                    error={((touched.firstName && !firstName) || (errorFields.firstName))}   
                                                />
                                                {((touched.firstName && !firstName) || (errorFields.firstName)) && <span className="error">First Name is required!</span>}
                                            </div>
                                            <div className="two-grid-view">
                                                <TextField
                                                    fullWidth
                                                    margin="small"                                                                                                
                                                    label="Surname"   
                                                    variant="outlined"                                                     
                                                    inputProps={{style: {fontSize: 12}}}                                                
                                                    onBlur={this.handleBlur('surname')}                                                
                                                    onChange={this.updateFormHandler('surname')}     
                                                    value={values.contactPersonLastName || surname}                    
                                                    InputLabelProps={{style: {fontSize: 12}}, { required: true }}                                                                                                    
                                                    error={((touched.surname && !surname) || (errorFields.surname))}   
                                                />
                                                {((touched.surname && !surname) || (errorFields.surname)) && <span className="error">Surname is required!</span>}
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <div className="profile__input-container">
                                            <TextField
                                                fullWidth
                                                margin="small"                                                                                                                                                
                                                variant="outlined"                                                     
                                                label="Email Address"   
                                                inputProps={{style: {fontSize: 12}}}                                                
                                                onBlur={this.handleBlur('emailAddress')}                                                
                                                onChange={this.updateFormHandler('emailAddress')}     
                                                value={values.contactPersonEmail || emailAddress}                                                                                                                        
                                                InputLabelProps={{style: {fontSize: 12}}, { required: true }}
                                                error={((touched.emailAddress && !emailAddress) || (errorFields.emailAddress))}   
                                            />
                                            {((touched.emailAddress && !emailAddress) || (errorFields.emailAddress)) && <span className="error">Email Address is required!</span>}
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">                                        
                                        <div className="eb-forms--not-fullwidth">
                                            <PhoneInput
                                                value={telNo}
                                                country={'au'}
                                                color="primary"  
                                                variant="outlined"
                                                placeholder="Tel: No"                                     
                                                onChange={this.updateFormHandler('telNo')} 
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--not-fullwidth">
                                            <PhoneInput
                                                country={'au'}
                                                color="primary"
                                                value={mobileNo}
                                                variant="outlined"
                                                placeholder="Mobile: No"       
                                                onChange={this.updateFormHandler('mobileNo')} 
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="profile__button-container profile__button-container--single">
                            <button className="btn btn-gold profile__button profile__button-next" onClick={this.saveAndContinue} disabled={errorsInTheForm}>{'Save'}</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    userId: session.userId,
    loading: profile.loading,    
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierContactInfomration.formUpdated
})
  
const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(sessionEnd()),
    hideNotification: () => dispatch(hideNotification()),
    checkLoginStatus: () => dispatch(checkLoginStatus()),   
    clearLocalStorage: () => dispatch(clearLocalStorage()),
    retrieveAllSupplierContactInformation: () => dispatch(retrieveAllSupplierContactInformation()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),             
    supplierProfileContactFormUpdate: (field, value) => dispatch(supplierProfileContactFormUpdate(field, value)),        
    updateTheDisplayNameSessionInAction: displayName => dispatch(updateTheDisplayNameSessionInAction(displayName)),
    postCollectedSupplierContactInformation: postData => dispatch(postCollectedSupplierContactInformation(postData)),    
    sendRevokeTokenRequestForLoginCredentialsChanges: email => dispatch(sendRevokeTokenRequestForLoginCredentialsChanges(email))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation)