import React, { Component } from 'react'
import { connect } from "react-redux"
import arrayMove from "array-move"
import { DndProvider } from "react-dnd"
import Select from 'react-select'
import HTML5Backend from "react-dnd-html5-backend"
import TouchBackend from "react-dnd-touch-backend"
import cuid from "cuid"

import { retrieveAllEventTypes } from '../../actions/galleryActions'
import { checkLoginStatus } from '../../actions/sessionActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"
import {
    loadSupplierCategories, 
    loadSupplierMainServices,
    processSupplierAddEvent,
    processSupplierPhotosUpload,
    supplierProfileEventInformationFormUpdate,
} from '../../actions/profileActions'

import ImageList from '../../reusables/ImageList/ImageList'
import Dropzone from '../../reusables/Dropzone/Dropzone'

import { isTouchDevice } from '../../utils/misc'

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend

class AddCategoryPhotos extends Component {

    constructor() {
        super()
        this.state = {
            eventTypeId: "",
            allSuppliers: [],
            imagesAll: false,
            selectedImages: [],
            mainServices: [],                                         
            supplierTypes: [],
            subServicesInfo: [],
            mainServiceId: null,
            supplierSubTypes: [],    
            imagesProfile: true,
            connectedSuppliers: [],
            folderInsideImages: [],
            photographerCredits: "",
            isSupplierTypeDisabled: false,
            selectedImagesPreviews: [],
            errorFields: {
                serviceType: true,
            },
            touched: {
                serviceType: false,
            },
            imagesCurrentCountUploaded: [],
            photosUploadingInProcess: false,
        }
    }

    componentDidMount() {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                setTimeout(() => { this.setState({ overlay: false, selectedImages: [], photosUploadingInProcess: false, imagesCurrentCountUploaded: [] }) }, 100)
                
                this._loadServiceInformation()
            }
        })
    }

    _loadServiceInformation = () => {
        this.props.loadSupplierTypes().then(data => {    
            this.setState({ supplierTypes: data })
            // this.props.retrieveAllSupplierBusinessInformation().then(supplierInfo => {  
            //     if (supplierInfo !== null) {
            //         this.props.loadSupplierMainServices().then(mainServices => this.setState({ mainServices }))
            //         this.props.loadSubCategoriesForParentCategory(supplierInfo.supplier_type_id).then(data => {
            //             if (data.length > 0) {                                     
            //                 this.setState({ 
            //                     supplierSubTypes: data,
            //                     isSupplierTypeDisabled: true,
            //                     supplierType: supplierInfo.supplier_type_id                                         
            //                 }) 
            //             }
            //         })
            //     }          
            // })        
        })
    }

    componentWillUnmount() {
        this.setState({ selectedImages: [], photosUploadingInProcess: false, imagesCurrentCountUploaded: [] })
    }

    updateFormHandler = field => event => {
        if ((event) && (event.target) && (event.target.value)) {
            this.setState({ [field]: event.target.value })
        }
        // this.props.supplierProfileEventInformationFormUpdate([field], event.target.value)
    }

    handleBlur = field => event => {
        this.setState({
            touched: {
                ...this.state.touched,
                [field]: true
            },
            errorFields: {
                ...this.state.errorFields,
                [field]: (!event.target.value) ? true : false
            },
        })
    }

    removeSelectedImages = index => {
        const { selectedImages } = this.state
        if (selectedImages && selectedImages.length > 0) {
            this.setState(prev => ({
                selectedImages: prev.selectedImages.filter(el => el.id !== index),
                selectedImagesPreviews: prev.selectedImagesPreviews.filter(el => el.id !== index),
            }))
        }
    }

    onDrop = acceptedFiles => {
        acceptedFiles.map((file, i) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = e => {
                const image = new Image
                image.src = reader.result
                image.onload = () => {
                    const imageObjectForSubmit = {
                        id: cuid(),
                        current_order: i + 1,
                        src: file,
                        width: image.width,
                        height: image.height,
                    }
                    const imageObjectPreview = {
                        id: cuid(),
                        current_order: i + 1,
                        src: reader.result
                    }
                    this.setState({
                        selectedImages: this.state.selectedImages.concat(imageObjectForSubmit),
                        selectedImagesPreviews: this.state.selectedImagesPreviews.concat(imageObjectPreview)
                    })
                }
            }
        })
    }

    moveImage = (dragIndex, hoverIndex) => {
        const { selectedImages, selectedImagesPreviews } = this.state
        const updatedImagesSet = arrayMove(selectedImages, hoverIndex, dragIndex)
        const updatedImagesSetPreview = arrayMove(selectedImagesPreviews, hoverIndex, dragIndex)
        const modifiedImageSet = updatedImagesSet.map((eachImage, i) => {
            eachImage.current_order = i + 1
            return eachImage
        })
        const modifiedImageSetPreview = updatedImagesSetPreview.map((eachImage, i) => {
            eachImage.current_order = i + 1
            return eachImage
        })
        this.setState({ selectedImages: modifiedImageSet, selectedImagesPreviews: modifiedImageSetPreview })
    }

    addEvent = () => {
        let postData = {}
        let notToProceedTheForm = false
        Object.values(this.state.errorFields).map(eachField => {
            if (eachField) notToProceedTheForm = true
        })
        if ((!notToProceedTheForm) || (this.state.selectedImages > 0)) {
            const {
                eventTypeId,
                otherCredits,
                eventLocation,
                imagesAppearIn,
                selectedImages,
                eventDescription
            } = this.state
            if (imagesAppearIn === 'all') {
                postData = {
                    eventTypeId: eventTypeId,
                    otherCredits: otherCredits,
                    eventLocation: eventLocation,
                    imagesAppearIn: imagesAppearIn,
                    eventDescription: eventDescription,
                    imageCount: (selectedImages && selectedImages.length !== 0) ? selectedImages.length : 0
                }
            } else {
                postData = {
                    otherCredits: otherCredits,
                    eventLocation: eventLocation,
                    imagesAppearIn: imagesAppearIn,
                    eventDescription: eventDescription,
                    imageCount: (selectedImages && selectedImages.length !== 0) ? selectedImages.length : 0
                }
            }
            this.props.processSupplierAddEvent(postData, selectedImages).then(data => {
                if (data.status === 'supplier_event_information_added') {
                    this.props.showNotification('Your event information has been added! Please wait while until we upload the photos.', 'success')
                    this.setState({
                        eventTypeId: "",
                        otherCredits: "",
                        eventLocation: "",
                        imagesAppearIn: "",
                        photographerCredits: "",
                        errorFields: {
                            eventLocation: false,
                            eventDescription: false
                        },
                        touched: {
                            eventLocation: false,
                            eventDescription: false
                        },
                        photosUploadingInProcess: true
                    })
                    setTimeout(() => this.props.hideNotification(), 6000)
                    if (selectedImages.length > 0) {
                        selectedImages.map((eachFile, i) => {
                            this.props.processSupplierPhotosUpload(eachFile.src, eachFile.current_order, eachFile.id, eachFile.width, eachFile.height, data.eventId, eventTypeId, data.currentTimeStamp).then(data => {
                                if (data.imageurl !== '') {
                                    this.setState({ imagesCurrentCountUploaded: this.state.imagesCurrentCountUploaded.concat(data.imageurl) })
                                }
                                if (selectedImages.length - 1 === i) {
                                    setTimeout(() => window.location.href = "/add-event", 3000)
                                }
                            })
                        })
                    }
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 6000)
                }
            }).catch(() => {
                this.props.showNotification('Something went wrong! Please contact support...', 'error')
                setTimeout(() => this.props.hideNotification(), 6000)
            })
        }
    }

    render() {

        const { loading } = this.props

        const {
            touched,
            imagesAll,
            eventTypeId,
            errorFields,
            supplierType,
            supplierTypes,
            imagesProfile,
            selectedImages,
            supplierSubTypes,
            selectedImagesPreviews,
            photosUploadingInProcess,
            imagesCurrentCountUploaded,            
            isSupplierTypeDisabled,
        } = this.state

        let notToProceedTheForm = false
        let serviceTypeObject = {}      

        if (supplierType) {
            serviceTypeObject = supplierTypes.filter(eachSupplierCategory => eachSupplierCategory.value === supplierType)
        }

        return (
            <>
                {(!photosUploadingInProcess) &&
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>}
                {(photosUploadingInProcess) &&
                <div className={(imagesCurrentCountUploaded.length !== selectedImages.length) ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Please wait while we upload the photos...</span></div>
                    </div>
                </div>}
                <div className="profile__add-events profile__add-events--banner-photos">
                    <div className="profile__section">
                        <h5 className="profile__sub-title">Upload banner photos</h5>
                        <div className="profile__event-container">
                            <div className="profile__first-event-container">
                                <h6 className="profile__sub-title">Choose up to 10 photos per category</h6>
                                <span className="profile__section-content-tips">* Indicates a required field</span>
                            </div>
                        </div>
                        <div id="addEventSection" className="profile__section-content">
                            <div className="profile__form-container">
                                <div className="eb-forms eb-forms--profile">                                    
                                    <div className="add__events-internal-wrapper">
                                        <span className="profile__section-content-tips">* Maximum file upload: 5MB (JPG / PNG)</span>
                                        <div className="eb-forms__form-group eb-forms__form-group--photo-upload">
                                            <div className="dropzoneContainer">
                                                <Dropzone onDrop={this.onDrop} accept={"image/*"} />
                                            </div>
                                        </div>
                                        {selectedImagesPreviews && selectedImagesPreviews.length > 0 && (
                                            <h6 className="text-center">Drag the Images to change positions</h6>
                                        )}
                                        <DndProvider backend={backendForDND}>
                                            <ImageList images={selectedImagesPreviews} moveImage={this.moveImage} removeImage={this.removeSelectedImages} />
                                        </DndProvider>
                                        <div className="eb-forms__form-group">
                                            <label htmlFor="contact-person-first-name">Supplier Categories</label>
                                            <div className="eb-forms--not-fullwidth">
                                                <div className="container">
                                                    <Select 
                                                        isClearable  
                                                        options={supplierTypes} 
                                                        value={serviceTypeObject}                      
                                                        className="searchable-dropdown"                                                                                                                                                                                    
                                                        isDisabled={isSupplierTypeDisabled}                                                               
                                                        onChange={this.updateFormHandler('supplierType')}
                                                    />
                                                </div>      
                                            </div>                                                                           
                                        </div>
                                        {
                                            supplierType && 
                                            <div className="eb-forms__form-group">
                                                <label>Supplier sub-categories</label>
                                                <div className="profile__supplier-subcategories-container eb-forms--not-fullwidth">
                                                    {
                                                        supplierSubTypes && 
                                                        supplierSubTypes.map((subCategory, i) => (
                                                            <div className="profile__supplier-subcategories eb-checkbox" key={subCategory.category_id}>
                                                                <input 
                                                                    type='checkbox' 
                                                                    id={subCategory.category_id}                                                            
                                                                    onChange={() => this.onSubCategoryCheck(subCategory.category_id)}                                                                
                                                                />
                                                                <span className="checkmark"></span>
                                                                <label htmlFor={subCategory.category_id}>{subCategory.category_name}</label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>                                         
                                        }
                                        <div className="profile__button-container profile__button-container--single">
                                            <button className="btn btn-gold profile__button profile__button-next" disabled={notToProceedTheForm || selectedImages.length < 1} onClick={this.addEvent}>Upload Images</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile__tips-section"></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierEventInformation.formUpdated
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    hideNotification: () => dispatch(hideNotification()),
    loadSupplierTypes: () => dispatch(loadSupplierCategories()), 
    retrieveAllEventTypes: () => dispatch(retrieveAllEventTypes()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    processSupplierAddEvent: (postObject, imageSet) => dispatch(processSupplierAddEvent(postObject, imageSet)),
    supplierProfileEventInformationFormUpdate: (field, value) => dispatch(supplierProfileEventInformationFormUpdate(field, value)),
    processSupplierPhotosUpload: (image, order, id, width, height, eventId, eventName, eventTypeId, currentTimeStamp) => dispatch(processSupplierPhotosUpload(image, order, id, width, height, eventId, eventName, eventTypeId, currentTimeStamp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCategoryPhotos)