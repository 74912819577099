import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import Select from 'react-select'
import Rodal from 'rodal'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core';
import { checkLoginStatus } from '../../actions/sessionActions'
import {
    retrieveAllCountries,
    retrieveAllStatesByCountry,
    retrieveAllCitiesByStateAndCountry
} from '../../actions/generalActions'
import {
    removeMainServiceAreas,
    loadSupplierMainServices,
    loadProfileServedCountries,
    postSupplierLocationInformationAction,
    retrieveAllSupplierBusinessInformation
} from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

import Header from '../Global/Header'
import clsx from 'clsx';

const useStyles = customTheme => ({
    checkBoxLabel: {
        marginLeft: '-8px',
    },
    checkboxRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#bf9000',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#bf9000',
        },
    },

});

const yesNoOptions = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
];

const continentSupplyOptions = [
    { value: 'true', label: 'Yes, I supply all over the specific continent.' },
    { value: 'false', label: 'No, I only supply to the specific county / multiple countries / state / cities.' },
];

const continentOptions = [
    { value: 'Africa', label: 'Africa' },
    { value: 'Antartica', label: 'Antartica' },
    { value: 'Asia', label: 'Asia' },
    { value: 'Europe', label: 'Europe' },
    { value: 'North America', label: 'North America' },
    { value: 'Oceania', label: 'Oceania' },
    { value: 'South America', label: 'South America' },
];

class Locations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: "",
            state: "",
            country: "",
            locations: [],
            gotoNext: false,
            servedLocations: [],
            cityAsSubmitValue: "",
            errorsInTheForm: true,
            stateAsSubmitValue: "",
            countryAsSubmitValue: "",
            loggedInRealStatus: true,
            modalBoxVisbileForProfileCompletion: false,
            yesNoOptions: yesNoOptions,
            continentSupplyOptions: continentSupplyOptions,
            globalSupplierSelect: yesNoOptions[0].value,
            continentWisSupplierSelect: continentSupplyOptions[0].value,
            continentNameSelect: continentOptions[0].value,
            addNewLocationButtonDisabled: false,
            onlyThisCountryChecked: false,
            onlyThisStateChecked: false,
        }
        // this.handleAddNewLocationButtonDisable = this.handleAddNewLocationButtonDisable.bind(this);
        // this.handleAddNewLocationButtonDisable();
    }

    componentWillMount() {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                this._loadProfileLocationInformation()
                this.props.retrieveAllCountries()
                this.props.retrieveAllCountries().then(allCountries => this.setState({ allCountries }))
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.globalSupplierSelect !== this.state.globalSupplierSelect ||
            prevState.continentWisSupplierSelect !== this.state.continentWisSupplierSelect ||
            prevState.country !== this.state.country ||
            prevState.onlyThisCountryChecked !== this.state.onlyThisCountryChecked ||
            prevState.state !== this.state.state ||
            prevState.onlyThisStateChecked !== this.state.onlyThisStateChecked ||
            prevState.city !== this.state.city
        ) {
            this.handleAddNewLocationButtonDisable();
        }
    }

    getLabelFromValue(obtainedValue, options) {
        let label = "";
        options.forEach(element => {
            if (element.value === obtainedValue) {
                label = element.label;
            }
        });
        return label;
    }

    _loadProfileLocationInformation = () => {
        this.props.retrieveAllSupplierBusinessInformation().then(supplierInfo => {
            if (supplierInfo !== null) {
                this.props.loadProfileServedCountries().then(servedLocations => this.setState({ servedLocations }))
            }
        })
    }

    hideModalBoxForBasicProfile = () => this.setState({ modalBoxVisbileForProfileCompletion: false })

    handleAddNewLocationButtonDisable = () => {
        //Enable button if globalSupplier is true
        if (this.state.globalSupplierSelect === 'true') {
            this.setState({ addNewLocationButtonDisabled: false })
        }

        //Enable button if globalsupplier = false and continentWisSupplierSelect is true and selected contintent is not empty 
        else if (this.state.globalSupplierSelect === 'false' &&
            this.state.continentWisSupplierSelect === 'true' &&
            this.state.continentNameSelect !== '') {
            this.setState({ addNewLocationButtonDisabled: false })
        }


        //Enable button if global supplier false and continentWisSupplierSelect is false and country is not "" and  onlyThisCountryChecked is true
        else if (this.state.globalSupplierSelect === 'false' &&
            this.state.continentWisSupplierSelect === 'false' &&
            this.state.country !== "" &&
            this.state.onlyThisCountryChecked === true) {
            this.setState({ addNewLocationButtonDisabled: false })
        }

        //Enable button if global supplier false and continentWisSupplierSelect is false and country is not "" and  onlyThisCountryChecked is false  and state is not "" and onlyThisStateChecked is true
        else if (this.state.globalSupplierSelect === 'false' &&
            this.state.continentWisSupplierSelect === 'false' &&
            this.state.country !== "" &&
            this.state.onlyThisCountryChecked === false &&
            this.state.state !== "" &&
            this.state.onlyThisStateChecked === true
        ) {
            this.setState({ addNewLocationButtonDisabled: false })
        }

        //Enable button if global supplier false and continentWisSupplierSelect is false and country is not "" and  onlyThisCountryChecked is false  and state is not "" and onlyThisStateChecked is false and city is not ""
        else if (this.state.globalSupplierSelect === 'false' &&
            this.state.continentWisSupplierSelect === 'false' &&
            this.state.country !== "" &&
            this.state.onlyThisCountryChecked === false &&
            this.state.state !== "" &&
            this.state.onlyThisStateChecked === false &&
            this.state.city !== ""
        ) {
            this.setState({ addNewLocationButtonDisabled: false })
        } else {
            this.setState({ addNewLocationButtonDisabled: true })
        }
    }

    handleChange = field => event => {
        this.setState({ [field]: event.value });
        this.handleAddNewLocationButtonDisable();
    }

    handleOnlyThisCountryCheckBoxChange = (event) => {
        this.setState({
            onlyThisCountryChecked: event.target.checked
        })
    }

    handleOnlyThisStateCheckBoxChange = (event) => {
        this.setState({
            onlyThisStateChecked: event.target.checked
        })
    }

    updateFormHandler = field => event => {
        if ((field === "city") || (field === "state") || (field === "country")) {
            if (event && event.value) {
                if (field === "country") {
                    this.setState({
                        [field]: event.value,
                        countryAsSubmitValue: event.label,
                        errorFields: {
                            [field]: (!event.value) ? true : false
                        },
                        errorsInTheForm: (!event.value) ? true : false
                    })
                    this.props.retrieveAllStatesByCountry(event.value).then(allStates => this.setState({ allStates }))
                }
                if (field === "state") {
                    this.setState({
                        [field]: event.value,
                        stateAsSubmitValue: event.label,
                        errorFields: {
                            [field]: (!event.value) ? true : false
                        },
                        errorsInTheForm: (!event.value) ? true : false
                    })
                    this.props.retrieveAllCitiesByStateAndCountry(this.state.country, event.value).then(allCities => this.setState({ allCities }))
                }
                if (field === "city") {
                    this.setState({
                        [field]: event.value,
                        cityAsSubmitValue: event.label,
                        errorFields: {
                            [field]: (!event.value) ? true : false
                        },
                        errorsInTheForm: (!event.value) ? true : false
                    })
                }
            } else {
                if (field === "country") {
                    this.setState({ allStates: [], allCities: [] })
                }
                if (field === "state") {
                    this.setState({ allCities: [] })
                }
                this.setState({ [field]: '' })
            }
        }
    }

    removeSelectedLocation = i => {
        const { locations } = this.state
        const filteredLocations = locations.filter((el, n) => i !== n)
        this.setState({ locations: filteredLocations })
    }

    removeSelectedMainLocation = mainLocation => {
        this.props.removeMainServiceAreas(mainLocation.id).then(() => {
            const { servedLocations } = this.state
            const filteredMainAreas = servedLocations.filter(eachMainArea => eachMainArea.id != mainLocation.id)
            this.setState({ servedLocations: filteredMainAreas })
            this.props.showNotification('Your location information have been updated!', 'success')
            setTimeout(() => this.props.hideNotification(), 6000)
        })
    }

    addNew = () => {
        const {
            city,
            state,
            country,
            cityAsSubmitValue,
            stateAsSubmitValue,
            countryAsSubmitValue,
            globalSupplierSelect,
            continentWisSupplierSelect,
            continentNameSelect,
            onlyThisCountryChecked,
            onlyThisStateChecked,
            addNewLocationButtonDisabled
        } = this.state

        // if (country && state && city) {
        // if (addNewLocationButtonDisabled === false) {
        const locationsCurrentSelection = {
            isGlobalSupplier: globalSupplierSelect,
            isContinentalSupplier: continentWisSupplierSelect,
            continent: continentNameSelect,
            isEntireCountrySupplier: onlyThisCountryChecked,
            isEntireStateSupplier: onlyThisStateChecked,
            city: cityAsSubmitValue,
            state: stateAsSubmitValue,
            country: countryAsSubmitValue,
        }
        this.setState({
            locations: this.state.locations.concat(locationsCurrentSelection),
            city: "",
            state: "",
            country: "",
            allCities: [],
            allStates: [],
            cityAsSubmitValue: "",
            stateAsSubmitValue: "",
            countryAsSubmitValue: "",
            globalSupplierSelect: yesNoOptions[0].value,
            continentWisSupplierSelect: continentSupplyOptions[0].value,
            continentNameSelect: continentOptions[0].value,
            addNewLocationButtonDisabled: false,
            onlyThisCountryChecked: false,
            onlyThisStateChecked: false,
        })
        // }
    }

    addNewLocations = e => {
        e.preventDefault()
        const { locations } = this.state
        console.log(JSON.stringify(locations));
        if (locations.length > 0) {
            // if (10 < 0) {
            this.props.postSupplierLocationInformationAction(JSON.stringify(locations)).then(data => {
                console.log(data);

                if (data.status === "supplier_location_information_updated") {
                    this.setState({
                        city: "",
                        state: "",
                        country: "",
                        allCities: [],
                        allStates: [],
                        cityAsSubmitValue: "",
                        stateAsSubmitValue: "",
                        countryAsSubmitValue: "",
                    })
                    this.props.showNotification('Your service area information have been updated!', 'success')
                    this.props.retrieveAllSupplierBusinessInformation().then(data => {
                        if (data && data.cover_image && data.company_bio && data.company_name && data.profile_photo && data.business_awards) {
                            this.props.loadSupplierMainServices().then(mainServices => {
                                if (mainServices.length > 0) {
                                    this.setState({ modalBoxVisbileForProfileCompletion: true })
                                    setTimeout(() => {
                                        this.props.hideNotification()
                                        this.setState({ gotoNext: true })
                                    }, 5000)
                                }
                            })
                        }
                    })
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                }

            })
        } else {
            this.setState({ gotoNext: true })
        }
    }

    render() {

        // console.log(this.state);

        if (this.state.gotoNext) {
            return <Redirect to={'/add-event'} />
        }

        const {
            city,
            state,
            country,
            locations,
            allCities,
            allStates,
            allCountries,
            servedLocations,
            cityAsSubmitValue,
            stateAsSubmitValue,
            countryAsSubmitValue,
            modalBoxVisbileForProfileCompletion,
            yesNoOptions,
            continentSupplyOptions,
            addNewLocationButtonDisabled,
            globalSupplierSelect,
            continentWisSupplierSelect,
            onlyThisCountryChecked,
            onlyThisStateChecked
        } = this.state
        const { loading } = this.props
        let countryObject = {}
        let cityObject = {}
        let stateObject = {}

        const {
            classes,
        } = this.props;

        if (country && allCountries) {
            countryObject = allCountries.filter(eachCountry => eachCountry.value === country)
            if (state && allStates) {
                stateObject = allStates.filter(eachState => eachState.value === state)
                if (city && allCities) {
                    cityObject = allCities.filter(eachCity => eachCity.value === city)
                }
            }
        }



        return (
            <>
                <Header />
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>
                <div className="profile__services-location">
                    <div className="profile__section">
                        {/* <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Select the countries / states / cities!</h6> */}
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile">
                                    <div className="eb-forms__form-group">
                                        <div>
                                            <div>
                                                <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Are you a Global Supplier ?</h6>
                                                <Select
                                                    defaultValue={yesNoOptions[0]}
                                                    value={{
                                                        value: globalSupplierSelect,
                                                        label: this.getLabelFromValue(globalSupplierSelect, yesNoOptions)
                                                    }}
                                                    className="searchable-dropdown"
                                                    // placeholder="Select the Country"
                                                    options={yesNoOptions}
                                                    onChange={this.handleChange('globalSupplierSelect')}
                                                />
                                            </div>

                                            {(globalSupplierSelect === "false") ? (
                                                <div>
                                                    <div>
                                                        <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Do you supply all over the continent ?</h6>
                                                        <Select
                                                            defaultValue={continentSupplyOptions[0]}
                                                            // value={countryObject}
                                                            className="searchable-dropdown"
                                                            // placeholder="Select the Country"
                                                            options={continentSupplyOptions}
                                                            onChange={this.handleChange('continentWisSupplierSelect')}
                                                        />
                                                    </div>

                                                    {(continentWisSupplierSelect === "true") ? (
                                                        <div>
                                                            <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Please select continent.</h6>
                                                            <Select
                                                                defaultValue={continentOptions[0]}
                                                                // value={countryObject}
                                                                className="searchable-dropdown"
                                                                // placeholder="Select the Country"
                                                                options={continentOptions}
                                                                onChange={this.handleChange('continentNameSelect')}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div>
                                                                <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Please select country.</h6>
                                                                {(allCountries && allCountries.length > 0) &&
                                                                    <div>
                                                                        <Select
                                                                            isClearable
                                                                            // isDisabled={country}
                                                                            value={countryObject}
                                                                            className="searchable-dropdown"
                                                                            placeholder="Select the Country"
                                                                            options={allCountries && allCountries}
                                                                            onChange={this.updateFormHandler('country')}
                                                                        />
                                                                        <FormControlLabel
                                                                            className={classes.checkBoxLabel}
                                                                            control={
                                                                                <Checkbox
                                                                                    className={classes.checkboxRoot}
                                                                                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                                                    icon={<span className={classes.icon} />}
                                                                                    checked={onlyThisCountryChecked}
                                                                                    onChange={this.handleOnlyThisCountryCheckBoxChange}
                                                                                    name="onlyThisCountryChecked"
                                                                                />
                                                                            }
                                                                            label="I supply to this whole country"
                                                                        />
                                                                    </div>

                                                                }
                                                            </div>

                                                            {(country !== '' && onlyThisCountryChecked === false) ? (

                                                                <div>
                                                                    <div>
                                                                        <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Please select state.</h6>
                                                                        {(allStates && allStates.length > 0 && onlyThisCountryChecked === false) &&
                                                                            <div>
                                                                                <Select
                                                                                    isClearable
                                                                                    // isDisabled={state}
                                                                                    value={stateObject}
                                                                                    placeholder="Select the State"
                                                                                    className="searchable-dropdown"
                                                                                    options={allStates && allStates}
                                                                                    onChange={this.updateFormHandler('state')}
                                                                                />
                                                                                <FormControlLabel
                                                                                    className={classes.checkBoxLabel}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            className={classes.checkboxRoot}
                                                                                            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                                                            icon={<span className={classes.icon} />}
                                                                                            checked={onlyThisStateChecked}
                                                                                            onChange={this.handleOnlyThisStateCheckBoxChange}
                                                                                            name="onlyThisStateChecked"
                                                                                        />
                                                                                    }
                                                                                    label="I supply to this whole state"
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                    {(state !== '' && onlyThisStateChecked === false) ? (
                                                                        <div>
                                                                            <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Please select city.</h6>
                                                                            {(allCities && allCities.length > 0 && onlyThisStateChecked === false) &&
                                                                                <Select
                                                                                    isClearable
                                                                                    // isDisabled={city}
                                                                                    value={cityObject}
                                                                                    placeholder="Select the City"
                                                                                    className="searchable-dropdown"
                                                                                    options={allCities && allCities}
                                                                                    onChange={this.updateFormHandler('city')}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : null}
                                            {/* GLOBAL SUPPLIER SHOW/HIDE ENDS */}
                                        </div>
                                        <div className="eb-forms--not-fullwidth">
                                            {/* <div className="eb__locations-dropdowns-container">
                                                {(allCountries && allCountries.length > 0) &&
                                                    <Select
                                                        isClearable
                                                        isDisabled={country}
                                                        value={countryObject}
                                                        className="searchable-dropdown"
                                                        placeholder="Select the Country"
                                                        options={allCountries && allCountries}
                                                        onChange={this.updateFormHandler('country')}
                                                    />
                                                }
                                            </div> */}
                                            {/* <div className="eb__locations-dropdowns-container">
                                                {(allStates && allStates.length > 0) &&
                                                    <Select
                                                        isClearable
                                                        isDisabled={state}
                                                        value={stateObject}
                                                        placeholder="Select the State"
                                                        className="searchable-dropdown"
                                                        options={allStates && allStates}
                                                        onChange={this.updateFormHandler('state')}
                                                    />
                                                }
                                            </div> */}
                                            {/* <div className="eb__locations-dropdowns-container">
                                                {(allCities && allCities.length > 0) &&
                                                    <Select
                                                        isClearable
                                                        isDisabled={city}
                                                        value={cityObject}
                                                        placeholder="Select the City"
                                                        className="searchable-dropdown"
                                                        options={allCities && allCities}
                                                        onChange={this.updateFormHandler('city')}
                                                    />
                                                }
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="profile__button-container profile__btn-container-locations">
                            {/* <button disabled={cityAsSubmitValue == "" || stateAsSubmitValue == "" || countryAsSubmitValue == ""} className="btn btn-gold profile__button profile__button-next" onClick={this.addNew}>{'Add new location'}</button> */}
                            <button
                                disabled={addNewLocationButtonDisabled}
                                // {(addNewLocationButtonDisabled === true) ? 'disabled' : ''} 
                                className="btn btn-gold profile__button profile__button-next"
                                onClick={this.addNew}>
                                {'Add new location'}
                            </button>
                        </div>
                        {
                            locations && locations.length > 0 && (
                                <div className="location-list">
                                    <div className={'sub__service-or-location-content-wrapper'}>
                                        {locations && locations.map((eachLocation, i) => {
                                            return (
                                                <>
                                                    <span className="close" onClick={() => this.removeSelectedLocation(i)}>x</span>
                                                    {(eachLocation.isGlobalSupplier === "true") ? (
                                                        <p>All over the world</p>
                                                    ) : (eachLocation.isGlobalSupplier === "false" &&
                                                        eachLocation.isContinentalSupplier === "true" &&
                                                        eachLocation.continent !== "") ? (
                                                        <p>All over {eachLocation.continent}</p>
                                                    ) : (eachLocation.isGlobalSupplier === "false" &&
                                                        eachLocation.isContinentalSupplier === "false" &&
                                                        eachLocation.country !== "" &&
                                                        eachLocation.isEntireCountrySupplier === true) ? (
                                                        <p>All over {eachLocation.country}</p>
                                                    ) : (eachLocation.isGlobalSupplier === "false" &&
                                                        eachLocation.isContinentalSupplier === "false" &&
                                                        eachLocation.country !== "" &&
                                                        eachLocation.isEntireCountrySupplier === false &&
                                                        eachLocation.state !== "" &&
                                                        eachLocation.isEntireStateSupplier === true) ? (
                                                        <p>All across {eachLocation.state} of {eachLocation.country}</p>
                                                    ) : (eachLocation.isGlobalSupplier === "false" &&
                                                        eachLocation.isContinentalSupplier === "false" &&
                                                        eachLocation.country !== "" &&
                                                        eachLocation.isEntireCountrySupplier === false &&
                                                        eachLocation.state !== "" &&
                                                        eachLocation.isEntireStateSupplier === false &&
                                                        eachLocation.city !== "") ? (
                                                        <p>{eachLocation.country} - ({eachLocation.state} - {eachLocation.city})</p>
                                                    ) : null}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }

                        {servedLocations && servedLocations.length > 0 && <h6 className="profile__sub-title sub--heading_presentations">Your current locations - {servedLocations.length}</h6>}

                        {servedLocations && servedLocations.length > 0 && (

                            <div className="profile__section-content">
                                {/* {console.log(servedLocations)} */}
                                {servedLocations.map(eachLocationInfor => (
                                    <div className="location-list" key={eachLocationInfor.id}>
                                        <div className={'sub__service-or-location-content-wrapper'}>
                                            <span className="close" onClick={() => this.removeSelectedMainLocation(eachLocationInfor)}>x</span>
                                            {(eachLocationInfor.isGlobalSupplier === "1") ? (
                                                <p>All over the world</p>
                                            ) : (eachLocationInfor.isGlobalSupplier === "0" &&
                                                eachLocationInfor.isContinentalSupplier === "1" &&
                                                eachLocationInfor.continent !== "") ? (
                                                <p>All over {eachLocationInfor.continent}</p>
                                            ) : (eachLocationInfor.isGlobalSupplier === "0" &&
                                                eachLocationInfor.isContinentalSupplier === "0" &&
                                                eachLocationInfor.country !== "" &&
                                                eachLocationInfor.isEntireCountrySupplier === "1") ? (
                                                <p>All over {eachLocationInfor.country}</p>
                                            ) : (eachLocationInfor.isGlobalSupplier === "0" &&
                                                eachLocationInfor.isContinentalSupplier === "0" &&
                                                eachLocationInfor.country !== "" &&
                                                eachLocationInfor.isEntireCountrySupplier === "0" &&
                                                eachLocationInfor.regions_served !== "" &&
                                                eachLocationInfor.isEntireStateSupplier === "1") ? (
                                                <p>All across {eachLocationInfor.regions_served} of {eachLocationInfor.country}</p>
                                            ) : ((eachLocationInfor.isGlobalSupplier === "0" || eachLocationInfor.isGlobalSupplier === null ) &&
                                                (eachLocationInfor.isContinentalSupplier === "0" || eachLocationInfor.isContinentalSupplier === null) &&
                                                eachLocationInfor.country !== "" &&
                                                (eachLocationInfor.isEntireCountrySupplier === "0" || eachLocationInfor.isEntireCountrySupplier === null) &&
                                                eachLocationInfor.regions_served !== "" &&
                                                (eachLocationInfor.isEntireStateSupplier === "0" || eachLocationInfor.isEntireStateSupplier === null) &&
                                                eachLocationInfor.city !== "") ? (
                                                <p>{eachLocationInfor.country} - ({eachLocationInfor.regions_served} - {eachLocationInfor.city})</p>
                                            ) : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="profile__button-container profile__btn-container-locations">
                            <button className="btn btn-gold profile__button profile__button-next" onClick={this.addNewLocations}>{'Next'}</button>
                        </div>
                    </div>
                </div>
                <Rodal className="rodal--profile-pic rodal__modal-box-for-add-event" visible={modalBoxVisbileForProfileCompletion} onClose={this.hideModalBoxForBasicProfile.bind(this)} animation={'slideDown'}>
                    <header className="rodal__header">
                        <h4 className="rodal__heading">GREAT JOB!</h4>
                    </header>
                    <p className="profile__rodal-text">You have created your <span className="gold-color__text">PROFESSIONAL PROFILE!</span></p>
                    <br />
                    <p className="profile__rodal-text">Click <span className="normal-color__text__bold">UPLOAD</span> to start uploading your <span className="gold-color__text">IMAGES</span>. Feel free to upload as many photos as you like!.</p>
                    <br />
                    <p className="profile__rodal-text">The more photos you add the more exposure you get. Make sure you upload each day!.</p>
                </Rodal>
            </>
        )
    }
}

const mapStateToProps = ({ profile }) => ({
    loading: profile.loading,
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    hideNotification: () => dispatch(hideNotification()),
    retrieveAllCountries: () => dispatch(retrieveAllCountries()),
    loadSupplierMainServices: () => dispatch(loadSupplierMainServices()),
    loadProfileServedCountries: () => dispatch(loadProfileServedCountries()),
    removeMainServiceAreas: locationId => dispatch(removeMainServiceAreas(locationId)),
    retrieveAllStatesByCountry: countryId => dispatch(retrieveAllStatesByCountry(countryId)),
    retrieveAllSupplierBusinessInformation: () => dispatch(retrieveAllSupplierBusinessInformation()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    postSupplierLocationInformationAction: postData => dispatch(postSupplierLocationInformationAction(postData)),
    retrieveAllCitiesByStateAndCountry: (countryId, stateId) => dispatch(retrieveAllCitiesByStateAndCountry(countryId, stateId)),

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Locations))