import { ActionTypes as types } from '../constants/newLogin'

const initialState = {
    step: 1,
    count: 1,
    userType: '',
    modalOpen: false,
    newUserType: '',
    primaryUserType: '',
    primaryUserTypes: {
        individual: [
            { index: 1, text: "I am looking for event ideas and suppliers" },
        ],
        company: [
            { index: 2, text: "I wish to apply for a company profile", subCategories: [
              { index: 3, text: "I'm an Event Supplier" },
              { index: 4, text: "I'm a Venue" }
            ] },
        ],
        jobs: [
            { index: 5, text: "I'm an Employer Who Wants to Find Available People" },
            { index: 6, text: "I'm Searching for Work" }
        ]
    },
    individual: false,
    company: false,
    jobs: false
}

export const newLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_MODAL:
            return {
                ...state,
                modalOpen: true,
                step: 1,
                newUserType: '',
                primaryUserType: '',
                individual: false,
                company: false,
                jobs: false
            }
        case types.CLOSE_MODAL:
            return {
                ...state,
                modalOpen: false,
                // count: state.count + 1
            }
        case types.CHANGE_STEP_USER:
        // if new user, go to step 3
        // if existing user, go to step 2
            return {
                ...state,
                step: action.data === 1 ? 3 : 2
            }
        case types.RESET_PASSWORD:
            return {
                ...state,
                step: 7
            }
        case types.SET_NEW_USER_TYPE:
            return {
                ...state,
                primaryUserType: action.data.userType,
                newUserType: action.data.section !== 'company' ? action.data.userType : '',
                individual: false,
                company: false,
                jobs: false,
                [action.data.section]: true
            }
        case types.GOTO_NEXT_STEP:
            return {
                ...state,
                step: state.step + 1
            }
        case types.REGISTRATION_COMPLETED:
            return {
                ...state, 
                step: 6
            }
        case types.SET_SECONDARY_USER_TYPE:
            return {
                ...state,
                newUserType: action.data
            }
        case types.GOTO_SECONDARY:
            return {
                ...state,
                step: state.company === true ? 4 : 5
            }
        default:
            return state
    }
}
