import React, { Component } from 'react'

export class StarRating extends Component {

    returnStarLevel = () => {
        let star = []
        for (var i = 1; i <= 5; i++) {
            star.push(<span className={i <= this.props.stars ? "fa fa-star checked" : "fa fa-star"}></span>)
        }
        return star
    }    

    render() {        
        return <fieldset className="rating">{this.returnStarLevel()}</fieldset>
    }
}