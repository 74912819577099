import * as actions from "../actions/registerActions"

const initialState = {
    company: "",
    lastName: "",
    password: "",
    firstName: "",
    loading: false,
    userType: null,
    emailAddress: "",
    confirmpassword: ""
}

export const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.REGISTER_FORM_UPDATE:
            return {
                ...state,
                [action.field]: action.value
            }
        case actions.SEND_REGISTER_FORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_REGISTER_FORM_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REGISTER_FORM_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_REGISTER_FORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_SUPPLIER_REGISTER_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_REGISTER_FORM_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_ACTIVATION_FORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_SUPPLIER_ACTIVATION_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_ACTIVATION_FORM_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_USERNAME_EXISTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_USERNAME_EXISTS_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_USERNAME_EXISTS_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.UPDATE_USER_TYPE_ON_REGISTER:
            return {
                ...state,
                userType: action.userType
            }
        case actions.SEND_REGISTER_CLEAR_FORM:
            return initialState
        default:
            return state
    }
}