import React from 'react'

import '../find-pro-hire.css'

const modifyTheSubCategoriesList = categories => {
	return categories.map(eachCategory => {
		if ((eachCategory.subcategories) && (eachCategory.subcategories instanceof Array)) {
			eachCategory.subcategories = eachCategory.subcategories
		} else {
			eachCategory.subcategories = JSON.parse(eachCategory.subcategories)
		}
		return eachCategory
	})
}

export const FindProCategories = ({ categories, redirectToDetails, redirectToCategories, filledSubCategories, suppliersName, redirectToSupplierPage }) => {
	const parsedCategories = modifyTheSubCategoriesList(categories)
	return (
		<div className="find-pro__container">
			{parsedCategories.map((category, i) => (
				<div className="find-pro__categories" key={category.id}>
					<a className="find-pro__category-title" onClick={() => redirectToCategories(category.category_name, category.id)}>{category.category_name}</a>
					{
						category.subcategories &&
						(<div className="find-pro__sub-categories">
							{category.subcategories.map((subcategory, j) => (
								<span onClick={() => redirectToDetails(category.category_name, category.id, subcategory.category_name, subcategory.id)}
									className={`find-pro__sub-category-title ${filledSubCategories.indexOf(subcategory.id) !== -1 ? 'filled__find-a-pro-hire-link' : 'empty-a-pro-hire-link'}`} key={subcategory.id}>{subcategory.category_name}</span>))}
						</div>)
					}
				</div>
			))
			}
			{/* Main ends here */}
			{(suppliersName.length > 0) ? (
				<div className="find-pro__categories" key="searched suppliers name">
					<a className="find-pro__category-title">Suppliers</a>
					{suppliersName.map((name, i) => (
						<div className="find-pro__sub-categories">
							<span
								onClick={() => redirectToSupplierPage(name)}
								className={`find-pro__sub-category-title filled__find-a-pro-hire-link`}
								key={name}>
								{name}
							</span>
						</div>
					))
					}
				</div>
			) : null}
		</div>
	)
}