import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import arrowIcon from '../../assets/images/icons/arrow.svg'

export class Sidebar extends Component {

  render() { 
    const { 
        pages,
        sidebar, 
        subLink,
        mainLink, 
        activeTab, 
        selectedTab
    } = this.props

    const sidebarLinks = pages.map(page => {
        return (
            <li 
                key={page.id}
                className={"profile__sidebar-list-item " + 
                (activeTab === page.id ? " profile__sidebar-list-item--active " : "") +
                (selectedTab === page.id ? "profile__sidebar-list-item--selected" : "")} 
            >
                <NavLink
                    to={'/' + page.url} 
                    activeClassName="active"
                    onClick={e => mainLink(e, page.id)} 
                    className="profile__sidebar-list-link"            
                >   
                    <div className="profile__sidebar-icon-name">
                        <img src={page.icon} alt="icon" className={"profile__sidebar-icon profile__sidebar-icon--" + page.url } />
                        <span className="profile__sidebar-list-link-name">{page.name}</span>
                    </div>
                </NavLink>
                {page.subItems &&
                    <ul className="profile__sidebar-sublist">
                        {sidebar && <span className="profile__sidebar-list-link-text">{page.name}</span>}
                        {page.subItems.map((subpage, j) => (
                            <li 
                                key={j}
                                className="profile__sidebar-sublist-item" 
                            >
                                <NavLink                         
                                    activeClassName="active" 
                                    onClick={e => subLink(page.id, page.id)}
                                    key={j} to={'/' + subpage.url}                                                 
                                    className="profile__sidebar-sublist-link"
                                >{subpage.name}
                            </NavLink>
                            </li>
                        ) 
                    )}                
                </ul>
                }
            </li>
        )
    })

    return (
        <div refs="sidebar" className={`${sidebar ? "profile__sidebar profile__sidebar--open" : "profile__sidebar"} overrided--style_for_sidebar`}>
            <button className="profile__sidebar-trigger" onClick={this.props.toggleSidebar}>
                <img src={arrowIcon} alt="icon" className="profile__sidebar-trigger-icon" />            
            </button>
            <ul className="profile__sidebar-list">            
                {sidebarLinks}
            </ul>
        </div>
        )
    }
}