import React from 'react'
import InfiniteScroll from "react-infinite-scroll-component"

import GalleryModal from './GalleryModal'
import { LazyLoad } from '../../../reusables/LazyLoad/LazyLoad'
import multiple from '../../../assets/images/icons/multiple.png'

export const Photos = ({ folders, openGalleryModal }) => (
    <div className="gallery">
        { folders && folders.length > 0 &&
          folders.map((eachFolder, key) =>
              <div className="gallery__image"
                  key={eachFolder.id}
                  onClick={() => openGalleryModal(eachFolder.images[0].image_id)}>
                  <LazyLoad src={eachFolder.images[0].image_link} style="gallery__image-item" alter="gallery" />
                  {eachFolder.images.length > 1 && (
                      <div className="gallery__image--multiple">
                          <img className="" src={multiple} alt="folder" />
                      </div>
                  )}
                  <div className="gallery__image-hover-overlay">
                      <span className="gallery__overlay-icons">
                          <i className="fa fa-heart" aria-hidden="true"></i>
                          {eachFolder.likes}
                      </span>
                      <span className="gallery__overlay-icons">
                          <i className="fa fa-comment" aria-hidden="true"></i>
                          {eachFolder.comments}
                      </span>
                  </div>
              </div>
          )}
        <GalleryModal />
    </div>
)