import React, { Component } from 'react'

import Header from '../Global/Header'

import './styles/static.css'

export default class ReviewPolicy extends Component  {

    render () {
        return (
            <>
                <Header />
                <section className="static-page">
                    <div className="static-page__static-container">
                        <header className="static-page__header">
                            <h3 className="static-page__title">EVENTBUZZ360 - REVIEW POLICY </h3>
                        </header>
                        <div className="static-page__content">
                            <p>EventBuzz360 may enable you to post reviews. Reviews are considered Your Content. By posting a Review you acknowledge and agree that:</p>
                            <ol type="a">
                                <li>you are not:
                                    <ol type="i">
                                        <li>a director, employee, officer, or principal  (“Interested Party”) of the business whose products or services are the subject of the Review;</li>
                                        <li>an Interested Party of a competitor of the business or individual whose products or services are the subject of the Review; or</li>
                                        <li>related in any way, including by blood, adoption or marriage, to the business or individual whose products or services are the subject of the Review;</li>
                                    </ol>
                                </li>
                                <li>your Review is based upon your first-hand experience with the products, or business or individual whose services, are the subject of the Review;</li>
                                <li>your Review is accurate, truthful and complete;</li>
                                <li>your Review does not violate our <a className="hight__lighted-text-underlined" target="_blank" href="/acceptable-use-policy">Acceptable Use Policy</a>, including through libellous, defamatory, threatening, harassing, invasive of privacy, abusive, tortious, hateful, discriminatory, pornographic or obscene content; as determined by EventBuzz360 in our sole and absolute discretion; and</li>
                                <li>If you have received monetary compensation or other incentive to write and submit a Review, it will be clearly and conspicuously stated in the text of your Review, even if the amount or value of such incentive is small.</li>                            
                            </ol>
                            <p>You will not use the posting or removing of your Review to demand payment from any business or individual, except that bona fide refund requests made in connection with a bad customer experience are acceptable.</p>
                        </div>
                    </div>                
                </section>
            </>
        )
    }
}