import React, { Component } from 'react'
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import './ImageUploadPreview.css'

export class ImageUploadPreview extends Component {

    state = {
        isOpen: false
    }

    closeTheImagePreviewModalBox = _ => this.setState({ isOpen: false })

    showTheImagePreviewModalBox = _ => this.setState({ isOpen: true })

    render() {
        let imagePreview = null
        const { isOpen } = this.state        
        let { coverImagePreview } = this.props        
        if (coverImagePreview) {
            imagePreview = (<img onClick={this.showTheImagePreviewModalBox} src={coverImagePreview} alt="preview" />)
        } else {
            imagePreview = (<div className="previewText">Please select an Image for Preview</div>)
        }
  
        return (
            <div className="previewComponent">
                <label id="fileInputPreviewLabel" htmlFor="fileInputPreview">
                    <svg viewBox="0 0 20 17">
                        <path d="M10 0L4.8 4.9h3.3V10h3.8V4.9h3.3L10 0zm9.3 11.5l-3.2-2.1h-2l3.4 2.6H14c-.1 0-.2.1-.2.1l-.8 2.3H7l-.8-2.2c-.1-.1-.1-.2-.2-.2H2.4l3.4-2.6h-2L.6 11.5c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9L20 13c.1-.5-.2-1.2-.7-1.5z" />
                    </svg>                    
                    Browse...
                </label>
                <input id="fileInputPreview" className="fileInput" type="file" onChange={e => this.props.coverImageHandler(e)} />
                <div className="imgPreview">
                    {isOpen && (
                        <Lightbox
                            mainSrc={coverImagePreview}
                            onCloseRequest={this.closeTheImagePreviewModalBox}
                        />
                    )}  
                    {imagePreview}                  
                </div>
            </div>
        )
    }
}