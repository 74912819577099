import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import { changeStepOnUserType } from '../../../actions/newLoginActions'
import { submitEmailForPasswordRest } from "../../../actions/sessionActions"
import { showNotification, hideNotification } from "../../../actions/notificationActions"
import { supplierProfileFormGenericUpdate, processSupplierProfileChangePasswordPublicRequest } from "../../../actions/profileActions"

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: 0,
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    }
})
class ForgotPassword extends Component {

    state = {
        email: "",
        password: "",
        securityCode: "",
        showPassword: false,        
        confirmPassword: "",
        touched: {
            email: false,
            password: false,
            confirmPassword: false,    
        },
        errorFields: {
            email: false,
            password: false,
            confirmPassword: false,
        },
        showPasswordsSection: false,
        showPasswordForConfirm: false,
        disabledStatusOfPasswordColumns: true
    }

    handleBlur = field => this.setState({ ...this.state, touched: { ...this.state.touched, [field]: true } })

    updateFormHandler = (field, event) => {
        this.props.supplierProfileFormGenericUpdate(field, event.target.value)   
        this.setState({ [field]: event.target.value })            
    }
    
    formSubmitHandler = () => {
        const { email } = this.state
        if (email) {                        
            this.props.submitEmailForPasswordRest(email).then(data => {
                // if (data.status === "email_address_exists") {
                if (data.status === "password_reset_success") {
                    this.setState({ showPasswordsSection: true, disabledStatusOfPasswordColumns: false })
                    this.props.showNotification('Please check your email for the security code!.', 'success')
                    setTimeout(() => this.props.hideNotification(), 3000)  
                } else if(data.status === "password_reset_failed_mail_not_sent") {
                    this.props.showNotification('Password reset failure. Error while sending email. Please try again later.', 'error')
                    setTimeout(() => this.props.hideNotification(), 3000)  
                } 
                else if(data.status === "email_address_dont_exists") {
                    this.props.showNotification('Email Address dont exist!', 'error')
                    setTimeout(() => this.props.hideNotification(), 3000)  
                }
                else{
                    this.props.showNotification('Internal error. Please try again in a while.', 'error')
                    setTimeout(() => this.props.hideNotification(), 3000)  
                }
            })            
        }
    }

    resetPassword = () => {
        const { 
            email, 
            password, 
            securityCode,
            confirmPassword 
        } = this.state
        if ((email && password && confirmPassword && securityCode) && (password == confirmPassword)) {            
            const postData = {
                email: email,                
                password: password,
                securityCode: securityCode
            }        
            this.props.processSupplierProfileChangePasswordPublicRequest(postData).then(data => {
                if (data.status === 'supplier_password_updated') {
                    this.props.showNotification('Your password have been updated!.', 'success')
                    this.setState({ 
                        email: '',
                        password: '', 
                        securityCode: '',
                        confirmPassword: '',
                        showPasswordsSection: false,
                        showPasswordForConfirm: false,
                        touched: {
                            email: false,
                            password: false,
                            securityCode: false,
                            confirmPassword: false,
                        }, errorFields: {
                            email: false,
                            password: false,
                            securityCode: false,
                            confirmPassword: false,
                        } 
                    })
                    setTimeout(() => this.props.hideNotification(), 5000)                                    
                } 
            })
        }
    }

    handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword })
    
    handleMouseDownPassword = event => event.preventDefault()

    handleClickShowPasswordForConfirm = () => this.setState({ showPasswordForConfirm: !this.state.showPasswordForConfirm })
    
    handleMouseDownPasswordForConfirm = event => event.preventDefault()

    render () {

        const { 
            email, 
            touched, 
            password,
            errorFields,  
            securityCode,          
            showPassword,
            confirmPassword,
            showPasswordsSection,
            showPasswordForConfirm,
            disabledStatusOfPasswordColumns
        } = this.state
        const { classes } = this.props

        return (
            <>
                <h5 className="login__title">Forgot Password</h5>
                <div className="login__form-container">
                    <div className="eb-forms">
                        <div className="eb-forms__form-group eb-forms__form-group--login">
                            <TextField
                                fullWidth
                                value={email}                  
                                margin="small"
                                labelWidth={50}
                                variant="outlined"                                   
                                disabled={showPasswordsSection}                                                                                          
                                inputProps={{style: {fontSize: 15}}}              
                                onBlur={() => this.handleBlur('email')}                                
                                InputLabelProps={{style: {fontSize: 15}}}
                                label={!showPasswordsSection ? 'Email Address' : ''}   
                                error={((touched.email && !email) || (errorFields.email))}   
                                onChange={(event, newValue) => this.updateFormHandler('email', event)}                           
                            />                                                               
                            {((touched.email && !email) || (errorFields.email)) && <span className="error">Enter a valid email address</span>}                        
                        </div>
                        {showPasswordsSection ?
                        <>
                            <div className="eb-forms__form-group">
                                <div className="eb-forms__form-group eb-forms__form-group--login">
                                    <TextField
                                        fullWidth                                        
                                        margin="small"
                                        labelWidth={50}
                                        variant="outlined"                        
                                        value={securityCode}                  
                                        label={'Security Code'}                                                                                                                                          
                                        inputProps={{style: {fontSize: 15}}}                                                                                  
                                        InputLabelProps={{style: {fontSize: 15}}}
                                        disabled={disabledStatusOfPasswordColumns}        
                                        onBlur={() => this.handleBlur('securityCode')}                                    
                                        error={((touched.securityCode && !securityCode) || (errorFields.securityCode))}   
                                        onChange={(event, newValue) => this.updateFormHandler('securityCode', event)}                           
                                    />                                                               
                                    {((touched.securityCode && !securityCode) || (errorFields.securityCode)) && <span className="error">Enter the security code</span>}                        
                                </div>
                                <div className="eb-forms--fullwidth">
                                    <FormControl className={clsx(classes.margin)} variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password">{'Password'}</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            value={password}                                            
                                            id="outlined-adornment-password"
                                            inputProps={{style: {fontSize: 15}}}
                                            disabled={disabledStatusOfPasswordColumns}
                                            onBlur={() => this.handleBlur('password')}
                                            InputLabelProps={{style: {fontSize: 15}}}   
                                            type={showPassword ? 'text' : 'password'}                                                                                        
                                            onChange={(event, newValue) => this.updateFormHandler('password', event)}      
                                            error={((touched.password && !password) || (errorFields.password))}    
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                            labelWidth={60}
                                        />
                                    </FormControl>                            
                                    {((touched.password && !password) || (errorFields.password)) && <span className="error">Please Enter a valid Password</span>}
                                    <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>
                                </div>                                                                                                                     
                            </div>
                            <div className="eb-forms__form-group">
                                <div className="eb-forms--fullwidth">
                                    <FormControl className={clsx(classes.margin)} variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password">{'Confirm Password'}</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            value={confirmPassword}
                                            id="outlined-adornment-password"
                                            inputProps={{style: {fontSize: 15}}}                                            
                                            InputLabelProps={{style: {fontSize: 15}}}   
                                            disabled={disabledStatusOfPasswordColumns}
                                            onBlur={() => this.handleBlur('confirmPassword')}
                                            type={showPasswordForConfirm ? 'text' : 'password'}                                                                                        
                                            onChange={(event, newValue) => this.updateFormHandler('confirmPassword', event)}      
                                            error={((touched.confirmPassword && !confirmPassword) || (errorFields.confirmPassword))}    
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPasswordForConfirm}
                                                    onMouseDown={this.handleMouseDownPasswordForConfirm}
                                                    edge="end"
                                                >
                                                {showPasswordForConfirm ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                            labelWidth={110}
                                        />
                                    </FormControl>                            
                                    {((touched.password && !password) || (errorFields.password)) && <span className="error">Please Enter a valid Password</span>}
                                    <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>                                            
                                </div>                                                                                                                     
                            </div>
                            <button type="submit" className="btn btn-gold signup__button" disabled={(!touched.password && !password) || (!touched.confirmPassword && !confirmPassword)} onClick={this.resetPassword}>Change Password</button>                            
                        </> : <button type="submit" className="btn btn-gold signup__button" disabled={(!touched.email && !email)} onClick={this.formSubmitHandler}>Reset Password</button>}
                    </div>     
                </div>    
                <footer className="login-form__footer">
                    <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                    <p className="login-form__footer-copy">Try Sign In now? <a onClick={() => this.props.changeStepOnUserType(2)}>Sign In</a></p>
                </footer> 
            </>
        )
    }
}
  
const mapDispatchToProps = dispatch => ({
    hideNotification: () => dispatch(hideNotification()),    
    changeStepOnUserType: userType => dispatch(changeStepOnUserType(userType)),
    submitEmailForPasswordRest: email => dispatch(submitEmailForPasswordRest(email)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    supplierProfileFormGenericUpdate: (field, value) => dispatch(supplierProfileFormGenericUpdate(field, value)),    
    processSupplierProfileChangePasswordPublicRequest: postData => dispatch(processSupplierProfileChangePasswordPublicRequest(postData)),
})

export default connect(null, mapDispatchToProps)(withStyles(useStyles)(ForgotPassword))