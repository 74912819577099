import React from 'react'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import PinterestIcon from '@material-ui/icons/Pinterest'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import YouTubeIcon from '@material-ui/icons/YouTube'
import WebIcon from '@material-ui/icons/Web'
import _ from 'lodash'

import Accordion from '../../../reusables/Accordian/Accordian'

import {
    formatUrl,
    cleanTheWebSiteUrlAndReturnPlainURL
} from '../../../utils/misc'

export const Overview = ({  
    areas, 
    supplier,
    activeTab,  
    otherAreas, 
    activateTab, 
    mainServices, 
    mainServiceId,     
    subServicesInfo, 
    otherSubServicesInfo     
}) => (
    <div className="supplier-profile__overview">
        <div className="supplier-profile__description rich-text">
            {supplier && supplier.company_bio !== '' && supplier.company_bio !== null && supplier.company_bio !== 'null' && (
                <div className="supplier-profile__company-data supplier-profile__company-data--company-bio">
                    <span className="profile__basic-infomation-labeles">Company Bio:</span>                    
                    <div>
                        {(supplier && supplier.company_bio) && supplier.company_bio.split("\n").map((i,key) => <div key={key}>{i}</div>)}
                    </div>
                </div>
            )}
            {supplier.business_awards !== '' && supplier.business_awards !== null && supplier.business_awards !== 'null'  && (
                <div className="supplier-profile__company-data supplier-profile__company-data--business-awards">
                    <span className="profile__basic-infomation-labeles">Company Awards:</span>
                    <div>
                        {(supplier && supplier.business_awards) && supplier.business_awards.split("\n").map((i,key) => <div key={key}>{i}</div>)}
                    </div>
                </div>
            )}
            {(mainServices && mainServices.length > 0) &&
                <div className="supplier-profile__company-data supplier-profile__company-data--services">
                    <span className="profile__basic-infomation-labeles">Services Provided:</span>
                    {(mainServices && mainServices.length > 0) &&
                    <>
                        <div className="profile__section-content">                                                        
                            <Accordion                                                                     
                                panels={mainServices} 
                                activeTab={activeTab} 
                                serviceId={mainServiceId}
                                activateTab={activateTab} 
                                subServicesInfo={subServicesInfo} 
                                otherSubServicesInfo={otherSubServicesInfo}                                                                    
                            />                        
                        </div>
                    </>}
                </div>
            }
            {((areas && areas.length > 0) || (otherAreas && otherAreas.length > 0)) &&
                <div className="supplier-profile__company-data supplier-profile__company-data--areas">
                    <span className="profile__basic-infomation-labeles">Areas Served:</span>
                    <ul className="supplier-profile__areas-list">
                        {areas && areas.map(eachLocationInfor => eachLocationInfor &&
                            <li key={Math.floor(Math.random() * 1000)}>{(eachLocationInfor && eachLocationInfor.isGlobalSupplier === "1") ? (
                                <p>All over the world</p>
                            ) : (eachLocationInfor.isGlobalSupplier === "0" &&
                                eachLocationInfor.isContinentalSupplier === "1" &&
                                eachLocationInfor.continent !== "") ? (
                                <p>All over {eachLocationInfor.continent}</p>
                            ) : (eachLocationInfor.isGlobalSupplier === "0" &&
                                eachLocationInfor.isContinentalSupplier === "0" &&
                                eachLocationInfor.CountryName !== "" &&
                                eachLocationInfor.isEntireCountrySupplier === "1") ? (
                                <p>All over {eachLocationInfor.CountryName}</p>
                            ) : (eachLocationInfor.isGlobalSupplier === "0" &&
                                eachLocationInfor.isContinentalSupplier === "0" &&
                                eachLocationInfor.CountryName !== "" &&
                                eachLocationInfor.isEntireCountrySupplier === "0" &&
                                eachLocationInfor.Region !== "" &&
                                eachLocationInfor.isEntireStateSupplier === "1") ? (
                                <p>All across {eachLocationInfor.Region} of {eachLocationInfor.CountryName}</p>
                            ) : ((eachLocationInfor.isGlobalSupplier === "0" || eachLocationInfor.isGlobalSupplier === null ) &&
                                (eachLocationInfor.isContinentalSupplier === "0" || eachLocationInfor.isContinentalSupplier === null) &&
                                eachLocationInfor.CountryName !== "" &&
                                (eachLocationInfor.isEntireCountrySupplier === "0" || eachLocationInfor.isEntireCountrySupplier === null) &&
                                eachLocationInfor.Region !== "" &&
                                (eachLocationInfor.isEntireStateSupplier === "0" || eachLocationInfor.isEntireStateSupplier === null) &&
                                eachLocationInfor.City !== "") ? (
                                <p>{eachLocationInfor.CountryName} - ({eachLocationInfor.Region} - {eachLocationInfor.City})</p>
                            ) : null}</li>
                            // <li>{eachLocationInfor.City} ({eachLocationInfor.Region} - {eachLocationInfor.CountryName})</li>
                        )}  

                        {/* This other areas data from database is out-dated and old so the following code is commented, please discuss to move data to supplier_location_information table. Thanks  */}

                        {/* {otherAreas && otherAreas.map(eachLocationInfor =>
                            <li>{eachLocationInfor.City} ({eachLocationInfor.CountryName})</li>
                        )}   */}
                    </ul>  
                </div>
            }                                    
        </div>
        <div className="supplier-profile__sidebar">
            <p className="supplier-profile__contact-name"><span className="profile__basic-infomation-labeles">Contact: </span> {supplier.first_name && supplier.first_name} {supplier.surname && supplier.surname}</p>
            <address className="supplier-profile__address">
                {supplier && supplier.address && <span className="profile__basic-infomation-labeles">Address: </span>} 
                {(supplier && supplier.address) && supplier.address.split("\n").map((i,key) => <div key={key}>{i}</div>)}
                {supplier && supplier.town && (supplier.town + ", ")}
                {supplier.city && supplier.city && (supplier.city + ", ")}
                {supplier && supplier.post_code && (supplier.post_code + ", ")}
                {supplier && supplier.country && (supplier.country)}
            </address>     
            <p className="supplier-profile__contact-name">                
                {supplier.typical_job_cast_type !== '' && supplier.typical_job_cast_type !== 'null' && (
                    <>
                        <span className="profile__basic-infomation-labeles">Typical Job:</span>
                        <div>
                            <label>Cost: </label>
                            {supplier.typical_job_cast_type}
                        </div>
                    </>
                )}                
                {supplier.cost_details !== '' && supplier.cost_details !== 'null' && (
                    <div>
                        <lable>Cost details: </lable>
                        {(supplier && supplier.cost_details) && supplier.cost_details.split("\n").map((i,key) => <div key={key}>{i}</div>)}
                    </div>
                )} 
            </p>       
            <div className="supplier-profile__social-media">
                <ul>
                {supplier.blog !== null && supplier.blog !== 'null' && supplier.blog !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.blog ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.blog)) : ''}`}>
                                <WebIcon style={{ color: '#bf9000', fontSize: 30 }} fontSize="large" />
                            </a>
                        </li>
                    }
                    {supplier.video !== null && supplier.video !== 'null' && supplier.video !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.video ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.video)) : ''}`}>
                                <YouTubeIcon style={{ color: '#bf9000', fontSize: 30 }} fontSize="large" />
                            </a>
                        </li>
                    }
                    {supplier.facebook !== null && supplier.facebook !== 'null' && supplier.facebook !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.facebook ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.facebook)) : ''}`}>
                                <FacebookIcon style={{ color: '#bf9000', fontSize: 30 }} fontSize="large" />
                            </a>
                        </li>
                    }
                    {supplier.instagram !== null && supplier.instagram !== 'null' && supplier.instagram !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.instagram ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.instagram)) : ''}`}>                                
                                <InstagramIcon style={{ color: '#bf9000', fontSize: 30 }} fontSize="large" />
                            </a>
                        </li>
                    }
                    {supplier.linkedIn !== null && supplier.linkedIn !== 'null' && supplier.linkedIn !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.linkedIn ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.linkedIn)) : ''}`}>
                                <LinkedInIcon style={{ color: '#bf9000', fontSize: 30 }} fontSize="large" />
                            </a>
                        </li>
                    }
                    {supplier.pinterest !== null && supplier.pinterest !== 'null' && supplier.pinterest !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.pinterest ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.pinterest)) : ''}`}>
                                <PinterestIcon style={{ color: '#bf9000', fontSize: 30 }} fontSize="large" />                                
                            </a>
                        </li>
                    }
                    {supplier.vimeo !== null && supplier.vimeo !== 'null' && supplier.vimeo !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.vimeo ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.vimeo)) : ''}`}>
                                <svg
                                    width="1em"
                                    height="1em"
                                    ariaHidden="true"                                    
                                    viewBox="0 0 24 24"
                                    style={{
                                        width: '30px',
                                        height: '30px'
                                    }}
                                    transform="rotate(360)"
                                    style={{ MsTransform: "rotate(360deg)" }}
                                >
                                    <path
                                        fill="#bf9000"
                                        d="M22 7.42c-.09 1.95-1.45 4.62-4.08 8.02C15.2 19 12.9 20.75 11 20.75c-1.15 0-2.14-1.08-2.95-3.25-.55-1.96-1.05-3.94-1.61-5.92-.6-2.16-1.24-3.24-1.94-3.24-.14 0-.66.32-1.56.95L2 8.07c1-.87 1.96-1.74 2.92-2.61 1.32-1.14 2.31-1.74 2.96-1.8 1.56-.16 2.52.92 2.88 3.2.39 2.47.66 4 .81 4.6.43 2.04.93 3.04 1.48 3.04.42 0 1.05-.64 1.89-1.97.84-1.32 1.29-2.33 1.35-3.03.12-1.14-.33-1.71-1.35-1.71-.48 0-.97.11-1.48.33.98-3.23 2.86-4.8 5.63-4.71 2.06.06 3.03 1.4 2.91 4.01z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                    }
                </ul>
            </div>
        </div>   
    </div>
)