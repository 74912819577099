import React, { Component } from 'react'
import { connect } from 'react-redux'
import Rodal from 'rodal'
import ImageGallery from 'react-image-gallery'

import { formatDate, replaceGenericNameSpacesWithDashes } from '../../../utils/misc'

import {
    closeGalleryModal,
    submitCommentForEvent,
    supplierEventCommentmUpdate,
    addLikesForSpecificEventsInAction,
    checkAlreadyGivenALikeToThatEvent,
} from '../../../actions/galleryActions'
import { showNotification, hideNotification } from "../../../actions/notificationActions"
class GalleryModal extends Component {

    state = {
        event_comment: ''
    }

    updateFormHandler = (field, value) => {
        this.setState({ [field]: value })
        this.props.supplierEventCommentmUpdate([field], value)
    }

    submitCommentForEvent = () => {
        const {
            isLoggedIn,
            showNotification,
            hideNotification,
            submitCommentForEvent,
            galleryModalData,
        } = this.props
        const eventId = galleryModalData.event_info.id
        if (!isLoggedIn) {
            showNotification('Please login to add comments...', 'info')
            setTimeout(() => hideNotification(), 6000)
        } else {
            submitCommentForEvent(eventId).then(data => {
                if (data === "supplier_comment_success") {
                    this.setState({ event_comment: "" })
                    showNotification('Thanks for your comment!.', 'success')
                }
            })
        }
    }

    addLikesForEvent = () => {
        const {
            isLoggedIn,
            showNotification,
            hideNotification,
            galleryModalData,
            checkAlreadyGivenALikeToThatEvent,
            addLikesForSpecificEventsInAction
        } = this.props
        const eventId = galleryModalData.event_info.id
        if (!isLoggedIn) {
            showNotification('Please login to like the event...', 'info')
            setTimeout(() => hideNotification(), 6000)
        } else {
            checkAlreadyGivenALikeToThatEvent(eventId).then(data => {
                console.log(data)
                if (data && data.liked === '1') {
                    showNotification('You already given a like to that event!', 'info')
                    setTimeout(() => hideNotification(), 6000)
                } else {
                    addLikesForSpecificEventsInAction(eventId)
                }
            })
        }
    }

    close = () => {
        this.props.closeGalleryModal()
        document.querySelector('body').classList.remove('modal-open')
    }

    render () {

        const { event_comment } = this.state

        const {
            modalLoading,
            galleryModalOpen,
            closeGalleryModal,
            galleryModalData,
            currentImageObject,
        } = this.props

        const {
            event_info,
            event_photos,
            event_keywords,
            event_comments,
            event_liking_info,
            business_information,
        } = galleryModalData

        return (
            <Rodal className="rodal--gallery-modal" visible={galleryModalOpen} onClose={() => this.close()} animation={'slideDown'}>
                { modalLoading ? (
                    <div className={modalLoading ? "overlay overlay--inner" : "overlay hide"}>
                        <div className="profile__loader-container">
                            <div className="loader">
                                <span>Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="gallery-modal__column gallery-modal__column--media">
                            <ImageGallery items={event_photos}
                                        lazyLoad={true}
                                        showThumbnails={false}
                                        showPlayButton={false}
                                        showBullets={true}
                                        showIndex={true} />
                        </div>
                        <div className="gallery-modal__column gallery-modal__column--info">
                            <header className="gallery-model__header">
                                <a className="gallery-model__info-link" href={`supplier-public/${replaceGenericNameSpacesWithDashes(business_information.company_name)}`}>
                                    {/* <h2 className="logoEqualFontHeader">{business_information.company_name.split("\n").map((i,key) => <div key={key}>{i.replace(/[^a-z0-9\s]/gi, '')}</div>)}</h2> */}
                                    <h2 className="logoEqualFontHeader">{business_information.company_name}</h2>
                                </a>
                            </header>
                            <div className="gallery-modal__body">
                                <div className="gallery-modal__body-header">
                                    <span>{event_liking_info.likeCount} Likes</span>
                                    <span>{formatDate(event_info.created_at)}</span>
                                </div>
                                {event_info.event_description &&
                                    <p className="gallery-modal__info">
                                        <h6>Event Description: </h6>
                                        {event_info.event_description}
                                    </p>}
                                {event_info.event_location &&
                                    <p className="gallery-modal__info">
                                        <h6>Event Location: </h6>
                                        {event_info.event_location}
                                    </p>}
                                {event_info.supplier_credits &&
                                    <p className="gallery-modal__info">
                                        <h6>Supplier Credits: </h6>
                                        {event_info.supplier_credits}
                                    </p>}
                                {event_info.hashtags &&
                                    <p className="gallery-modal__info gallery-modal__info--hashtags">
                                        {event_info.hashtags.map((eachHashTag,i) =>
                                                <a href="#" key={i}>{eachHashTag} </a>
                                            )}
                                    </p>}
                                {(event_keywords && event_keywords.length > 0 && event_keywords.map) &&
                                    <div className="gallery-model__comments-list">
                                        <div className="gallery-model__comment">
                                            {event_keywords.map((eachKeyword, i) =>
                                                <span className="event__keywords">{eachKeyword}</span>
                                            )}
                                        </div>
                                    </div>}
                                {(event_comments && event_comments.length > 0 && event_comments.map) &&
                                <div className="gallery-model__comments-list">
                                    {event_comments.map((eachComment, i) =>
                                        <div className="gallery-model__comment">
                                            {/* <b>{eachComment.first_name} {eachComment.surname}</b> */}
                                            <b>{eachComment.company_name}</b>
                                            <blockquote>{eachComment.comment}</blockquote>
                                        </div>
                                    )}
                                </div>}
                            </div>
                            <footer className="gallery-model__footer eb-forms">
                                {event_liking_info && event_liking_info.likesCount > 0 ?
                                    <i className={`fa fa-heart ${event_liking_info ? 'likedHeart' : ''}`}></i> :
                                    <i onClick={() => this.addLikesForEvent()} className="fa fa-heart-o"></i>}
                                <input
                                    type="text"
                                    placeholder="Add a comment..."
                                    className="form-control gallery-model__input"
                                    onChange={e => this.updateFormHandler('event_comment', e.target.value)}
                                    value={event_comment}
                                />
                                <button className="btn btn-white gallery-modal__post-btn" onClick={() => this.submitCommentForEvent()}>{'Post'}</button>
                            </footer>
                        </div>
                    </>
                ) }
            </Rodal>
        )
    }
}

const mapStateToProps = ({ gallery, session }) => ({
    isLoggedIn: session.isLoggedIn,
    modalLoading: gallery.modalLoading,
    galleryModalOpen: gallery.galleryModalOpen,
    galleryModalData: gallery.galleryModalData,
    currentImageObject: gallery.currentImageObject,

})

const mapDispatchToProps = dispatch => ({
    hideNotification: () => dispatch(hideNotification()),
    closeGalleryModal: () => dispatch(closeGalleryModal()),
    submitCommentForEvent: eventId => dispatch(submitCommentForEvent(eventId)),
    supplierEventCommentmUpdate: (field, value) => dispatch(supplierEventCommentmUpdate(field, value)),
    checkAlreadyGivenALikeToThatEvent: eventId => dispatch(checkAlreadyGivenALikeToThatEvent(eventId)),
    addLikesForSpecificEventsInAction: eventId => dispatch(addLikesForSpecificEventsInAction(eventId)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GalleryModal)
