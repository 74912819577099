import React, { Component } from 'react'

import { StarRating } from '../../Global/StarRating'

import { profilePhotosImagesUploadingPath } from '../../../utils/config'
import { refactorTheProfileOrCoverImage } from '../../../utils/misc'

export default class Reviews extends Component {

    state = {
        starExplanations: [
            "Would not recommend this pro",
            "Just ok, could have been better",
            "Pretty good overall",
            "Great job, recommend",
            "Excellent job, highly recommend"
        ],
    }

    render() { 

        const { reviews } = this.props 
        
        return (
            <div className="supplier-profile__reviews-container">
                <div className="supplier-profile__reviews">                    
                    <div className="supplier-profile__reviews-section">
                        {reviews && reviews.map(review => (
                            <div key={review.id} className="supplier-profile__review">
                                <div className="supplier-profile__review-header">
                                    <div className="supplier-profile__review-company-logo">
                                        <img src={refactorTheProfileOrCoverImage(review.profile_photo, profilePhotosImagesUploadingPath)} alt="company logo" />
                                    </div>
                                    <div className="supplier-profile__company-details">
                                        <h5 className="supplier-profile__review-name">{review.first_name + ' ' + review.last_name}</h5>
                                        <p className="supplier-profile__review-company-name">{review.reviewer_job_title} - {review.reviewer_company}</p>                               
                                    </div>
                                    
                                </div>
                                <div className="supplier-header__star-container">
                                    {/* {review.pro && <span className="supplier-header__pro">PRO</span>} */}
                                    <StarRating stars={review.rating}></StarRating>   
                                </div> 
                                <label>
                                    <em>
                                        {review.created_at}
                                    </em>
                                </label>
                                <blockquote className="supplier-profile__review-comment">{review.review_comment}</blockquote>
                            </div>))
                        }           
                    </div>
                </div>              
            </div>
        )
    }
}