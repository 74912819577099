import React, { Component } from 'react'
import backToTopIcon from '../../assets/images/icons/chevron.svg'

export class BackToTop extends Component {
    state = {
        show: false
    }

    componentDidMount() {
        this.handleScroll = this.handleScroll.bind(this)
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {        
        if (window.pageYOffset > 500) {
            this.setState({ show: true })
        } else {
            this.setState({ show: false })
        }        
    }

    backToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    render () {
        return (
            <button                 
                onClick={() => this.backToTop()}
                className={this.state.show ? "back-to-top" : "back-to-top hide"}
            >
                <img className="back-to-top__icon" src={backToTopIcon} alt="back to top icon" />
            </button>
        )
    }
}