import React, { Component } from 'react'

import HireContactModal from '../../IndexScreen/hire/HireContactModal'
import { LazyLoad } from '../../../reusables/LazyLoad/LazyLoad'

import { 
    uppercaseFirstLetter,
    checkTheStringThenTruncate,
    refactorTheProfileOrCoverImage 
} from '../../../utils/misc'
import { reactDomainUrl, profilePhotosImagesUploadingPath } from '../../../utils/config'

export default class HireGallery extends Component {

    render() {

        let company_info = null
        const { 
            touched,
            connect,
            handleBlur,
            errorFields,
            mainHeading,
            contactEmail, 
            onCloseHandler,
            errorsInTheForm,
            showContactModal,             
            updateFormHandler,
            contactTheSupplier,
            relatedSupplierInfo,
            supplierEmailMessage
        } = this.props

        if (!relatedSupplierInfo || relatedSupplierInfo.length === 0) return <h4 className="no__suppliers-error">{'No suppliers found!'}</h4>

        return (  
            <div className="find-pro__gallery-container">
                <div className="find-pro__gallery-title">
                    <h5>{uppercaseFirstLetter(mainHeading)}</h5>
                </div>      
                <div className="find-pro__gallery">
                    {(relatedSupplierInfo && relatedSupplierInfo.length > 0) && relatedSupplierInfo.map(eachSupplier => {
                        if (eachSupplier.company_info && eachSupplier.company_info != '' && eachSupplier.company_info.company_bio != '' && eachSupplier.company_info.company_name != '') {
                            company_info = JSON.parse(eachSupplier.company_info)
                        }                        
                        return (
                            <div className="find-pro__gallery__container">
                                <div className="find-pro__gallery__image">
                                    {((eachSupplier && eachSupplier.company_photos) && (eachSupplier.company_photos.image_link || eachSupplier.company_photos)) &&
                                    <LazyLoad
                                        style="find-pro__gallery__image-item" alter="FindPro" 
                                        src={eachSupplier.company_photos.image_link ? eachSupplier.company_photos.image_link : refactorTheProfileOrCoverImage(eachSupplier.company_photos, profilePhotosImagesUploadingPath)}                                    
                                    />}                                
                                    {/* <img className="find-pro__gallery__image-item" src={eachSupplier.company_photos.image_link ? galleryImagesUploadingPath + eachSupplier.company_photos.image_link : eachSupplier.company_photos} alt="gallery image" /> */}
                                </div>
                                <h5 className="find-pro__gallery-heading"><a href={`${reactDomainUrl}supplier-public/${company_info.company_name}`}>{company_info.company_name.length > 70 ? checkTheStringThenTruncate(company_info.company_name, 70) : company_info.company_name}</a></h5>
                                {company_info && 
                                    <p className="find-pro__description">{checkTheStringThenTruncate(company_info.company_bio, 210)}</p>
                                }                            
                                <div className="find-pro__footer">
                                    <ul className="find-pro__tag-list">
                                        {(eachSupplier.event_keywords && eachSupplier.event_keywords.map) && eachSupplier.event_keywords.map(eachTag => <li className="find-pro__tag-list-item">{eachTag}</li>)}
                                    </ul>
                                    <button type="submit" className="btn btn-gold" onClick={() => connect(eachSupplier.contact_info.email_address && eachSupplier.contact_info.email_address)}>Contact</button>
                                </div>
                                <p><a href={`${reactDomainUrl}supplier-public/${company_info.company_name}`}>Read More</a></p>
                            </div>
                        )
                    })}
                </div>
                <HireContactModal 
                    touched={touched}
                    handleBlur={handleBlur}
                    errorFields={errorFields}
                    contactEmail={contactEmail}
                    onCloseHandler={onCloseHandler}
                    errorsInTheForm={errorsInTheForm}
                    showContactModal={showContactModal}
                    updateFormHandler={updateFormHandler}
                    contactTheSupplier={contactTheSupplier}
                    supplierEmailMessage={supplierEmailMessage}
                />
            </div>
        )
    }
}