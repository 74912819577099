import React, { Component } from 'react'

import Panel from './Panel'

import './Accordian.css'

export default class Accordion extends Component {

	render() {

		const { 
			panels, 
			activeTab,
			isLoggedIn,
			activateTab, 
			subServicesInfo, 
			otherSubServicesInfo,
			removeSelectedSubService,
			removeSelectedMainService,
			removeSelectedSubOtherService
		} = this.props

		return (
			<div className='accordion' role='tablist'>
				{panels.map((panel, index) =>
					<Panel
                        {...panel} 
						key={index}						
						index={index}
                        activeTab={activeTab}
						isLoggedIn={isLoggedIn}
                        activateTab={activateTab}
						subServicesInfo={subServicesInfo}	
						otherSubServicesInfo={otherSubServicesInfo}
						removeSelectedSubService={removeSelectedSubService}
						removeSelectedMainService={removeSelectedMainService}
						removeSelectedSubOtherService={removeSelectedSubOtherService}
					/>
				)}
			</div>
		)
	}
}