import React from 'react'
import Rodal from 'rodal'
import ImageGallery from 'react-image-gallery'
import { TextField } from '@material-ui/core'

import { formatDate, replaceGenericNameSpacesWithDashes } from '../../../utils/misc'

import './styles/gallery-modal.css'

export const GalleryModal = props => {

    const {
        close,
        modalLoading,
        event_comment,
        addLikesForEvent,
        galleryModalOpen,
        galleryModalData, 
        updateFormHandler,
        submitCommentForEvent
    } = props   
    
    const { 
        event_info,
        event_photos,
        event_keywords,
        event_comments,
        event_liking_info,
        business_information,
    } = galleryModalData

    return (
        <Rodal 
            visible={galleryModalOpen} 
            className="rodal--gallery-modal" 
            onClose={close} animation={'slideDown'}
        >
            {modalLoading ? (
                <div className={modalLoading ? "overlay overlay--inner" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader">
                            <span>Loading...</span>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="gallery-modal__column gallery-modal__column--media">
                        <ImageGallery 
                            lazyLoad={true}
                            showIndex={true} 
                            showBullets={true}
                            items={event_photos}                                                        
                            showThumbnails={false}
                            showPlayButton={false}                            
                        />
                    </div>            
                    <div className="gallery-modal__column gallery-modal__column--info">
                        <header className="gallery-model__header">
                            <a className="gallery-model__info-link" href={`supplier-public/${replaceGenericNameSpacesWithDashes(business_information.company_name)}`}>
                                <h2 className="logoEqualFontHeader">{business_information.company_name}</h2>  
                            </a>
                        </header>
                        <div className="gallery-modal__body">
                            <div className="gallery-modal__body-header">
                                <span>{event_liking_info.likeCount} Like(s)</span>
                                <span>{formatDate(event_info.created_at)}</span>
                            </div>
                            {event_info.event_description &&
                                <p className="gallery-modal__info">
                                    <h6>Event Description: </h6>
                                    {event_info.event_description.split("\n").map((i,key) => <div key={key}>{i}</div>)}
                                </p>}                      
                            {event_info.event_location &&
                                <p className="gallery-modal__info">
                                    <h6>Event Location: </h6>
                                    {event_info.event_location}
                                </p>}
                            {event_info.supplier_credits &&
                                <p className="gallery-modal__info">
                                    <h6>Supplier Credits: </h6>
                                    {event_info.supplier_credits.split("\n").map((i,key) => <div key={key}>{i}</div>)}
                                </p>}
                            {event_info.hashtags &&
                                <p className="gallery-modal__info gallery-modal__info--hashtags">
                                    {event_info.hashtags.map((eachHashTag,i) => 
                                            <a href="#" key={i}>{eachHashTag}</a>
                                        )}                                
                                </p>}
                            {(event_keywords && event_keywords.length > 0 && event_keywords.map) &&
                                <div className="gallery-model__comments-list">                                
                                    <div className="gallery-model__comment">
                                        {event_keywords.map((eachKeyword, i) =>
                                            <span className="event__keywords">{eachKeyword}</span>
                                        )}
                                    </div>
                                </div>}
                            {(event_comments && event_comments.length > 0 && event_comments.map) &&
                            <div className="gallery-model__comments-list">
                                {event_comments.map((eachComment, i) =>
                                    <div className="gallery-model__comment">
                                        {/* <b>{eachComment.first_name} {eachComment.surname}</b> */}
                                        <b>{eachComment.company_name}</b>
                                        <blockquote>{eachComment.comment}</blockquote>
                                    </div>
                                )}
                            </div>}
                        </div>
                        <footer className="gallery-model__footer eb-forms">
                            {event_liking_info && event_liking_info.likesCount > 0 ? 
                                <i className={`fa fa-heart ${event_liking_info ? 'likedHeart' : ''}`}></i> :
                                <i onClick={addLikesForEvent} className="fa fa-heart-o"></i>}                        
                            
                            <TextField
                                fullWidth
                                margin="small"                                                                                    
                                label={'Comment'} 
                                value={event_comment}                                                                                                                                                                                               
                                placeholder="Add a comment..." 
                                inputProps={{style: {fontSize: 12}}}                                                                                                                                                
                                InputLabelProps={{style: {fontSize: 12}}}
                                onChange={e => updateFormHandler('event_comment', e.target.value)}
                            />
                            <button className="btn btn-white gallery-modal__post-btn" onClick={submitCommentForEvent}>{'Post'}</button>
                        </footer>
                    </div> 
                </>
            ) }
        </Rodal>
    )
}