import { 
    loadImagePages,    
    loadAllEventTypes,
    loadGalleryImages,        
    loadPaginatedImages,
    fetchLikesAndCommentsCount,
    loadEventIdFromImageId,
    submitCommentForEventApiCall,
    addSupplierPublicProfileReview,
    checkTheGivenCompanyNameIsValid,
    fetchSupplierEventPhotosForPublic,
    loadEventRelatedKeywordsForPublic,
    addLikesForSupplierSpecificEvents,
    loadAllRegisteredEventsIndexPhotos,
    checkTheExistingEventTypeValidKeyword,
    checkAlreadyGivenALikeToThatEventDataFetch,
    fetchSupplierPublicProfileServiceInformation,
    fetchSupplierProfileBasicInformationForPublic,
    fetchAllReviewsForSelectedSupplierPublicProfile,
    fetchSupplierPublicProfileLocationMainInformation,
    fetchSupplierPublicProfileOtherServiceInformation,
    fetchSupplierPublicProfileOtherLocationInformation   
} from "../services/mediaServices"

export const OPEN_GALLERY_MODAL = "OPEN_GALLERY_MODAL"
export const CLOSE_GALLERY_MODAL = "CLOSE_GALLERY_MODAL"
export const SEND_LOAD_IMAGE_PAGES_SUCCESS = "SEND_LOAD_IMAGE_PAGES_SUCCESS"
export const SEND_LOAD_IMAGE_PAGES_FAILURE = "SEND_LOAD_IMAGE_PAGES_FAILURE"
export const SEND_EVENT_COMMENT_FORM_UPDATE = "SEND_EVENT_COMMENT_FORM_UPDATE"
export const SUBMIT_COMMENT_FOR_EVENT_REQUEST = "SUBMIT_COMMENT_FOR_EVENT_REQUEST"
export const SEND_LOAD_PAGINATED_IMAGES_REQUEST = "SEND_LOAD_PAGINATED_IMAGES_REQUEST"
export const ADD_SUPPLIER_PUBLIC_PROFILE_REQUEST = "ADD_SUPPLIER_PUBLIC_PROFILE_REQUEST"
export const SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST = "SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST"
export const SEND_PUBLIC_PROFILE_REVIEW_FORM_UPDATE = "SEND_PUBLIC_PROFILE_REVIEW_FORM_UPDATE"
export const SUBMIT_COMMENT_FOR_EVENT_REQUEST_SUCCESS = "SUBMIT_COMMENT_FOR_EVENT_REQUEST_SUCCESS"
export const SUBMIT_COMMENT_FOR_EVENT_REQUEST_FAILURE = "SUBMIT_COMMENT_FOR_EVENT_REQUEST_FAILURE"
export const SEND_LOAD_PAGINATED_IMAGES_REQUEST_SUCCESS = "SEND_LOAD_PAGINATED_IMAGES_REQUEST_SUCCESS"
export const SEND_LOAD_PAGINATED_IMAGES_REQUEST_FAILURE = "SEND_LOAD_PAGINATED_IMAGES_REQUEST_FAILURE"
export const SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST_SUCCESS = "SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST_SUCCESS"
export const SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST_FAILURE = "SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST_FAILURE"
export const ADD_SUPPLIER_PUBLIC_PROFILE_REVIEW_REQUEST_SUCCESS = "ADD_SUPPLIER_PUBLIC_PROFILE_REVIEW_REQUEST_SUCCESS"
export const ADD_SUPPLIER_PUBLIC_PROFILE_REVIEW_REQUEST_FAILURE = "ADD_SUPPLIER_PUBLIC_PROFILE_REVIEW_REQUEST_FAILURE"
export const SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST = "SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST"
export const LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST = "LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST"
export const SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST = "SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST"
export const SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST = "SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST"
export const SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST_FAILURE = "SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST_FAILURE"
export const SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST_SUCCESS = "SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST_SUCCESS"
export const LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST_SUCCESS = "LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST_SUCCESS"
export const LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST_FAILURE = "LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST_FAILURE"
export const SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST_SUCCESS = "SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST_SUCCESS"
export const SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST_FAILURE = "SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST_FAILURE"
export const SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST_SUCCESS = "SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST_SUCCESS"
export const SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST_FAILURE = "SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST_FAILURE"
export const SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST = "SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST"
export const SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST_SUCCESS = "SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST_SUCCESS"
export const SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST_FAILURE = "SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST_FAILURE"

const sendGalleryImagesRetrievalRequest = () => ({
    type: SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST
})

const sendGalleryImagesRetrievalRequestSuccess = () => ({
    type: SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST_SUCCESS
})

const sendGalleryImagesRetrievalRequestFailure = () => ({
    type: SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST_FAILURE
})

const sendLoadAllRegisteredEventsIndexPhotosRequest = () => ({
    type: SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST
})

const sendLoadAllRegisteredEventsIndexPhotosRequestSuccess = data => ({
    type: SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST_SUCCESS,
    data: data
})

const sendLoadAllRegisteredEventsIndexPhotosRequestFailure = error => ({
    type: SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST_FAILURE,
    data: error
})

const loadAllEventInformationFromEventPhotoIdRequest = data => ({
    type: LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST,
    data: data
})

const loadAllEventInformationFromEventPhotoIdRequestSuccess = data => ({
    type: LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST_SUCCESS,
    data: data
})

const loadAllEventInformationFromEventPhotoIdRequestFailure = error => ({
    type: LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST_FAILURE,
    data: error
})

// const sendFetchSupplierEventInformationPublicRequest = () => ({
//     type: SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST
// })

// const sendFetchSupplierEventInformationPublicRequestSuccess = () => ({
//     type: SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST_SUCCESS
// })

// const sendFetchSupplierEventInformationPublicRequestFailure = () => ({
//     type: SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST_FAILURE
// })

// const sendretreieveSupplierEventOtherPhotosForPublicRequest = () => ({
//     type: SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST
// })

// const sendretreieveSupplierEventOtherPhotosForPublicRequestSuccess = () => ({
//     type: SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST_SUCCESS
// })

// const sendretreieveSupplierEventOtherPhotosForPublicRequestFailure = () => ({
//     type: SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST_FAILURE
// })

const sendLoadEventRelatedKeywordsForPublicRequest = () => ({
    type: SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST
})

const sendLoadEventRelatedKeywordsForPublicRequestSuccess = () => ({
    type: SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST_SUCCESS
})

const sendLoadEventRelatedKeywordsForPublicRequestFailure = () => ({
    type: SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST_FAILURE
})

const sendLoadPaginatedImagesRequest = () => ({
    type: SEND_LOAD_PAGINATED_IMAGES_REQUEST
})

const sendLoadImagePagesSuccess = data => ({
    type: SEND_LOAD_IMAGE_PAGES_SUCCESS,
    data: data
})

const sendLoadImagePagesFailure = error => ({
    type: SEND_LOAD_IMAGE_PAGES_FAILURE,
    data: error
})

const sendLoadPaginatedImagesRequestSuccess = data => ({
    type: SEND_LOAD_PAGINATED_IMAGES_REQUEST_SUCCESS,
    data: data
})

const sendLoadPaginatedImagesRequestFailure = error => ({
    type: SEND_LOAD_PAGINATED_IMAGES_REQUEST_FAILURE,
    data: error
})

const sendLoadPaginatedImagesSuccess = () => ({
    type: SEND_LOAD_PAGINATED_IMAGES_REQUEST_SUCCESS
})

const sendLoadPaginatedImagesFailure = () => ({
    type: SEND_LOAD_PAGINATED_IMAGES_REQUEST_FAILURE
})

const submitCommentForEventRequest = () => ({
    type: SUBMIT_COMMENT_FOR_EVENT_REQUEST
})

const submitCommentForEventRequestSuccess = () => ({
    type: SUBMIT_COMMENT_FOR_EVENT_REQUEST_SUCCESS
})

const submitCommentForEventRequestFailure = () => ({
    type: SUBMIT_COMMENT_FOR_EVENT_REQUEST_FAILURE
})

const addSupplierPublicProfileReviewRequest = () => ({
    type: ADD_SUPPLIER_PUBLIC_PROFILE_REQUEST
})

const addSupplierPublicProfileReviewRequestSuccess = () => ({
    type: ADD_SUPPLIER_PUBLIC_PROFILE_REVIEW_REQUEST_SUCCESS 
})

const addSupplierPublicProfileReviewRequestFailure = () => ({
    type: ADD_SUPPLIER_PUBLIC_PROFILE_REVIEW_REQUEST_FAILURE
})

export const supplierEventCommentmUpdate = (field, value) => ({
    type: SEND_EVENT_COMMENT_FORM_UPDATE,
    field,
    value
})

export const supplierPublicProfileReviewFormUpdate = (field, value) => ({
    type: SEND_PUBLIC_PROFILE_REVIEW_FORM_UPDATE,
    field,
    value
})

export const loadTheGalleryImages = () => dispatch => {
    dispatch(sendGalleryImagesRetrievalRequest())
    return loadGalleryImages().then(data => {
        dispatch(sendGalleryImagesRetrievalRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendGalleryImagesRetrievalRequestFailure())
        return error
    })
}

export const openGalleryModal = () => dispatch => {
    dispatch({ type: OPEN_GALLERY_MODAL })
    // loadAllEventInformationFromEventPhotoId(selectedImage)
}

export const closeGalleryModal = () => ({
    type: CLOSE_GALLERY_MODAL
})

export const loadAllRegisteredEventsIndexPhotosMax = eventType => dispatch => {
    dispatch(sendLoadAllRegisteredEventsIndexPhotosRequest())
    return loadAllRegisteredEventsIndexPhotos(eventType).then(data => {
        dispatch(sendLoadAllRegisteredEventsIndexPhotosRequestSuccess(data.event_photos))
        // return data
    }).catch(error => {
        dispatch(sendLoadAllRegisteredEventsIndexPhotosRequestFailure(error))
        // return error
    })
}

export const loadAllEventInformationFromEventPhotoId = imageId => dispatch => {
    dispatch(loadAllEventInformationFromEventPhotoIdRequest(imageId))
    return loadEventIdFromImageId(imageId).then(data => {
        dispatch(loadAllEventInformationFromEventPhotoIdRequestSuccess(data))
        // return data
    }).catch(error => {
        dispatch(loadAllEventInformationFromEventPhotoIdRequestFailure(error))
        // return error
    })
}

export const retrieveEventKeywordsforPublic = eventId => dispatch => {
    dispatch(sendLoadEventRelatedKeywordsForPublicRequest())
    return loadEventRelatedKeywordsForPublic(eventId).then(data => {
        dispatch(sendLoadEventRelatedKeywordsForPublicRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendLoadEventRelatedKeywordsForPublicRequestFailure())
        return error
    }) 
}

export const retrieveAllEventTypes = () => () => loadAllEventTypes().then(data => data)

export const checkKeywordValidAsActualEventType = keyword => () => checkTheExistingEventTypeValidKeyword(keyword).then(data => data)

export const retrievePaginatedImagesOnLoad = eventType => (dispatch, getState) => {
    dispatch(sendLoadPaginatedImagesRequest())
    getImagePages(dispatch, eventType)
    getPaginatedImages(dispatch, eventType)
}

export const retrievePaginatedImagesOnScorll = eventType => (dispatch, getState) => {
    getPaginatedImages(dispatch, eventType, getState().gallery.currentPage)
}

const getImagePages = (dispatch, eventType) => {
    loadImagePages(eventType).then(data => {
        dispatch(sendLoadImagePagesSuccess(data))
    }).catch(error => {
        dispatch(sendLoadImagePagesFailure(error))
    })
}

const getPaginatedImages = (dispatch, eventType, pageNum = 0) => {
    loadPaginatedImages(pageNum, eventType).then(data => {
        dispatch(sendLoadPaginatedImagesRequestSuccess(data))
    }).catch(error => {
        dispatch(sendLoadPaginatedImagesRequestFailure(error))
    })
}

export const submitCommentForEvent = eventId => (dispatch, getState) => {
    dispatch(submitCommentForEventRequest())
    const accessToken = localStorage.getItem('accessToken')
    const event_comment = getState().gallery.event_comment
    const postData = {                
        event_comment: event_comment
    } 
    return submitCommentForEventApiCall(eventId, postData, accessToken).then(data => {
        dispatch(submitCommentForEventRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(submitCommentForEventRequestFailure())
        return error
    })
}

export const addLikesForSpecificEventsInAction = eventId => () => {
    const accessToken = localStorage.getItem('accessToken')    
    return addLikesForSupplierSpecificEvents(eventId, {}, accessToken).then(data => data) 
}

export const fetchSupplierProfileBasicInformationForPublicInAction = userEmail => () => fetchSupplierProfileBasicInformationForPublic(userEmail)

export const checkTheGivenCompanyNameIsValidInAction = companyName => () => checkTheGivenCompanyNameIsValid(companyName).then(data => data)

export const fetchSupplierEventPhotosForPublicInAction = userEmail => () => fetchSupplierEventPhotosForPublic(userEmail).then(data => data) 

export const likesAndCommentsCount = event_id => () => fetchLikesAndCommentsCount(event_id).then(data => data)

export const addSupplierPublicProfileReviewInAction = (userEmail, supplierEmail) => (dispatch, getState) => {
    dispatch(addSupplierPublicProfileReviewRequest())
    const rating = getState().gallery.rating
    const reviewerJobTitle = getState().gallery.reviewerJobTitle
    const reviewerCompany = getState().gallery.reviewerCompany
    const reviewerDescription = getState().gallery.reviewerDescription
    const reviewerFirstName = getState().gallery.reviewerFirstName
    const reviewerLastName = getState().gallery.reviewerLastName
    const reviewerRelationship = getState().gallery.reviewerRelationship
    const reviewerProjectCompletionMonth = getState().gallery.reviewerProjectCompletionMonth
    const reviewerProjectCompletionYear = getState().gallery.reviewerProjectCompletionYear
    const reviewerProjectCost = getState().gallery.reviewerProjectCost
    const reviwerEmail = getState().gallery.reviwerEmail
    const reviewerStreetAddress = getState().gallery.reviewerStreetAddress
    const reviewerContactNo = getState().gallery.reviewerContactNo
    const postData = {                
        rating: rating,
        reviewerCompany: reviewerCompany,
        reviewerJobTitle: reviewerJobTitle,
        reviewerDescription: reviewerDescription,
        reviewerFirstName: reviewerFirstName,
        reviewerLastName: reviewerLastName,
        reviewerRelationship: reviewerRelationship,
        reviewerProjectCompletionMonth: reviewerProjectCompletionMonth,
        reviewerProjectCompletionYear: reviewerProjectCompletionYear,
        reviewerProjectCost: reviewerProjectCost,
        reviwerEmail: reviwerEmail,
        reviewerStreetAddress: reviewerStreetAddress,
        reviewerContactNo: reviewerContactNo
    } 
    return addSupplierPublicProfileReview(userEmail, supplierEmail, postData).then(data => {
        dispatch(addSupplierPublicProfileReviewRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(addSupplierPublicProfileReviewRequestFailure())
        return error
    })
}

export const fetchAllReviewsForSelectedSupplierPublicProfileInAction = userEmail => () => fetchAllReviewsForSelectedSupplierPublicProfile(userEmail).then(data => data)

export const fetchSupplierPublicProfileServiceInformationInAction = userEmail => () => fetchSupplierPublicProfileServiceInformation(userEmail).then(data => data)

export const fetchSupplierPublicProfileOtherServiceInformationInAction = userEmail => () => fetchSupplierPublicProfileOtherServiceInformation(userEmail).then(data => data)

export const fetchSupplierPublicProfileLocationMainInformationInAction = userEmail => () => fetchSupplierPublicProfileLocationMainInformation(userEmail).then(data => data)

export const fetchSupplierPublicProfileOtherLocationInformationInAction = userEmail => () => fetchSupplierPublicProfileOtherLocationInformation(userEmail).then(data => data)

export const checkAlreadyGivenALikeToThatEvent = eventId => () => {
    const accessToken = localStorage.getItem('accessToken')    
    return checkAlreadyGivenALikeToThatEventDataFetch(eventId, accessToken).then(data => data)
}