import React, { Component } from 'react'
import { connect } from "react-redux"
import { TextField } from '@material-ui/core'

import { checkLoginStatus } from '../../actions/sessionActions'
import { supplierReviewFormUpdated, postCollectedSupplierReviewInformation } from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"
import {     
    retrieveAllSupplierContactInformation,
    retrieveAllSupplierBusinessInformation
} from '../../actions/profileActions'

import Header from '../Global/Header'
import removeAccents from 'remove-accents'

class ProfessionalReviews extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            reviewerName: "",
            reviewerEmail: "",
            reviewerComment: "As a professional event company, our business relies on recommendations from our clients. We would appreciate it if you would write a brief review for us on EventBuzz360.com, the most influential events site for organising events",
            errorFields: {
                reviewerName: "",
                reviewerEmail: false,
                reviewerComment: false
            },
            touched: {
                reviewerName: false,
                reviewerEmail: false,
                reviewerComment: false
            },
            errorsInTheForm: true,
            supplier_first_name: "",
            successInTheForm: false,                      
            supplier_contact_no: "",
            loggedInRealStatus: true,  
            supplier_companyName: "",
            supplier_email_address: "",
        }
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => data)
        this.props.retrieveAllSupplierContactInformation().then(contactInfo => {  
            this.props.retrieveAllSupplierBusinessInformation().then(data => {  
                if (data !== null) {
                    this.setState({ 
                        supplier_first_name: contactInfo && removeAccents(contactInfo.first_name),
                        supplier_surname: contactInfo && removeAccents(contactInfo.surname),
                        supplier_email_address: contactInfo && removeAccents(contactInfo.email_address),
                        supplier_contact_no: contactInfo && contactInfo.tel_no || contactInfo.mobile_no,
                        supplier_companyName: data && removeAccents(data.company_name),                                                        
                    })     
                }          
            })                 
        })             
    }

    updateFormHandler = field => event => {
        this.setState({
            [field]: event.target.value,
            errorFields: {
                [field]: (!event.target.value) ? true : false
            },
            errorsInTheForm: (!event.target.value) ? true : false
        })
        this.props.supplierReviewFormUpdated([field], event.target.value)
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    sendMessage = () => {
        const {
            reviewerName,
            reviewerEmail,            
            errorsInTheForm,
            reviewerComment,
            supplier_contact_no,
            supplier_first_name,
            supplier_companyName,
            supplier_email_address
        } = this.state
        if (!errorsInTheForm) {
            const postData = {
                reviewerName: reviewerName,
                reviewerEmail: reviewerEmail,
                reviewerComment: reviewerComment,
                supplierFirstName: supplier_first_name,
                supplierEmailAddress: supplier_email_address,
                supplierContactNo: supplier_contact_no,
                supplierCompanyName: supplier_companyName,                                                        
            }
            this.props.postCollectedSupplierReviewInformation(postData).then(data => {
                if (data.status === "reviwer_mail_success") {
                    this.props.showNotification('Your request for a reference has been sent!', 'success')
                    setTimeout(() => this.props.hideNotification(), 5000)                    
                    this.setState({
                        reviewerName: "",
                        reviewerEmail: "",
                        reviewerComment: "",
                        errorsInTheForm: {},
                    })
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 5000)
                }
            })
        }
    }

    render() {

        const {
            touched,
            errorFields,
            reviewerName,
            reviewerEmail,
            reviewerComment,
        } = this.state

        return (
            <>
                <Header />   
                <div className="profile__contact-info">
                    <div className="profile__section">
                        <span className="profile__section-content-tips">* Indicates a required field!</span>
                        <h6 className="profile__sub-title">
                            Reviews are the No. 1 thing clients look for when hiring an Event Pro.
                            <br />
                            Event Pros with 3+ reviews generally get 10 times more business
                        </h6>
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <div className="eb-forms eb-forms--profile">
                                    <div className="eb-forms__form-group">
                                        <div className="profile__input-container">                                           
                                            <TextField
                                                fullWidth
                                                margin="small"                                                
                                                variant="outlined"                                                     
                                                label="Reviewer Name"   
                                                inputProps={{style: {fontSize: 12}}}                                                                                                
                                                onBlur={() => this.handleBlur('reviewerName')}                                                                                                                                                                    
                                                onChange={this.updateFormHandler('reviewerName')}
                                                value={reviewerName != 'null' ? reviewerName : ''}    
                                                InputLabelProps={{style: {fontSize: 12}}, { required: true }}
                                                error={((touched.reviewerName && !reviewerName) || (errorFields.reviewerName))}   
                                            />                                            
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <div className="profile__input-container">
                                            <TextField
                                                fullWidth
                                                margin="small"                                                
                                                variant="outlined"                                                     
                                                label="Reviewer Email"   
                                                inputProps={{style: {fontSize: 12}}}                                                                                                
                                                onBlur={() => this.handleBlur('reviewerEmail')}                                                                                                                                                                    
                                                onChange={this.updateFormHandler('reviewerEmail')}
                                                value={reviewerEmail != 'null' ? reviewerEmail : ''}    
                                                InputLabelProps={{style: {fontSize: 12}}, { required: true }}
                                                error={((touched.reviewerEmail && !reviewerEmail) || (errorFields.reviewerEmail))}   
                                            />                                                
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <TextField                                                                                        
                                            rows={1}
                                            fullWidth
                                            multiline
                                            rowsMax={2}                                            
                                            margin="small"                                                                                                                                        
                                            variant="outlined"                                                                                        
                                            name="reviewerComment"                                     
                                            label="Reviewer Comment"                                             
                                            inputProps={{style: {fontSize: 12}}}
                                            onChange={this.updateFormHandler('reviewerComment')}
                                            value={reviewerComment != 'null' ? reviewerComment : ''}
                                            InputLabelProps={{style: {fontSize: 12}}, { required: true }}   
                                            error={((touched.reviewerComment && !reviewerComment) || (errorFields.reviewerComment))}     
                                            // defaultValue="As a professional event company, our business relies on recommendations from our clients. We would appreciate it if you would write a brief review for us on EventBuzz360.com, the most influential events site for organising events"                                                                                                                                      
                                            // placeholder="As a professional event company, our business relies on recommendations from our clients. We would appreciate it if you would write a brief review for us on EventBuzz360.com, the most influential events site for organising events."
                                        />                                               
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="business-awards"></label>
                                        <div className="profile__button-container profile__button-container--reviews contentLeftToRight">
                                            <button className="btn btn-gold profile__button profile__button-next" disabled={
                                                !reviewerName || 
                                                !reviewerEmail || 
                                                !reviewerComment
                                            } onClick={this.sendMessage}>Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile__tips-section"></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,
    isLoggedIn: session.isLoggedIn,    
    formUpdated: profile.supplierBusinessInfomration.formUpdated,
    supplierBusinessInfomration: profile.supplierBusinessInfomration,
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    hideNotification: () => dispatch(hideNotification()),
    supplierReviewFormUpdated: (field, value) => dispatch(supplierReviewFormUpdated(field, value)),
    retrieveAllSupplierContactInformation: () => dispatch(retrieveAllSupplierContactInformation()),
    retrieveAllSupplierBusinessInformation: () => dispatch(retrieveAllSupplierBusinessInformation()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    postCollectedSupplierReviewInformation: postData => dispatch(postCollectedSupplierReviewInformation(postData))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalReviews)
