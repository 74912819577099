import React, { Component } from 'react'
import { connect } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {
    openGalleryModal,
    retrievePaginatedImagesOnScorll,
    loadAllEventInformationFromEventPhotoId,
} from '../../../actions/galleryActions'

import GalleryModal from './GalleryModal'
import { LazyLoad } from '../../../reusables/LazyLoad/LazyLoad'
import multiple from '../../../assets/images/icons/multiple.png'

class Photos extends Component {

    openGalleryModal = image_id => {
        const {
            openGalleryModal,
            loadAllEventInformationFromEventPhotoId
        } = this.props
        openGalleryModal()
        loadAllEventInformationFromEventPhotoId(image_id)
        document.querySelector('body').classList.add('modal-open')
    }

  render () {

      const { photos } = this.props

        return (
            <div className="gallery">
                {photos && photos.length > 0 &&
                    photos.map((eachImage, key) =>
                        <div className="gallery__image"                            
                            key={key}
                            key={eachImage.images.image_id}
                            onClick={() => this.openGalleryModal(eachImage.images[0].image_id)}
                        >
                            <LazyLoad src={eachImage.images[0].src} style="gallery__image-item" alter="gallery" />
                            {eachImage.images.length > 1 && (
                                <div className="gallery__image--multiple">
                                    <img className="" src={multiple} alt="folder" />
                                </div>
                            )}
                            <div className="gallery__image-hover-overlay">
                                <span className="gallery__overlay-icons">
                                    <i className="fa fa-heart" aria-hidden="true"></i>0
                                </span>
                                <span className="gallery__overlay-icons">
                                    <i className="fa fa-comment" aria-hidden="true"></i>0
                                </span>
                            </div>
                        </div>
                    )}
                <GalleryModal />
            </div>
        
        )
    }
}

const mapStateToProps = ({ gallery }) => ({
    allImages: gallery.allImages,
    pagesCount: gallery.pagesCount,
    currentPage: gallery.currentPage,
})

const mapDispatchToProps = dispatch => ({
    openGalleryModal: () => dispatch(openGalleryModal()),
    retrievePaginatedImagesOnScorll: eventTypeId => dispatch(retrievePaginatedImagesOnScorll(eventTypeId)),
    loadAllEventInformationFromEventPhotoId: imageId => dispatch(loadAllEventInformationFromEventPhotoId(imageId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Photos)
