import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router'
import Rodal from 'rodal'
import HayerImageCrop from 'hayer-react-image-crop'
import 'hayer-react-image-crop/style.css'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'

import { ImageUploadPreview } from '../../reusables/ImageUploadPreview/ImageUploadPreview'

import { checkLoginStatus } from '../../actions/sessionActions'
import {     
    loadSupplierCategories, 
    handleSupplierProfilePicUpload,
    supplierProfileBusinessFormUpdate, 
    handleSupplierProfileCoverImageUpload,
    retrieveAllSupplierBusinessInformation,     
    postCollectedSupplierBusinessInformation
} from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

import profile_sample from '../../../src/assets/images/profile_image1.png'
import { 
    dataURLtoFile, 
    refactorTheProfileOrCoverImage, 
} from '../../utils/misc'
import { coverImagesUploadingPath, profilePhotosImagesUploadingPath } from '../../utils/config'
import { loadCountries } from '../../utils/misc'
import removeAccents from 'remove-accents'

const useStyles = theme => ({
    option: {
      fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
})
class BusinessInformation extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            src: null,
            preview: null,
            crop: {
                aspect: 1,
                width: 50,
                x: 0,
                y: 0
            },
            town: "",        
            street: "",
            country: {},
            website: "", 
            postcode: "", 
            jobCost: null, 
            companyBio: "",         
            costDetails: "",       
            companyName: "",  
            coverImage: null,
            selectedCity: "",
            oldProfilePic: "",         
            oldCoverImage: "",        
            selectedState: "",        
            businessAwards: "",                
            croppedImageUrl: "",                
            selectedCountry: "",             
            jobCostToObject: null,
            initialPageLoad: false,
            modalBoxVisbile: false,
            profilePicResource: "",
            jobCostFromObject: null,                
            croppedImageUrlPreview: "",            
            modalBoxVisbileForBasicProfile: false,
            jobCastTypes: [
                {
                    "key": "Under $20,000",
                    "text": "Under $20,000"
                },
                {
                    "key": "$20,000-$30,000",
                    "text": "$20,000-$30,000"
                },
                {
                    "key": "$20,000-$50,000",
                    "text": "$20,000-$50,000"
                },
                {
                    "key": "Over $50,000",
                    "text": "Over $50,000"
                }
            ],
            errorFields: {
                website: false,            
                country: false,            
                coverImage: false,
                companyBio: false,
                companyName: false,
                croppedImageUrlPreview: false,
            },
            touched: {
                country: false,
                website: false,
                coverImage: false,
                companyBio: false,
                companyName: false,
                croppedImageUrlPreview: false,
            },
            errorsInTheForm: false,
            successInTheForm: false,
            selectedStateObject: {},
            loggedInRealStatus: true,        
        } 
    }

    componentWillMount () {
        this.props.checkLoginStatus().then(data => {
            if (!data) {
                this.setState({ loggedInRealStatus: false })
            } else {
                this._loadBusinessProfileInformation()   
            }            
        })
    }

    onClose = () => this.setState({ preview: null })

    countryToFlag = isoCode => {
        return typeof String.fromCodePoint !== 'undefined'
          ? isoCode
              .toUpperCase()
              .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
          : isoCode
    }
    
    onCrop = preview => this.setState({ preview: preview, touched: { ...this.state.touched, errorsInTheForm: true }})            
    
    showModalBox = () => this.setState({ modalBoxVisbile: true })

    hideModalBox = () => this.setState({ modalBoxVisbile: false })

    hideModalBoxForBasicProfile = () => this.setState({ modalBoxVisbileForBasicProfile: false })

    _loadBusinessProfileInformation = () => {
        this.props.retrieveAllSupplierBusinessInformation().then(data => {  
            if (data !== null) {
                const allCountriesSuggestions = loadCountries()
                const selectedCountryObject = allCountriesSuggestions.filter(each => each.label == data.country)
                this.setState({ 
                    initialPageLoad: true,  
                    country: selectedCountryObject[0],
                    town: data && removeAccents(data.town),
                    street: data && removeAccents(data.address),
                    website: data && removeAccents(data.website),
                    postcode: data && data.post_code,                    
                    coverImage: data && data.cover_image,                    
                    companyBio: data && removeAccents(data.company_bio),
                    companyName: data && removeAccents(data.company_name),                            
                    costDetails: data && data.cost_details,
                    oldProfilePic: data && data.profile_photo,   
                    oldCoverImage: data && data.cover_image,                         
                    jobCost: data && data.typical_job_cast_type,
                    businessAwards: data && removeAccents(data.business_awards),
                    croppedImageUrlPreview: this.props.values.selectedImage,                    
                    errorsInTheForm: (!data.company_name || !data.profile_photo || !data.cover_image || !data.company_bio || !data.country || !data.website) ? true : false,
                    modalBoxVisbileForBasicProfile: ((!data.profile_photo || !data.cover_image) ? true : false)
                })     
            }          
        }) 
    }

    _handleProfileImageCrop = e => {
        const img = new Image()       
        img.src = e
        img.onload = () => {            
            if (img.width >= 50 && img.height >= 50) {
                this.setState({ croppedImageUrlPreview: e, croppedImageUrl: dataURLtoFile(e, 'profile.jpg') })
            } else {
                this.props.showNotification('The selected image is not compatible with the file uploading size / dimentions!', 'error')
                setTimeout(() => this.props.hideNotification(), 1500)                        
            }
        }
    }

    _handleProfileImageCropError = err => {
        this.props.showNotification('Something went wrong! Please contact support...', 'error')
        setTimeout(() => this.props.hideNotification(), 1500)                
    }

    _completeTheImageCrop = e => {
        this.hideModalBox()
        this.props.selectedImage(this.state.croppedImageUrlPreview)
        this.props.supplierProfileBusinessFormUpdate('profilePhoto', this.state.croppedImageUrlPreview)     
    }

    _handleImageChangeForCoverImagae = e => {
        e.preventDefault()
        const reader = new FileReader()
        const file = e.target.files[0] 
        const fileSize = (file.size / (1024 * 1024))
        reader.readAsDataURL(file)
        reader.onload = () => {
            if (fileSize <= 5) {
                this.setState({ coverImagePreview: reader.result, selectedCoverImage: file, errorsInTheForm: false })  
            } else {
                this.props.showNotification('The selected image is not compatible with the file uploading size / dimentions!', 'error')
                setTimeout(() => this.props.hideNotification(), 1500)                        
            }            
        }
    }

    updateFormHandler = (field, event) => { 
        if (field == 'companyName') {
            this.setState({ 
                initialPageLoad: false,
                [field]: event.target.value.replace(/\\|\//g,''), 
                errorFields: {
                    [field]: (!event.target.value) ? true : false
                },
                errorsInTheForm: (!event.target.value) ? true : false
            })               
            this.props.supplierProfileBusinessFormUpdate([field], event.target.value.replace(/\\|\//g,''))         
    
        } else {
            this.setState({ 
                initialPageLoad: false,
                [field]: event.target.value, 
                errorFields: {
                    [field]: (!event.target.value) ? true : false
                },
                errorsInTheForm: (!event.target.value) ? true : false
            })               
            this.props.supplierProfileBusinessFormUpdate([field], event.target.value)             
        }
    }

    handleAutoCompleteChangeWithKey = value => {
        this.setState({ 
            'country': value, 
            errorFields: {
                ['country']: (value && !value.code) ? true : false
            },
            errorsInTheForm: (value && !value.code) ? true : false
        })               
        this.props.supplierProfileBusinessFormUpdate('country', value)  
    }

    handleBlurForCountryOnFocus = value => {
        this.setState({ 
            touched: { 
                ...this.state.touched,
                'country': true, 
            },
            errorFields: {
                ['country']: (value && !value.code) ? true : false
            },
        })               
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    handlePopoverOpen = event => this.setState({ anchorEl: event.currentTarget })
    
    handlePopoverClose = () => this.setState({ anchorEl: false })

    saveAndContinue = e => {
        e.preventDefault()
        const {
            town,
            street,
            jobCost,
            country,
            website,
            postcode,
            companyBio,
            companyName,                            
            costDetails,            
            oldCoverImage,           
            oldProfilePic,                                                 
            businessAwards,
            croppedImageUrl,
            selectedCoverImage,
            croppedImageUrlPreview
        } = this.state        
        if (companyName && companyBio && country.label && (oldProfilePic || croppedImageUrl) && (oldCoverImage || selectedCoverImage)) {  
            const postData = {
                supplierCompanyName: removeAccents(companyName),
                supplierProfilePhoto: oldProfilePic,
                supplierProfileCoverImage: oldCoverImage,
                supplierCompanyBio: removeAccents(companyBio),
                supplierBusinessAwards: removeAccents(businessAwards),
                supplierJobCost: jobCost,
                supplierCostDetails: costDetails,
                supplierStreet: street !== null ? street : '',
                supplierTown: town !== null ? town : '',
                supplierCity: "",
                supplierState: "",
                supplierCountry: country.label,
                supplierPostcode: postcode !== null ? postcode : '',
                supplierWebsite: website
            }  
            if (croppedImageUrlPreview || selectedCoverImage) {     
                if (croppedImageUrl) {
                    this.props.handleSupplierProfilePicUpload(croppedImageUrl, oldProfilePic).then(profilePicData => {
                        if ((profilePicData.imageUrl) && (profilePicData.imageUrl !== "")) {
                            postData.supplierProfilePhoto = profilePicData.imageUrl
                            this.props.postCollectedSupplierBusinessInformation(postData).then(data => {
                                if (data.status === "supplier_business_information_updated") {
                                    this.props.showNotification('Your business information have been updated!.', 'success')
                                    setTimeout(() => {
                                        this.props.hideNotification()
                                        // this.props.nextStep()                                
                                    }, 2500)      
                                } else {
                                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                                    setTimeout(() => this.props.hideNotification(), 2500)                
                                }
                            })                
                        } else {
                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                            setTimeout(() => this.props.hideNotification(), 2500)                
                        }
                        this._loadBusinessProfileInformation()   
                    })         
                }   
                if (selectedCoverImage) {                    
                    this.props.handleSupplierProfileCoverImageUpload(selectedCoverImage, oldCoverImage).then(coverImageData => {
                        if ((coverImageData.imageUrl) && (coverImageData.imageUrl !== "")) {
                            postData.supplierProfileCoverImage = coverImageData.imageUrl
                            this.props.postCollectedSupplierBusinessInformation(postData).then(data => {
                                if (data.status === "supplier_business_information_updated") {
                                    this.props.showNotification('Your business information have been updated!.', 'success')
                                    setTimeout(() => {
                                        this.props.hideNotification()
                                        // this.props.nextStep()                                
                                    }, 2500)      
                                } else {
                                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                                    setTimeout(() => this.props.hideNotification(), 2500)                
                                }
                            })                                                
                        }
                        this._loadBusinessProfileInformation()   
                    }) 
                }  
                if (croppedImageUrl && selectedCoverImage) {
                    this.props.handleSupplierProfilePicUpload(croppedImageUrl, oldProfilePic).then(profilePicData => {
                        if ((profilePicData.imageUrl) && (profilePicData.imageUrl !== "")) {
                            postData.supplierProfilePhoto = profilePicData.imageUrl
                            this.props.handleSupplierProfileCoverImageUpload(selectedCoverImage, oldCoverImage).then(coverImageData => {
                                if ((coverImageData.imageUrl) && (coverImageData.imageUrl !== "")) {
                                    postData.supplierProfileCoverImage = coverImageData.imageUrl
                                    this.props.postCollectedSupplierBusinessInformation(postData).then(data => {
                                        if (data.status === "supplier_business_information_updated") {
                                            this.props.showNotification('Your business information have been updated!.', 'success')
                                            setTimeout(() => {
                                                this.props.hideNotification()
                                                // this.props.nextStep()                                
                                            }, 2500)      
                                        } else {
                                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                                            setTimeout(() => this.props.hideNotification(), 2500)                
                                        }
                                    })                                                
                                }
                                this._loadBusinessProfileInformation()   
                            })                
                        } else {
                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                            setTimeout(() => this.props.hideNotification(), 500)                
                        }
                        this._loadBusinessProfileInformation()   
                    })         
                } else {
                    this.props.postCollectedSupplierBusinessInformation(postData).then(data => {
                        if (data.status === "supplier_business_information_updated") {
                            this.props.showNotification('Your business information have been updated!.', 'success')
                            this.props.nextStep()   
                            setTimeout(() => this.props.hideNotification(), 2500)      
                        } else {
                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                            setTimeout(() => this.props.hideNotification(), 2500)                
                        }
                    }) 
                }                
            } else {
                if (this.props.formUpdated) {
                    this.props.postCollectedSupplierBusinessInformation(postData).then(data => {
                        if (data.status === "supplier_business_information_updated") {
                            this.props.showNotification('Your business information have been updated!.', 'success')
                            // this.props.nextStep()        
                            setTimeout(() => this.props.hideNotification(), 2500)      
                        } else {
                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                            setTimeout(() => this.props.hideNotification(), 2500)                
                        }
                        this._loadBusinessProfileInformation()   
                    })                         
                } else {
                    this.props.nextStep()
                }
            }                      
        } else {
            this.props.nextStep()
        }
    } 

    render() {

        const { 
            loading,
            classes
        } = this.props         

        const { 
            src,
            town, 
            street,
            country,
            touched,
            jobCost,
            preview,
            website,
            anchorEl,
            postcode,
            companyBio,
            companyName,
            costDetails,
            errorFields,
            jobCastTypes,
            oldCoverImage,
            oldProfilePic,                       
            businessAwards,
            modalBoxVisbile,
            croppedImageUrl,  
            initialPageLoad, 
            coverImagePreview,
            selectedCoverImage,           
            loggedInRealStatus,
            croppedImageUrlPreview,
            modalBoxVisbileForBasicProfile
        } = this.state   
        const open = Boolean(anchorEl)

        if (!loggedInRealStatus) return <Redirect to={'/'} />

        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>  
                <div className="profile__business-info">
                    <div className="profile__section">
                        <Rodal className="rodal--profile-pic" visible={modalBoxVisbile} onClose={this.hideModalBox.bind(this)} animation={'slideDown'}>
                            <header className="rodal__header">
                                <h4 className="rodal__heading">Profile Image</h4>
                            </header>
                            <p className="profile__rodal-text">Drag the corner of the box to change the position and size, or upload a new image <br />(100px * 100px)</p>
                            <div className="rodal__main-container">
                                <div className="image-preview-container">
                                {
                                    croppedImageUrlPreview ?
                                        <img alt="Croped profile pic" className="image-preview--cropped-img" src={croppedImageUrlPreview || refactorTheProfileOrCoverImage(oldProfilePic, profilePhotosImagesUploadingPath)} /> : 
                                        <img alt="profile pic" className="image-preview" src={preview || profile_sample || refactorTheProfileOrCoverImage(oldProfilePic, profilePhotosImagesUploadingPath)} />
                                }
                                </div>  
                                <div className="image-file-select-container">
                                    {
                                        src ?
                                        <div className="image-cropped">
                                            <input type="button" className="btn btn-white image-crop-remove" value="Remove Image" onClick={this.removeImage} />
                                        </div> : 
                                        <div className="image-upload-wrap">
                                            <HayerImageCrop
                                                unit="px"                                                
                                                width={256}
                                                aspect={4/4}
                                                error={err => this._handleProfileImageCropError(err)}                                                
                                                onImageCrop={img => this._handleProfileImageCrop(img)}                                                
                                            />                                
                                        </div>
                                    } 
                                </div>    
                            </div>
                            <div className="rodal__btn-container">
                                <input type="button" className="btn btn-gold image-crop-complete" disabled={!croppedImageUrlPreview && true} value="Done" onClick={this._completeTheImageCrop} />
                            </div>
                        </Rodal>
                        <h5 className="profile__sub-title">Create An EventBuzz360 Profile</h5>                        
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile">                                    
                                    <div className="eb-forms__form-group cover-pro-container">
                                        <div 
                                            className="cover-pic-container"
                                            onMouseEnter={this.handlePopoverOpen}
                                            onMouseLeave={this.handlePopoverClose}
                                        >
                                            <ImageUploadPreview 
                                                coverImageHandler={this._handleImageChangeForCoverImagae}
                                                coverImagePreview={coverImagePreview ? coverImagePreview : (oldCoverImage ? refactorTheProfileOrCoverImage(oldCoverImage, coverImagesUploadingPath) : null)}                                                
                                            />                                                                                  
                                        </div>                                        
                                        <div className="pro-pic-container">
                                            <div 
                                                onClick={this.showModalBox}
                                                className={`${((touched.croppedImageUrlPreview && !croppedImageUrlPreview) || (errorFields.croppedImageUrlPreview)) ? 
                                                    'error__fields-indicator profile__profile-photo' : 'profile__profile-photo profile__profile-photo--cropped'}`} 
                                            >
                                                {
                                                    croppedImageUrlPreview || oldProfilePic ?
                                                        <img 
                                                            alt="Croped profile pic" 
                                                            className="image-preview--main-cropped-img" 
                                                            src={oldProfilePic ? refactorTheProfileOrCoverImage(oldProfilePic, profilePhotosImagesUploadingPath) : croppedImageUrlPreview} 
                                                        /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <span className="profile__section-content-tips">* Indicates a required field</span>
                                    <p className="profile__section-content-tips">* Maximum file upload: 5MB (JPG / PNG)</p>
                                    <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Tell us a little about your business</h6>
                                    <div className="eb-forms__form-group">
                                        <div className="profile__input-container">
                                            <TextField
                                                fullWidth
                                                margin="small"                                                
                                                variant="outlined"                                                          
                                                label={'Company Name'} 
                                                placeholder="Company Name"  
                                                inputProps={{style: {fontSize: 12}}}                                                                                                                                                
                                                onBlur={this.handleBlur('companyName')}        
                                                value={companyName != 'null' ? companyName : ''}                                                                                                                                                                                               
                                                InputLabelProps={{style: {fontSize: 12}}, { required: true }}
                                                error={((touched.companyName && !companyName) || (errorFields.companyName))}   
                                                onChange={(event, newValue) => this.updateFormHandler('companyName', event)}                                                    
                                            />
                                            {((touched.companyName && !companyName) || (errorFields.companyName)) && <span className="error">Company Name is required!</span>}
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <TextField                                                                                        
                                            rows={1}
                                            fullWidth
                                            multiline
                                            rowsMax={2}                                            
                                            margin="small"                                                                                            
                                            name="company-bio"
                                            variant="outlined"                                                                                                                                                                                                                     
                                            label={'Company Bio'} 
                                            placeholder="Company Bio"
                                            inputProps={{style: {fontSize: 12}}}                                                                                        
                                            onBlur={this.handleBlur('companyBio')}    
                                            value={companyBio != 'null' ? companyBio : ''}
                                            InputLabelProps={{style: {fontSize: 12}}, { required: true }}   
                                            error={((touched.companyBio && !companyBio) || (errorFields.companyBio))}                                                
                                            onChange={(event, newValue) => this.updateFormHandler('companyBio', event)}                                                                                                
                                        />              
                                        {((touched.companyBio && !companyBio) || (errorFields.companyBio)) && <span className="error">Company Bio is required!</span>}                          
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <TextField                                                                                        
                                            rows={1}
                                            fullWidth
                                            multiline
                                            rowsMax={2}                                            
                                            margin="small"        
                                            variant="outlined"                                                 
                                            name="business-awards"                                                                                                                                                                     
                                            label="Business Awards" 
                                            placeholder="Business Awards"
                                            inputProps={{style: {fontSize: 12}}}   
                                            InputLabelProps={{style: {fontSize: 12}}}
                                            onBlur={this.handleBlur('businessAwards')}                                                                                    
                                            value={businessAwards != 'null' ? businessAwards : ''}                                                                                                                                                                                                                                                           
                                            onChange={(event, newValue) => this.updateFormHandler('businessAwards', event)}                                                
                                        />
                                    </div>
                                    <h6 className="profile__sub-title sub--heading_presentations">Head Office Address Details</h6>
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--not-fullwidth">
                                            <div className="container">
                                                <Autocomplete
                                                    fullWidth
                                                    options={loadCountries()}
                                                    classes={{
                                                        option: classes.option,
                                                    }}
                                                    autoHighlight                                                    
                                                    getOptionLabel={option => option.label}
                                                    value={country != 'null' ? country : ''}
                                                    renderOption={option => (
                                                        <React.Fragment>
                                                            <span>{this.countryToFlag(option.code)}</span>
                                                            {option.label} ({option.code}) +{option.phone}
                                                        </React.Fragment>
                                                    )}
                                                    InputLabelProps={{style: {fontSize: 12}}, { required: true }}
                                                    onBlur={(event, newValue) => this.handleBlurForCountryOnFocus(newValue)}
                                                    onChange={(event, newValue) => this.handleAutoCompleteChangeWithKey(newValue)}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Country"                                                            
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                            }}
                                                            InputLabelProps={{style: {fontSize: 12}}, { required: true }}
                                                            error={((touched.country && (!country || !country.code)) || (errorFields.country))}    
                                                        />
                                                    )}                                                    
                                                />    
                                                {((touched.country && (!country || !country.code)) || (errorFields.country)) && <span className="error">Country is required!</span>}                                                                          
                                            </div>                                            
                                        </div>
                                    </div>                                    
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--fullwidth three-grid-view-full-width-wrapper">                                           
                                            <div className="three-grid-view">
                                                <TextField
                                                    fullWidth
                                                    name="street"
                                                    margin="small"                                                                
                                                    variant="outlined"                                                                                                                             
                                                    label="Number / Street"   
                                                    inputProps={{style: {fontSize: 12}}}
                                                    value={street != 'null' ? street : ''}                                                                                        
                                                    onBlur={() => this.handleBlur('street')}
                                                    InputLabelProps={{style: {fontSize: 12}}}
                                                    onChange={(event, newValue) => this.updateFormHandler('street', event)}                                                    

                                                />
                                            </div>
                                            <div className="three-grid-view">
                                                <TextField
                                                    fullWidth
                                                    margin="small"                                                     
                                                    name="town-suburb"                                               
                                                    variant="outlined"                                                                                                                             
                                                    label="Town / Suburb"   
                                                    value={town != 'null' ? town : ''}                                    
                                                    inputProps={{style: {fontSize: 12}}}
                                                    onBlur={() => this.handleBlur('town')}
                                                    InputLabelProps={{style: {fontSize: 12}}}
                                                    onChange={(event, newValue) => this.updateFormHandler('town', event)}                                                    

                                                />
                                            </div>
                                            <div className="three-grid-view">
                                                <TextField
                                                    fullWidth                                                    
                                                    margin="small" 
                                                    name="postcode"                                                                                                   
                                                    label="Postcode"   
                                                    variant="outlined"                                                                                                                                                                             
                                                    inputProps={{style: {fontSize: 12}}}                                                                              
                                                    InputLabelProps={{style: {fontSize: 12}}}
                                                    onBlur={() => this.handleBlur('postcode')}
                                                    value={postcode != 'null' ? postcode : ''}                                                                                                     
                                                    onChange={(event, newValue) => this.updateFormHandler('postcode', event)}                                                    

                                                />
                                            </div>                                                                                   
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <div className="profile__input-container">
                                            <TextField
                                                fullWidth
                                                margin="small" 
                                                name="website"                                               
                                                label="Website"                                                   
                                                variant="outlined"                                                                                                                                                                             
                                                inputProps={{style: {fontSize: 12}}}                                                                                                
                                                value={website != 'null' ? website : ''}
                                                InputLabelProps={{style: {fontSize: 12}}}
                                                onBlur={() => this.handleBlur('website')}
                                                onChange={(event, newValue) => this.updateFormHandler('website', event)}                                                    
                                            />
                                        </div>
                                    </div>
                                    <h6 className="profile__sub-title sub--heading_presentations">Help prospective clients understand the typical budget you work with</h6>
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--not-fullwidth">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="job-cost">{!initialPageLoad ? 'Typical Job Cost' : (jobCost ? '' : 'Typical Job Cost')}</InputLabel>
                                                <Select                                                                                                                                                              
                                                    labelId="job-cost"              
                                                    style={{ width: 300 }}                                                  
                                                    label={"Typical Job Cost"}                
                                                    placeholder={'Typical job cost'}                                               
                                                    inputProps={{style: {fontSize: 12}}}                                                                                                                             
                                                    value={jobCost != 'null' ? jobCost : ''}                                                      
                                                    textFieldProps={{
                                                        label: "Label",
                                                        InputLabelProps: jobCost ? { shrink : true } : {}
                                                    }}
                                                    onChange={(event, newValue) => this.updateFormHandler('jobCost', event)}                                                    
                                                >
                                                    {jobCastTypes.map(eachType => <MenuItem value={eachType.key}>{eachType.text}</MenuItem>)}
                                                </Select>
                                            </FormControl>   
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <TextField                                                                                        
                                            rows={1}
                                            fullWidth
                                            multiline
                                            rowsMax={2}                                            
                                            margin="small"                                                  
                                            variant="outlined"                                              
                                            name="cost-details"                                                                                                                                                                     
                                            label="Cost Details"   
                                            inputProps={{style: {fontSize: 12}}}                                                                                                
                                            InputLabelProps={{style: {fontSize: 12}}}
                                            value={costDetails != 'null' ? costDetails : ''}
                                            onChange={(event, newValue) => this.updateFormHandler('costDetails', event)}                                                                                            
                                            placeholder="Budget Range, Your Standard Fees and Charges. e.g. M.C. charge is $5,000 per day + GST"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="profile__button-container profile__button-container--single">                           
                            <button                           
                                disabled={
                                    (!companyName || !companyBio || !country) ||
                                    ((!croppedImageUrl && !oldProfilePic) || (!oldCoverImage && !selectedCoverImage))                                    
                                }      
                                onClick={this.saveAndContinue}
                                className="btn btn-gold profile__button profile__button-next"                                                                 
                            >Save</button>                            
                        </div>
                    </div>
                </div>
                <Rodal className="rodal--profile-pic rodal--profile-pic-overrided" visible={modalBoxVisbileForBasicProfile} onClose={this.hideModalBoxForBasicProfile.bind(this)} animation={'slideDown'}>
                    <header className="rodal__header">
                        <h4 className="rodal__heading">Add your Profile Photo and Cover Photo</h4>
                    </header>
                    <p className="profile__rodal-text">Next upload your <span className="gold-color__text">{'Profile Photo'}</span> and <span className="gold-color__text">{'Cover Photo'}</span>!</p>                    
                    <p className="profile__rodal-text">Then proceed to fill out the information about your company.</p>                    
                </Rodal>
            </>    
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,        
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierBusinessInfomration.formUpdated
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),    
    hideNotification: () => dispatch(hideNotification()),
    loadSupplierTypes: () => dispatch(loadSupplierCategories()),        
    retrieveAllSupplierBusinessInformation: () => dispatch(retrieveAllSupplierBusinessInformation()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),                
    supplierProfileBusinessFormUpdate: (field, value) => dispatch(supplierProfileBusinessFormUpdate(field, value)),              
    postCollectedSupplierBusinessInformation: postData => dispatch(postCollectedSupplierBusinessInformation(postData)),
    handleSupplierProfilePicUpload: (croppedImageUrl, oldProfilePic) => dispatch(handleSupplierProfilePicUpload(croppedImageUrl, oldProfilePic)),
    handleSupplierProfileCoverImageUpload: (selectedCoverImage, oldCoverImage) => dispatch(handleSupplierProfileCoverImageUpload(selectedCoverImage, oldCoverImage))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(BusinessInformation))