import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { ProfileCompletionProgress } from './ProfileCompletionProgress'

import { retrieveAllSupplierBusinessInformation } from '../../actions/profileActions'
import { openModal } from '../../actions/newLoginActions'

import { CountryPopover } from '../Global/CountryPopover'
class BlackTopBar extends Component {

    state = {
        isHidden: true,
        profileProgress: 25,
        defaultLanguage: '',
        basicProfileCompleted: false,        
    }

    componentDidMount () {
        if (this.props.isLoggedIn) {
            this.props.retrieveAllSupplierBusinessInformation().then(data => {  
                if (data && data.company_name && data.cover_image && data.profile_photo && data.company_bio && data.country) {
                    this.setState({ basicProfileCompleted: true })
                }                     
            })             
        }
        // document.addEventListener('mousedown', this.handleClick, false)
    }

    componentWillUnmount () {
        // document.removeEventListener('mousedown', this.handleClick, false)
    }

    togglePopover = e => {
        e.preventDefault()
        this.setState({ isHidden: !this.state.isHidden })
    }

    handleClick = e => (!this.node.contains(e.target)) && this.setState({ isHidden: true })

    filteredCountry = selectedCountry => this.props.filteredCountry(selectedCountry)

    userLogout = () => {
        this.props.userLogout()
        window.location.href = "/gallery"
    }

    openLoginModal = () => {
        this.props.openModal()
        document.querySelector('body').classList.add('modal-open')
    }

    render() {

    const { 
        t,
        userType,
        username,
        countries,
        isLoggedIn,
        displayName,
        activeCountry,
        changeLanguage,
        defaultLanguage,        
    } = this.props

    let linkHeader = null
    const language = localStorage.getItem('language')    
    const { isHidden, basicProfileCompleted, profileProgress } = this.state

    if (isLoggedIn && userType == 4) {
        linkHeader = '/gallery'        
    } else if ((isLoggedIn) && (userType == 2 || userType == 3)) {
        if (basicProfileCompleted) {
            linkHeader = '/add-event'        
        } else {
            linkHeader = '/profile'            
        }        
    } else if ((isLoggedIn) && (userType == 1)) {
        linkHeader = '/profile'        
    } else {
        linkHeader = '/login'
    }
    
    return (  
        <ul className="header__black-top-bar-list">
            {/* <li className="header__black-top-bar-list-item" ref={node => { this.node = node }}>
                <Link 
                    to='' 
                    onClick={e => this.togglePopover(e)}
                    className={isHidden ? "header__black-top-bar-list-link header__black-top-bar-popover-trigger" : "header__black-top-bar-list-link header__black-top-bar-popover-trigger header__black-top-bar-list-item--active"}  
                >{language}</Link>
                {!this.state.isHidden && <CountryPopover t={t} countries={countries} defaultLanguage={defaultLanguage} activeCountry={activeCountry} changeLanguage={changeLanguage} filteredCountry={this.filteredCountry} />}
            </li>           */}
            {/* <li className="header__black-top-bar-list-item">
                <Link to='/wishlist' className="header__black-top-bar-list-link">{t('WISHLIST')}</Link>
            </li> */}
            {isLoggedIn ? (
                <>
                    <li className="header__black-top-bar-list-item">
                        <Link to={linkHeader} className="header__black-top-bar-list-link">
                            {/* {t('WELCOME')}  */}
                            {displayName || username}
                        </Link>
                    </li>
                    <li className="header__black-top-bar-list-item">
                        <a onClick={this.userLogout} className="header__black-top-bar-list-link">{t('LOGOUT')}</a>
                    </li>
                    {/* <li className="header__black-top-bar-list-item">
                        <ProfileCompletionProgress profileProgress={profileProgress} />
                    </li> */}
                </>
            ): (
                <li className="header__black-top-bar-list-item">
                    <a onClick={this.openLoginModal} className="header__black-top-bar-list-link">{t('LOGIN')} / {t('SIGNUP')}</a>
                </li>
            )}
        </ul>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    userType: session.userType,
    isLoggedIn: session.isLoggedIn,
})

const mapDispatchToProps = dispatch => ({
    openModal: () => dispatch(openModal()),
    retrieveAllSupplierBusinessInformation: () => dispatch(retrieveAllSupplierBusinessInformation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BlackTopBar)