import React from 'react'

import profile_image from '../../../assets/images/Person-Icon.png'
import { refactorTheProfileOrCoverImage } from "../../../utils/misc"
import { coverImagesUploadingPath, profilePhotosImagesUploadingPath } from '../../../utils/config'
import './styles/supplier-header.css'
import removeAccents from 'remove-accents'

export const SupplierHeader = ({ supplier, reviews, loggedUserEmail, showWriteReview, showContactForm }) => (
    <header className="supplier-header">
        <div className="supplier-header__cover-image-container">
            {supplier.cover_image && <img src={refactorTheProfileOrCoverImage(supplier.cover_image, coverImagesUploadingPath)} className="supplier-header__cover-image" alt="supplier cover image"/>}      
        </div>
        <div className="supplier-header__info-container">
            <div className="supplier-header__info">
                <div className="supplier-header__logo-container">
                    {supplier.profile_photo ? 
                        <img src={supplier.profile_photo && refactorTheProfileOrCoverImage(supplier.profile_photo, profilePhotosImagesUploadingPath)} className="supplier-header__logo" alt="supplier logo image" />
                        : <img className="supplier-header__logo--dummy" src={profile_image && refactorTheProfileOrCoverImage(profile_image, profilePhotosImagesUploadingPath)}  />}
                </div>
                <div className="supplier-header__details">
                    {/* <h1 className="supplier-header__supplier-name">{removeAccents(supplier.company_name)}</h1> */}
                    <h1 className="supplier-header__supplier-name">{supplier.company_name}</h1>
                    <div className="supplier-header__star-container">                        
                        <span className="supplier-header__current-reviews">{reviews ? reviews.length : 0} Review(s) </span>
                        {loggedUserEmail !== supplier.email_address && <a onClick={showWriteReview} className="supplier-header__review-link">Review me</a>}                        
                    </div>
                </div>
            </div>
            <div className="supplier-header__contact-container">
                {/* <a target="_blank" href={`mailto:${supplier.email_address}`} className="btn supplier-header__contact supplier_profile-header-btn">Contact</a> */}
                <button className="btn supplier-profile__write-review" onClick={showContactForm}>Contact</button>
            </div>
        </div>
    </header>
)