export const LocalStorageService = {

    updateLocalStorage(data) {
        localStorage.setItem('accessToken', data.accessToken)
        localStorage.setItem('userId', data.userId)
        localStorage.setItem('userName', data.userName)
        localStorage.setItem('userType', data.userType)
        localStorage.setItem('displayName', data.displayName)
    },

    clearLocalStorage() {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userId')
        localStorage.removeItem('userName')
        localStorage.removeItem('userType')
        localStorage.removeItem('displayName')
    },

    updateLocalStorageSingleItem(key, value) {
        localStorage.setItem(key, value)
    },

    getLocalStorageSingleItem(key) {
        localStorage.getItem(key)
    }
}