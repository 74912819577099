import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../../actions/sessionActions'
import { 
    fetchRelatedSubServices, 
    supplierContactFormUpdate,
    connectWithTheSelectedSupplier, 
    fetchRelatedSuppliersForFindProOrHire,
    fetchRelatedSuppliersForFindProOrHireAsMainCategory 
} from '../../../actions/generalActions'
import { showNotification, hideNotification } from "../../../actions/notificationActions"

import { FindProSidebar } from './FindProSidebar'
import FindProGallery from './FindProGallery'
import Header from '../../Global/Header'
import Footer from '../../Global/Footer'

import chevron from '../../../assets/images/right_arrow.svg'

import '../find-pro-hire.css'

class FindProDetail extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    state = {
        errorsInTheForm: true,
        supplierEmailMessage: "",
        errorFields: {
            supplierEmailMessage: false
        },
        touched: {
            supplierEmailMessage: false
        },
        contactEmail: "",
        relatedSubServices: [],
        showContactModal: false,
        resetTheSupplierContactForm: false
    }

    componentDidMount() {   
        // setTimeout(() => {
        //     this.myRef.current.scrollIntoView({ behavior: 'smooth', block: "start" })  
        // }, 100)
        const qString = this.props.match.params
        this.props.checkLoginStatus().then(data => {
            if ((data !== null) && (data.message !== "Expired token") && (data.status !== "error")) {
                this.setState({ loggedInRealStatus: true })
            }            
        })               
        if (this.props.location.pathname.indexOf('find-pro-categories') == -1) {
            this.props.fetchRelatedSuppliersForFindProOrHire(qString.mainId, qString.subId).then(relatedSupplierInfo => {
                if ((relatedSupplierInfo !== 'no_sub_category_found') && (relatedSupplierInfo !== 'no_main_category_found')) {
                    this.setState({ relatedSupplierInfo: relatedSupplierInfo.suppliers })
                    this.props.fetchRelatedSubServices(relatedSupplierInfo.main_service_id).then(relatedSubServices => this.setState({ relatedSubServices }))                    
                } else {
                    if (relatedSupplierInfo === 'no_main_category_found') {
                        this.props.showNotification('Main category not found!', 'error')
                    } else if (relatedSupplierInfo === 'no_sub_category_found') {
                        this.props.showNotification('Sub category not found!', 'error')
                    } else {
                        this.props.showNotification('Something went wrong... Please contact administration for support!', 'error')
                    }                
                    setTimeout(() => {
                        this.props.hideNotification()
                        this.props.history.push('/find-pro')
                    }, 6000)                    
                }
            })            
        } else {
            this.props.fetchRelatedSuppliersForFindProOrHireAsMainCategory(qString.mainId).then(relatedSupplierInfo => {
                if (relatedSupplierInfo !== 'no_main_category_found') {
                    this.setState({ relatedSupplierInfo: relatedSupplierInfo.suppliers })
                    this.props.fetchRelatedSubServices(relatedSupplierInfo.main_service_id).then(relatedSubServices => this.setState({ relatedSubServices }))                    
                } else {
                    if (relatedSupplierInfo === 'no_main_category_found') {
                        this.props.showNotification('Main category not found!', 'error')
                    } else {
                        this.props.showNotification('Something went wrong... Please contact administration for support!', 'error')
                    }                
                    setTimeout(() => {
                        this.props.hideNotification()
                        this.props.history.push('/find-pro')
                    }, 6000)                    
                }
            })   
        }
    }

    componentDidUpdate(nextProps) {        
        if (nextProps.location.pathname != this.props.location.pathname) {
            const qString = this.props.match.params
            const currentUrlSplitted = this.props.history.location.pathname.split('/')            
            if (currentUrlSplitted[2] && currentUrlSplitted[3]) {                
                this.props.fetchRelatedSuppliersForFindProOrHire(qString.mainId, qString.subId).then(relatedSupplierInfo => {
                    this.setState({ relatedSupplierInfo: relatedSupplierInfo.suppliers })
                    this.props.fetchRelatedSubServices(relatedSupplierInfo.main_service_id).then(relatedSubServices => this.setState({ relatedSubServices }))                
                })            
            }
        }
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    updateFormHandler = (field, event) => {         
        this.setState({ 
            [field]: event.target.value, 
            errorFields: {
                [field]: (!event.target.value) ? true : false
            },
            errorsInTheForm: (!event.target.value) ? true : false
        })               
        this.props.supplierContactFormUpdate([field], event.target.value) 
    }

    connect = contactEmail => {
        if (!this.props.isLoggedIn) {
            this.props.showNotification('Please login to connect with the supplier...', 'info')
            setTimeout(() => this.props.hideNotification(), 6000)                    
        } else {
            this.setState({ showContactModal: true, contactEmail: contactEmail })
        }
    }

    redirectToHome = () => this.props.history.push('/find-pro')

    onCloseContactModalBoxHandler = () => {
        this.setState({ 
            showContactModal: false, 
            contactEmail: "", 
            supplierEmailMessage: "",         
            errorFields: {
                supplierEmailMessage: false
            },
            touched: {
                supplierEmailMessage: false
            },
        }) 
        this.props.supplierContactFormUpdate("supplierEmailMessage", "") 
    }

    contactTheSupplier = contactEmail => {
        this.props.connectWithTheSelectedSupplier(contactEmail, this.props.userName, this.props.displayName).then(data => {
            if (data === 'supplier_contact_success') {
                this.props.showNotification('Your message has been sent successfully!', 'success')                
            } else {
                this.props.showNotification('Something went wrong... Please contact administration for support!', 'error')
            }
            this.setState({ showContactModal: false, contactEmail: "" })            
            setTimeout(() => this.props.hideNotification(), 6000)                    
        })
    }

    render() {

        const qString = this.props.match.params
        const { loading, supplierEmailMessage } = this.props

        const { 
            touched,
            errorFields,
            contactEmail,
            errorsInTheForm,
            showContactModal, 
            relatedSubServices, 
            relatedSupplierInfo, 
        } = this.state

        return (
            <>
                <Header />
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>            
                <div ref={this.myRef} className="main">
                    <div className="find-pro__back-container">
                        <a onClick={this.redirectToHome} className="find-pro__back">
                        <img src={chevron} alt="icon" className="find-pro__back-chevron" />Find a Pro</a>
                    </div>
                    <div className="find-pro-detail__container">
                        <FindProSidebar                             
                            main={qString.mainId}   
                            mainCat={qString.main}
                            relatedSubServices={relatedSubServices}
                        />
                        <FindProGallery   
                            touched={touched}
                            connect={this.connect}                     
                            errorFields={errorFields}
                            mainHeading={qString.main}
                            contactEmail={contactEmail}
                            handleBlur={this.handleBlur}
                            errorsInTheForm={errorsInTheForm}
                            showContactModal={showContactModal}                                                        
                            relatedSupplierInfo={relatedSupplierInfo} 
                            updateFormHandler={this.updateFormHandler}
                            supplierEmailMessage={supplierEmailMessage}
                            contactTheSupplier={this.contactTheSupplier}                                                   
                            onCloseHandler={this.onCloseContactModalBoxHandler}                            
                        />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ general, session }) => ({
    loading: general.loading,
    userName: session.username,
    isLoggedIn: session.isLoggedIn,
    displayName: session.displayName,
    supplierEmailMessage: general.supplierEmailMessage
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    hideNotification: () => dispatch(hideNotification()),    
    fetchRelatedSubServices: mainServiceId => dispatch(fetchRelatedSubServices(mainServiceId)),
    supplierContactFormUpdate: (field, value) => dispatch(supplierContactFormUpdate(field, value)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),            
    fetchRelatedSuppliersForFindProOrHireAsMainCategory: main => dispatch(fetchRelatedSuppliersForFindProOrHireAsMainCategory(main)),
    fetchRelatedSuppliersForFindProOrHire: (main, categoryName) => dispatch(fetchRelatedSuppliersForFindProOrHire(main, categoryName)),    
    connectWithTheSelectedSupplier: (emailToConnect, emailConnectFrom, emailConnectFromName, emailMessage) => dispatch(connectWithTheSelectedSupplier(emailToConnect, emailConnectFrom, emailConnectFromName, emailMessage))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(FindProDetail)