import * as actions from "../actions/galleryActions"

const initialState = {
    rating: 0,
    loading: true,
    allImages: [],    
    pagesCount: 0,
    currentPage: 0,
    photosCount: 0,
    eventComment: "",
    reviwerEmail: "",
    modalLoading: true,
    selectedImage: null,
    galleryModalData: {},
    reviewerLastName: "",    
    reviewerContactNo: "",
    reviewerFirstName: "",
    currentImageObject: {},
    reviewerProjectCost: "",    
    galleryModalOpen: false,
    reviewerDescription: "",    
    reviewerRelationship: "",        
    reviewerStreetAddress: "",
    reviewerProjectCompletionMonth: null,
    reviewerProjectCompletionYear: null
}

export const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.OPEN_GALLERY_MODAL:
            return {
                ...state,
                galleryModalOpen: true
            }
        case actions.CLOSE_GALLERY_MODAL:
            return {
                ...state,
                galleryModalOpen: false,
                modalLoading: true,
                // galleryModalData: {},
                // currentImageObject: {}
            }
        case actions.SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_GALLERY_IMAGES_RETRIEVAL_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }                      
        case actions.SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                allImages: action.data
            }
        case actions.SEND_LOAD_ALL_REGISTERED_EVENTS_INDEX_PHOTOS_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.data
            }
        case actions.LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST:
            return {
                ...state,
                modalLoading: true,
                selectedImage: action.data
            }
        case actions.LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST_SUCCESS:
            action.data.event_photos.map(eachItem => {
                eachItem.original = eachItem.image_link
                eachItem.thumbnail = eachItem.image_link
            })
            return {
                ...state,
                modalLoading: false,
                galleryModalData: action.data,
                currentImageObject: action.data.event_photos.filter(eachImage => (state.selectedImage == eachImage.image_id))
            }
        case actions.LOAD_ALL_EVENT_INFORMATION_FROM_EVENT_PHOTO_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_FETCH_SUPPLIER_EVENT_INFORMATION_PUBLIC_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETREIVE_SUPPLIER_EVENT_OTHER_PHOTOS_FOR_PUBLIC_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_LOAD_EVENT_RELATED_KEYWORDS_FOR_PUBLIC_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_EVENT_COMMENT_FORM_UPDATE:
            return {
                ...state,
                [action.field]: action.value
            }
        case actions.SEND_LOAD_PAGINATED_IMAGES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_LOAD_IMAGE_PAGES_SUCCESS: 
            return {
                ...state,
                photosCount: parseInt(action.data.photos_count),
                pagesCount: action.data.pages_count
            } 
        case actions.SEND_LOAD_PAGINATED_IMAGES_REQUEST_SUCCESS:
            return {
                ...state,
                allImages: state.allImages.concat(action.data.event_photos),
                currentPage: state.currentPage + 1,
                loading: false,
            }
        case actions.SEND_LOAD_PAGINATED_IMAGES_REQUEST_FAILURE:
            return {
                ...state,
                error: action.data
            }
        case actions.SUBMIT_COMMENT_FOR_EVENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SUBMIT_COMMENT_FOR_EVENT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SUBMIT_COMMENT_FOR_EVENT_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.ADD_SUPPLIER_PUBLIC_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.ADD_SUPPLIER_PUBLIC_PROFILE_REVIEW_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.ADD_SUPPLIER_PUBLIC_PROFILE_REVIEW_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_PUBLIC_PROFILE_REVIEW_FORM_UPDATE:
            return {
                ...state,
                [action.field]: action.value
            }
        default:
            return state
    }
}