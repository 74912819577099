import * as actions from "../actions/profileActions"
import {
    REMOVE_SELECTED_SUB_SERVICE_REQUEST,
    REMOVE_ADDIONAL_SERVICE_AREA_REQUEST,
    REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_FAILURE,
    REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_SUCCESS,
} from "../actions/profileActions"

const initialState = {
    loading: false,
    oldPassword: "",
    newPassword: "",
    supplierTypes: [],
    confirmPassword: "",
    supplierBusinessInfomration: {
        town: "",
        street: "",
        website: "",
        country: "",
        jobCost: "",
        postcode: "",
        jobCostTo: "",
        companyBio: "",
        companyName: "",
        jobCostFrom: "",
        costDetails: "",
        supplierType: "",
        profilePhoto: "",
        businessAwards: "",
        formUpdated: false,
        profileCoverImage: ""
    },
    supplierContactInfomration: {
        telNo: "",
        mobileNo: "",
        surname: "",
        firstName: "",
        emailAddress: "",
        formUpdated: false
    },
    supplierSocialInfomration: {
        profileBlog: "",
        profileVideo: "",
        profileVimeo: "",
        profileFacebook: "",
        profileInstagram: "",
        profilePinterest: "",
        profileLinkedIn: "",
        formUpdated: false
    },
    supplierEventInformation: {
        eventName: "",
        eventDescription: "",
        venueName: "",
        venueLink: "",
        eventLocation: "",
        eventYear: "",
        projectBudget: "",
        photographerCredits: "",
        otherCredits: "",
        imagesAppearIn: "",
        keywords: [],
        formUpdated: false
    },
    supplierReviewform: {
        reviewerName: "",
        reviewerEmail: "",
        reviewerComment: "",
        formUpdated: false
    },
    sendContactMessageResponse:'',
}

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES:
            return {
                ...state,
                loading: true
            }
        case actions.LOAD_ALL_COUNTRIES:
            return {
                ...state,
                loading: true
            }
        case actions.LOAD_ALL_COUNTRIES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.LOAD_ALL_STATES:
            return {
                ...state,
                loading: true
            }
        case actions.LOAD_ALL_STATES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.LOAD_ALL_CITIES:
            return {
                ...state,
                loading: true
            }
        case actions.LOAD_ALL_CITIES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SUPPLIER_PROFILE_BUSINESS_INFORMATION_FORM_UPDATE:
            return {
                ...state,
                supplierBusinessInfomration: {
                    [action.field]: action.value,
                    formUpdated: true
                }
            }
        case actions.SUPPLIER_PROFILE_CONTACT_INFORMATION_FORM_UPDATE:
            return {
                ...state,
                supplierContactInfomration: {
                    [action.field]: action.value,
                    formUpdated: true
                }
            }
        case actions.SUPPLIER_PROFILE_SOCIAL_INFORMATION_FORM_UPDATE:
            return {
                ...state,
                supplierSocialInfomration: {
                    [action.field]: action.value,
                    formUpdated: true
                }
            }
        case actions.SUPPLIER_PROFILE_EVENT_INFORMATION_FORM_UPDATE:
            return {
                ...state,
                supplierEventInformation: {
                    [action.field]: action.value,
                    formUpdated: true
                }
            }
        case actions.SUPPLIER_PROFILE_SEERVICE_INFORMATION_FORM_UPDATE:
            return {
                ...state,
                supplierServiceInformation: {
                    [action.field]: action.value,
                    formUpdated: true
                }
            }
        case actions.SUPPLIER_PROFILE_FORM_GENERIC_UPDATE:
            return {
                ...state,
                [action.field]: action.value
            }
        case actions.SUPPLIER_REVIEW_FORM_UPDATED:
            return {
                ...state,
                [action.field]: action.value
            }
        case actions.SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.HANDLE_SUPPLIER_PROFILE_PIC_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_ADD_EVENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_SUPPLIER_ADD_EVENT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_ADD_EVENT_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUPPLIER_UPDATE_EVENT_REQUEST:
            return {
                ...state,
                loadiing: true
            }
        case actions.SEND_SUPPLIER_UPDATE_EVENT_REQUEST_SUCCESS:
            return {
                ...state,
                loadiing: false
            }
        case actions.SEND_SUPPLIER_UPDATE_EVENT_REQUEST_FAILURE:
            return {
                ...state,
                loadiing: false
            }
        case actions.SUPPLIER_REVIEW_ADD_REQUEST:
            return {
                ...state,
                loadiing: true
            }
        case actions.SUPPLIER_REVIEW_ADD_REQUEST_SUCCESS:
            return {
                ...state,
                loadiing: false
            }
        case actions.SUPPLIER_REVIEW_ADD_REQUEST_FAILURE:
            return {
                ...state,
                loadiing: false
            }
        case actions.SUPPLIER_LIST_REVIEW_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SUPPLIER_LIST_REVIEW_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SUPPLIER_LIST_REVIEW_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.REMOVE_CURRENT_EVENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.REMOVE_CURRENT_EVENT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.REMOVE_CURRENT_EVENT_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.REMOVE_SELECTED_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.REMOVE_SELECTED_IMAGE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.REMOVE_SELECTED_IMAGE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.REMOVE_SELECTED_SUB_SERVICE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.REMOVE_SELECTED_SUB_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.REMOVE_SELECTED_SUB_SERVICE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.REMOVE_ADDIONAL_SERVICE_AREA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_CONTACT_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_CONTACT_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_CONTACT_MESSAGE_FAILURE:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}