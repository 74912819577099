import React, { Component } from 'react'

export class UserTypePrimary extends Component {

    onUserTypeSelect = (input, section) => this.props.newFilteredUserType(input, section)

    gotoSecondary = () => this.props.gotoSecondary()

    render () {

        const { userTypes, individual, company } = this.props

        return (
            <div className="login-modal-user-types user-type">
                <div className="user-type__section">
                    <header className="landing-page__middle-header">
                    <h4 className="login-modal__title landing-page__middle-title">JOIN THE BUZZ</h4>
                        <p className="landing-page__middle-subtext">What would you like to do?</p>
                    </header>
                    <fieldset className="fieldset fieldset--inverse">
                        <legend className="legend legend--user-type">
                            <button 
                                className="btn new-login__legend"
                                onClick={() => this.gotoSecondary()}
                                disabled={individual === true || company === true ? false : true} 
                            >JOIN THE BUZZ</button>
                            <span className="legend-light">It's free</span>
                        </legend>
                        <div className="landing-page__user-type-container eb-forms">
                        {userTypes.individual.map(userType => (
                            <div key={userType.index}
                                className="landing-page__user-type eb-radio">
                                <input
                                    type='radio'
                                    name="userType"
                                    id={userType.index}
                                    onChange={() => this.onUserTypeSelect(userType.index, 'individual')}
                                />
                                <span className="checkmark"></span>
                                <label htmlFor={userType.index}>{userType.text}</label>
                            </div>
                        ))}
                        {userTypes.company.map(userType => (
                            <div 
                                key={userType.index}
                                className={userType.index === 3 ? "landing-page__user-type eb-radio eb-radio--disabled" : "landing-page__user-type eb-radio"}
                            >
                                <input
                                    type='radio'
                                    name="userType"
                                    id={userType.index}                                    
                                    disabled={userType.index === 3}
                                    onChange={() => this.onUserTypeSelect(userType.index, 'company')}
                                />
                                <span className="checkmark"></span>
                                <label htmlFor={userType.index}>{userType.text}</label>
                            </div>
                        ))}
                        </div>
                    </fieldset>
                </div>
                {/*<div className="user-type__section">*/}
                {/*    <header className="landing-page__middle-header">*/}
                {/*        <p className="landing-page__middle-subtext">Match to Jobs</p>*/}
                {/*    </header>*/}
                {/*    <fieldset className="fieldset fieldset--inverse">*/}
                {/*        <legend className="legend legend--user-type">*/}
                {/*            <button className="btn new-login__legend" */}
                {/*                    onClick={() => this.nextStep()}*/}
                {/*                    disabled={jobs ? false : true} >JOIN THE BUZZ</button>*/}
                {/*            <span className="legend-light">It's free</span>*/}
                {/*        </legend>*/}
                {/*        <div className="landing-page__user-type-container eb-forms">*/}
                {/*        {*/}
                {/*            userTypes.jobs.map(userType => (*/}
                {/*                <div key={userType.index} className="landing-page__user-type eb-radio">*/}
                {/*                    <input */}
                {/*                        type='radio'*/}
                {/*                        name="userType" */}
                {/*                        id={userType.index}  */}
                {/*                        onChange={() => this.onUserTypeSelect(userType.index, 'jobs')} */}
                {/*                    />*/}
                {/*                    <span className="checkmark"></span>*/}
                {/*                    <label htmlFor={userType.index}>{userType.text}</label>*/}
                {/*                </div>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*        </div>*/}
                {/*    </fieldset>*/}
                {/*</div>*/}
            </div>
        )
    }
}
