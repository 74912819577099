import React, { Component } from 'react'
import { connect } from 'react-redux'

import { openModal, closeModal } from '../../../actions/newLoginActions'

import LoginModalWizard from './LoginModalWizard'
import closeIcon from '../../../assets/images/Close.svg'
import './styles/loginModal.css'
import './styles/activation.css'
import './styles//SignupScreen.css'
import './styles/login.css'
import './styles/LandingScreen.css'

class LoginModal extends Component {

    componentDidMount() {
        this.timer()
        // this.tempShowLoginModal()
    }

    componentWillUnmount() {
        clearInterval(this.state.userCheckIntervalId)
    }

    timer = () => {
        const userCheckIntervalId = setInterval(this.tempShowLoginModal, 900000)
        this.setState({ userCheckIntervalId: userCheckIntervalId }) 
    }

    // showLoginModal = _ => {
    //     const { isLoggedIn, openModal } = this.props

    //     if (isLoggedIn) {   
    //         // if user logged in, clear timer  
    //         clearInterval(this.state.userCheckIntervalId)
    //     } else {
    //         // if user not logged in, show modal box after 2s
    //         setTimeout(() => {
    //             clearInterval(this.state.userCheckIntervalId)
    //             openModal()
    //             document.querySelector('body').classList.add('modal-open')
    //         }, 2000);
    //     }
    // }

    tempShowLoginModal = () => {
        const { isLoggedIn, openModal } = this.props
        if (isLoggedIn) {
            clearInterval(this.state.userCheckIntervalId)
        } else {
            setTimeout(() => {
                clearInterval(this.state.userCheckIntervalId)
                openModal()
                document.querySelector('body').classList.add('modal-open')
            }, 900000)
        }
    }

    closeLoginModal = () => {
        this.props.closeModal()
        document.querySelector('body').classList.remove('modal-open')
        // this.timer()
    }

    render () {
        const { modalOpen, count, loading } = this.props
        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>
                <div className={modalOpen ? "login-modal login-modal--open" : "login-modal"}>
                    <div className="login-modal__container">
                        <header className="login-modal__header">
                            {/* <h4 className="login-modal__title landing-page__middle-title">JOIN THE BUZZ</h4> */}
                            <button 
                                disabled={count > 3 ? true : false}
                                className="login-modal__close-btn btn" 
                                onClick={() => this.closeLoginModal()}
                            >
                                <img className="login-modal__close-icon" src={closeIcon} alt="close login popup" />
                            </button>
                        </header>
                        <div className="login-modal__content-container">
                            <LoginModalWizard />
                        </div>
                    </div>
                </div>
            </>
        )
    }  
}

const mapStateToProps = ({ newLogin, session }) => ({
    count: newLogin.count,
    loading: session.loading,
    modalOpen: newLogin.modalOpen,
    isLoggedIn: session.isLoggedIn,
}) 

const mapDispatchToProps = dispatch => ({
    openModal: () => dispatch(openModal()),
    closeModal: () => dispatch(closeModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)