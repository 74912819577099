import React, { Component } from 'react'

import './styles/loginModal.css'

export default class UserApprovalNotification extends Component {

    render () {
        
        return (
            <>
                <h5 className="login__title">Account Activation</h5>
                <div className="login__form-container">
                    <p>We will review your application and get back to you within 12 hours.</p>
                </div>
            </>
        )
    }  
}