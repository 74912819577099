import React, { Component } from 'react'

import ComingSoon from '../../reusables/ComingSoon/ComingSoon'
import Header from '../Global/Header'
export default class ButtonsAndBadges extends Component {

    render() {

        return (
            <>
                <Header />   
                <div className="main">
                    <ComingSoon />
                </div>
            </>
        )
    }
}