import React, { Component } from 'react'
import { connect } from "react-redux"
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import { checkLoginStatus } from '../../actions/sessionActions'
import { supplierProfileFormGenericUpdate, processSupplierProfileChangePassword } from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

import Header from '../Global/Header'

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: 0,
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    }
})
class Password extends Component {

    state = { 
        oldPassword: "",
        newPassword: "",
        showPassword: false,
        confirmPassword: "",
        errorFields: {
            oldPassword: false,
            newPassword: false,
            confirmPassword: false,
        },
        touched: {
            oldPassword: false,
            newPassword: false,
            confirmPassword: false,
        },
        userId: null,
        errorsInTheForm: true
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => data)
        setTimeout(() => { this.setState({ overlay: false }) }, 100)   
    }

    updateFormHandler = (field, event) => { 
        this.props.supplierProfileFormGenericUpdate(field, event.target.value)    
        this.setState({ [field]: event.target.value })            
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }, errorsInTheForm: (!event.target.value) ? true : false })

    handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword })
    
    handleMouseDownPassword = event => event.preventDefault()

    formSubmitHandler = e => {
        e.preventDefault()
        const { 
            oldPassword, 
            newPassword, 
            confirmPassword 
        } = this.state
        if ((oldPassword && newPassword && confirmPassword) && (newPassword == confirmPassword)) {            
            const postData = {
                oldPassword: oldPassword,
                newPassword: newPassword
            }        
            this.props.processSupplierProfileChangePassword(postData).then(data => {
                if (data.status === 'supplier_password_updated') {
                    this.props.showNotification('Your password have been updated!.', 'success')
                    this.setState({ 
                        oldPassword: '', 
                        newPassword: '', 
                        touched: {
                            oldPassword: false,
                            newPassword: false,
                            confirmPassword: false,
                        }, errorFields: {
                            oldPassword: false,
                            newPassword: false,
                            confirmPassword: false,
                        } 
                    })
                    setTimeout(() => this.props.hideNotification(), 5000)                                    
                } else if (data.status === 'supplier_old_new_equal') {
                    this.props.showNotification('Your new password and old passwords are same!.', 'error')
                    setTimeout(() => this.props.hideNotification(), 5000)                
                } else if (data.status === 'supplier_oldpassword_wrong') {
                    this.props.showNotification('Your old password is wrong!.', 'error')
                    setTimeout(() => this.props.hideNotification(), 5000)                
                } 
            })
        }
    } 

    render() {

        const {
            touched,
            oldPassword,
            newPassword,
            errorFields,
            showPassword,
            confirmPassword
        } = this.state
        const { classes } = this.props

        return (
            <>
                <Header />
                <div className="profile__change-password">
                    <div className="profile__section">
                        <div className="profile__section-content width__Half">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile eb-forms--profile-password">
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--fullwidth">
                                            <FormControl className={clsx(classes.margin)} variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-password">{'Old Password'}</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    value={oldPassword}
                                                    id="outlined-adornment-password"
                                                    inputProps={{style: {fontSize: 15}}}
                                                    onBlur={this.handleBlur('oldPassword')}
                                                    InputLabelProps={{style: {fontSize: 15}}}   
                                                    type={showPassword ? 'text' : 'password'}                                                                                        
                                                    onChange={(event, newValue) => this.updateFormHandler('oldPassword', event)}      
                                                    error={((touched.oldPassword && !oldPassword) || (errorFields.oldPassword))}    
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                            onMouseDown={this.handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    labelWidth={80}
                                                />
                                            </FormControl> 
                                            {((touched.oldPassword && !oldPassword) || (errorFields.oldPassword)) && <span className="error">Please Enter a valid Password</span>}
                                        </div>                                    
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--fullwidth">
                                            <FormControl className={clsx(classes.margin)} variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-password">{'Password'}</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    value={newPassword}
                                                    id="outlined-adornment-password"
                                                    inputProps={{style: {fontSize: 15}}}
                                                    onBlur={this.handleBlur('password')}
                                                    InputLabelProps={{style: {fontSize: 15}}}   
                                                    type={showPassword ? 'text' : 'password'}                                                                                        
                                                    onChange={(event, newValue) => this.updateFormHandler('newPassword', event)}      
                                                    error={((touched.newPassword && !newPassword) || (errorFields.newPassword))}    
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                            onMouseDown={this.handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    labelWidth={60}
                                                />
                                            </FormControl>                            
                                            {((touched.newPassword && !newPassword) || (errorFields.newPassword)) && <span className="error">Please Enter a valid Password</span>}
                                            <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>
                                        </div>                                                                                                                     
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--fullwidth">
                                            <FormControl className={clsx(classes.margin)} variant="outlined" fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-password">{'Confirm Password'}</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    value={confirmPassword}
                                                    id="outlined-adornment-password"
                                                    inputProps={{style: {fontSize: 15}}}
                                                    onBlur={this.handleBlur('confirmPassword')}
                                                    InputLabelProps={{style: {fontSize: 15}}}   
                                                    type={showPassword ? 'text' : 'password'}                                                                                        
                                                    onChange={(event, newValue) => this.updateFormHandler('confirmPassword', event)}      
                                                    error={((touched.confirmPassword && !confirmPassword) || (errorFields.confirmPassword))}    
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                            onMouseDown={this.handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    labelWidth={110}
                                                />
                                            </FormControl>                            
                                            {((touched.newPassword && !newPassword) || (errorFields.newPassword)) && <span className="error">Please Enter a valid Password</span>}
                                            <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>                                            
                                        </div>                                                                                                                     
                                    </div>
                                </form>
                            </div>
                            <div className="profile__tips-section"></div>
                        </div>
                        <div className="profile__button-container width__Half">
                            <button className="btn btn-gold profile__button profile__button-next" disabled={!oldPassword || !newPassword || (newPassword != confirmPassword)} onClick={this.formSubmitHandler}>Change Password</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,        
    isLoggedIn: session.isLoggedIn,
    oldPassword: profile.oldPassword,
    newPassword: profile.newPassword,
    confirmPassword: profile.confirmPassword
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),    
    hideNotification: () => dispatch(hideNotification()),    
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),    
    processSupplierProfileChangePassword: postData => dispatch(processSupplierProfileChangePassword(postData)),
    supplierProfileFormGenericUpdate: (field, value) => dispatch(supplierProfileFormGenericUpdate(field, value))    
})
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Password))