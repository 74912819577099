import React, { Component } from 'react'

import Header from '../Global/Header'

import './styles/static.css'
export default class PrivacyPolicy extends Component  {

    render () {
        return (
            <>
                <Header />
                <section className="static-page">
                    <div className="static-page__static-container">
                        <header className="static-page__header">
                            <h3 className="static-page__title">EVENTBUZZ360 - PRIVACY POLICY </h3>
                        </header>
                        <div className="static-page__content">
                            <p>Our Cookie Policy explains how EventBuzz360 Pty Ltd, collectively "we", "us", "our," or "EventBuzz360," and our corporate affiliates use cookies and similar technologies to recognise you when you visit our sites on which this Cookie Policy is posted and that are EventBuzz360-branded or operated by EventBuzz360. It explains what these technologies are and why we use them, as well as your rights to control our use of them. </p>                        
                            <h4 className="static-page__sub-title">What is a Cookie? </h4>
                            <p>A cookie is a small data file that is placed on your device when you visit a website. Cookies are widely used in order to make websites work or to work more efficiently, as well as to provide reporting information. A cookie may have unique identifiers and reside, among other places, on your device, in emails we send to you, and on the Platform. </p>
                            <p>Cookies set by EventBuzz360 are called "first-party cookies". Cookies set by parties other than EventBuzz360 are called "third-party cookies." Third-party cookies enable features or functionality provided by third parties in connection with the Platform, for example, advertising, interactive content, and analytics. The parties that set these third-party cookies can recognise your device both when it visits the website in question and also when it visits certain other websites. </p>
                            <p>We may use other technologies similar to cookies like web beacons, which are sometimes called "tracking pixels" or "clear gifs". These are tiny graphics files that contain a unique identifier that enable us to recognise when someone has visited our Platform or opened an email that we have sent them. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning. </p>
                            <p>Our Platform may also use "Flash Cookies" (also known as Local Shared Objects or "LSOs"), which are different from browser cookies because of the amount and type of data stored. </p>
                            <h4 className="static-page__sub-title">Why do we use Cookies? </h4>                        
                            <p>We use first-party and third-party cookies for several reasons. Some are required for technical reasons in order for our Platform to operate. Others provide a better experience on our Platform by remembering some of your activities on the Platform (including through our plug-ins, widgets, and embedded content). Other cookies enable more relevant advertising and better analytics. </p>
                            <p><strong>Essential Cookies:</strong> Some cookies are required for the Platform to operate, for example, in the following ways. To: </p>
                            <ol>
                                <li>identify you as being logged into the Platform; and</li>
                                <li>make sure you connect to the right service on our Platform when we make any changes to the way the Platform works.                                 
                                </li>
                            </ol>
                            <p><strong>Cookies for Features and Services: </strong>Some cookies provide a better experience on our Platform. For example, without cookies to remember some of your settings and activities on our Platform, the following functionality may become unavailable: </p>
                            <ol>
                                <li>adding purchases to your cart and remembering the products you have added;</li>
                                <li>remembering settings you have applied, such as layout, text size, preferences, and colours; </li>
                                <li>remembering if we already have asked you to complete a survey or if you already have engaged with particular content on the Platform to avoid showing these items to you again; and </li>
                                <li>remembering when you have added a product to your EventBook. </li>
                            </ol>
                            <p><strong>Cookies for Analytics and Personalisation: </strong>Some cookies collect information about how you use the Platform to help us improve how our Platform is being used, and to help us understand what may interest you and personalise your experience accordingly. These cookies, for example: </p>
                            <ol>
                                <li>generate statistics on how the Platform is used, including to measure any errors that occur or to obtain data on the number of users of the Platform that have viewed a product or added it to their Ideabook; </li>
                                <li>test different designs for the Platform; </li>
                                <li>help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded; and </li>
                                <li>collect or transmit information about you, such as your browser type and search preferences, and your use of the Platform or third-party sites that incorporate part of the Platform. </li>
                            </ol>
                            <p><strong>Advertising Cookies: </strong>We and third parties use cookies to make advertising more relevant to you. They perform functions like measuring the performance of ads, preventing the same ad from continuously reappearing, ensuring that ads are properly displayed, and in some cases selecting ads that are based on your visits to our Platform and other sites. These cookies, for example: </p>
                            <ol>
                                <li>provide feedback to advertising partners that one of their visitors also visited the platform; </li>
                                <li>provide our advertisers with data relating to ads that have been displayed to you or that you have clicked on, and the date and time of your use; </li>
                                <li>to show you relevant advertising off of the platform; and </li>
                                <li>to understand what actions you take on the Platform after receiving our advertising. </li>
                            </ol>
                            <p><strong>Third-Party Cookies: </strong>We work with analytics service providers, advertising partners, and advertising networks that may have access to your device information but does not enable them to collect your name, contact details or other personal information unless you choose to provide this information. Learn more about who serves cookies from our Platform. </p>
                            <h4 className="static-page__sub-title">How can I control cookies? </h4>
                            <p>You can amend or "opt out" of the collection of any information through cookies or other tracking technology by actively managing the settings on your browser or mobile device. Please note that turning off or deleting cookies will not prevent device identification and related data collection from occurring. If you choose to reject cookies, your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's or mobile device's technical information for instructions on how to delete and disable cookies, and other tracking/recording tools. </p>
                            <p>In addition you can also "opt-out" of the collection of any information through cookies or other tracking technology of businesses. Please visit these organisations' opt-out pages to learn about how you may opt out of receiving web-based personalised ads from participating businesses. You can access any settings offered by your mobile operating system to limit ad tracking or install the AppChoices app to learn more about how you may opt out of receiving personalised ads in mobile apps. </p>
                            <p>If you do not want Flash Cookies stored on your device, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the Website Storage Settings Panel. You can also control Flash Cookies by going to the Global Storage Settings Panel and following the instructions (which may include instructions that explain, for example, how to delete existing Flash Cookies (referred to "information" on the Macromedia site), how to prevent Flash LSOs from being placed on your device without you being asked, and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the page you are on at the time). Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our services or online content. </p>
                            <h4 className="static-page__sub-title">How often will you update this Cookie Policy? </h4>
                            <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies. </p>
                            <h4 className="static-page__sub-title">More information about cookies </h4>
                            <p>If you have any questions about our use of cookies or other technologies, please email us at <a target="_blank" href="mailto:hello@eventbuzz360.com">hello@eventbuzz360.com</a></p>                        
                            <p>Useful information about cookies can be found at:  </p>
                            <p><a target="_blank" href="https://www.australia.gov.au/privacy">https://www.australia.gov.au/privacy</a></p>
                            <p><a target="_blank" href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a></p>
                            <p>Information on the ICC (UK) UK cookie guide can be found on the ICC website section: <a target="_blank" href="http://www.international-chamber.co.uk/our-expertise/digitaleconomy">http://www.international-chamber.co.uk/our-expertise/digitaleconomy</a></p>
                            <p>A guide to behavioural advertising and online privacy has been produced by the internet advertising industry which can be found at: <a target="_blank" href="http://www.youronlinechoices.eu">http://www.youronlinechoices.eu</a></p>
                            <h4 className="static-page__sub-title">More information about analytics </h4>
                            <p>Our Website uses Google Analytics, a web analysis service from Google Inc., Google Analytics uses cookies - text files that are stored on your device and make it possible to analyse how you utilise the site. The information generated by the cookie (including the IP address) is transferred and stored on a Google server located in the United States. </p>
                            <p>Google uses the information on our behalf to evaluate how the site is used, create reports about the activities on the site for the site operators, and to perform additional services regarding website and internet utilisation. </p>
                            <p>Further you can prevent the collection and processing of cookie created data relating to your utilisation of the website (including your IP) via Google by downloading and installing the browser-plugin available under the following link (<a target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>). </p>                        
                            <p>You can refuse the use of Google Analytics by clicking on the following link. An opt-out cookie will be set on the device, which prevents the future collection of your data when visiting this website: </p>
                            <a target="_blank" href="https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out">Disable Google Analytics </a>
                        </div>
                    </div>                
                </section>
            </>
        )
    }
}