import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" className="linear-progress">
        <Box minWidth={35}>
            <Typography variant="body2" className="linear-progress__percentage">{`${Math.round(props.value,)}%`}</Typography>
        </Box>
        <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" {...props} className="linear-progress_progress-bar" />
        </Box>
    </Box>
  );
}