import React, { Component } from 'react'
import { connect } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import { 
    openGalleryModal,
    closeGalleryModal,
    submitCommentForEvent,
    supplierEventCommentmUpdate,
    retrievePaginatedImagesOnScorll,    
    addLikesForSpecificEventsInAction,
    checkAlreadyGivenALikeToThatEvent,
    loadAllEventInformationFromEventPhotoId,
} from '../../../actions/galleryActions'
import { showNotification, hideNotification } from "../../../actions/notificationActions"

import { GalleryModal } from './GalleryModal'
import { LazyLoad } from '../../../reusables/LazyLoad/LazyLoad'
import multiple from '../../../assets/images/icons/multiple.png'

import './styles/gallery.css'
import loader from  '../../../assets/images/lazy_loader.gif'

class GalleryImages extends Component {

    updateFormHandler = (field, value) => {         
        this.setState({ [field]: value })               
        this.props.supplierEventCommentmUpdate([field], value) 
    }

    submitCommentForEvent = () => {
        const {
            isLoggedIn, 
            showNotification,
            hideNotification,            
            galleryModalData,
            submitCommentForEvent,
        } = this.props
        const eventId = galleryModalData.event_info.id
        if (!isLoggedIn) {
            showNotification('Please login to add comments...', 'info')
            setTimeout(() => hideNotification(), 6000)                    
        } else {
            submitCommentForEvent(eventId).then(data => {
                if (data === "supplier_comment_success") {
                    this.setState({ event_comment: "" })
                    this.props.loadAllEventInformationFromEventPhotoId(this.props.currentImageObject[0].image_id)                         
                    showNotification('Thanks for your comment!.', 'success')   
                    setTimeout(() => hideNotification(), 6000)                      
                }
            })
        }                
    }

    addLikesForEvent = () => {
        const { 
            isLoggedIn, 
            showNotification,
            hideNotification,
            galleryModalData,
            checkAlreadyGivenALikeToThatEvent,
            addLikesForSpecificEventsInAction
        } = this.props
        const eventId = galleryModalData.event_info.id
        if (!isLoggedIn) {
            showNotification('Please login to like the event...', 'info')
            setTimeout(() => hideNotification(), 6000)                    
        } else {
            checkAlreadyGivenALikeToThatEvent(eventId).then(data => {
                if (data && data.liked === '1') {
                    showNotification('You already given a like!!!', 'info')
                    setTimeout(() => hideNotification(), 6000)        
                } else {
                    addLikesForSpecificEventsInAction(eventId)  
                    setTimeout(() => {
                        this.props.loadAllEventInformationFromEventPhotoId(this.props.currentImageObject[0].image_id)                                               
                    }, 500)                        
                }
            })
        }
    }

    openGalleryModal = image_id => {
        this.props.loadAllEventInformationFromEventPhotoId(image_id)
        this.props.openGalleryModal()
        document.querySelector('body').classList.add('modal-open')
    }

    close = () => {
        this.props.closeGalleryModal()
        document.querySelector('body').classList.remove('modal-open')
    }

    fetchMoreData = () => this.props.retrievePaginatedImagesOnScorll(this.props.currentEventTypeId)

    render() {

        const {
            allImages,
            pagesCount,
            currentPage,
            modalLoading,
            galleryModalData,
            galleryModalOpen            
        } = this.props

        return (
            <div className="gallery">                
                <InfiniteScroll
                    hasMore={true}
                    dataLength={allImages.length}
                    className="gallery__inifinite-scroll"
                    next={currentPage <= pagesCount ? this.fetchMoreData : null}
                >
                    {(allImages && allImages.length > 0) &&
                    allImages.map((eachImage, key) =>
                        <div
                            key={key}
                            className="gallery__image"
                            onClick={() => this.openGalleryModal(eachImage.imageId)}>
                            <LazyLoad src={eachImage.imageLink} style="gallery__image-item" alter="gallery" />
                            {eachImage.imageCount > 1 && (
                                <div className="gallery__image--multiple">
                                    <img src={multiple} alt="folder" />
                                </div>
                            )}
                            <div className="gallery__image-hover-overlay">
                                <span className="gallery__overlay-icons">
                                    <i className="fa fa-heart" aria-hidden="true"></i>{(galleryModalData && galleryModalData.requested_image == eachImage.imageId) ? ((galleryModalData && galleryModalData.event_liking_info) && galleryModalData.event_liking_info.likeCount) : eachImage.likesResult.liked_count}
                                </span>
                                <span className="gallery__overlay-icons">
                                    <i className="fa fa-comment" aria-hidden="true"></i>{(galleryModalData && galleryModalData.requested_image == eachImage.imageId) ? ((galleryModalData && galleryModalData.event_comments) && galleryModalData.event_comments.length) : eachImage.event_comments_count.comments_count}
                                </span>
                            </div>
                        </div>
                        )
                    }
                </InfiniteScroll>
                {
                    currentPage < pagesCount && (
                        <div className="load-more-images">
                            <img className="load-more-icon" src={loader} alt="loader" />
                        </div>
                    )
                }
                <GalleryModal 
                    close={this.close}
                    modalLoading={modalLoading}
                    galleryModalData={galleryModalData}
                    galleryModalOpen={galleryModalOpen}
                    addLikesForEvent={this.addLikesForEvent}
                    updateFormHandler={this.updateFormHandler}                    
                    submitCommentForEvent={this.submitCommentForEvent}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ gallery, session }) => ({
    allImages: gallery.allImages,
    pagesCount: gallery.pagesCount,
    isLoggedIn: session.isLoggedIn,
    currentPage: gallery.currentPage,
    modalLoading: gallery.modalLoading,
    galleryModalOpen: gallery.galleryModalOpen,
    galleryModalData: gallery.galleryModalData,
    currentImageObject: gallery.currentImageObject     
})

const mapDispatchToProps = dispatch => ({
    openGalleryModal: () => dispatch(openGalleryModal()),
    hideNotification: () => dispatch(hideNotification()),
    closeGalleryModal: () => dispatch(closeGalleryModal()),
    submitCommentForEvent: eventId => dispatch(submitCommentForEvent(eventId)),
    supplierEventCommentmUpdate: (field, value) => dispatch(supplierEventCommentmUpdate(field, value)),
    checkAlreadyGivenALikeToThatEvent: eventId => dispatch(checkAlreadyGivenALikeToThatEvent(eventId)),  
    addLikesForSpecificEventsInAction: eventId => dispatch(addLikesForSpecificEventsInAction(eventId)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),    
    retrievePaginatedImagesOnScorll: eventTypeId => dispatch(retrievePaginatedImagesOnScorll(eventTypeId)),
    loadAllEventInformationFromEventPhotoId: imageId => dispatch(loadAllEventInformationFromEventPhotoId(imageId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GalleryImages)