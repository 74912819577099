import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../actions/sessionActions'
import { loadAllSupplierReviews, fetchAllReviewsForSupplierProfileInAction } from '../../actions/profileActions'
import { StarRating } from '../Global/StarRating'
import { profilePhotosImagesUploadingPath } from '../../utils/config'

import Header from '../Global/Header'

import { refactorTheProfileOrCoverImage } from '../../utils/misc'

class ProfessionalReviewsListing extends Component {

    state = { 
        allReviews: [],
        loggedInRealStatus: true,
        starExplanations: [
            "Would not recommend this pro",
            "Just ok, could have been better",
            "Pretty good overall",
            "Great job, recommend",
            "Excellent job, highly recommend"
        ]
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                this.props.fetchAllReviewsForSupplierProfile().then(allReviews => this.setState({ allReviews }))
            }            
        })        
    }

    render() {

        const { allReviews } = this.state 
        
        return (
            <>
                <Header />
                <div className="supplier-profile__reviews">
                    <div className="profile__section">
                        <div className="supplier-profile__reviews-section">
                            {allReviews && allReviews.map(review => (
                                <div key={review.id} className="supplier-profile__review">
                                    <div className="supplier-profile__review-header">
                                        <div className="supplier-profile__review-company-logo">
                                            <img src={refactorTheProfileOrCoverImage(review.profile_photo, profilePhotosImagesUploadingPath)} alt="company logo" />
                                        </div>
                                        <div className="supplier-profile__company-details">
                                            <h5 className="supplier-profile__review-name">{review.first_name + ' ' + review.last_name}</h5>
                                            <p className="supplier-profile__review-company-name">{review.reviewer_job_title} - {review.reviewer_company}</p>                               
                                        </div>                                        
                                    </div>
                                    <div className="supplier-header__star-container">
                                        {/* {review.pro && <span className="supplier-header__pro">PRO</span>} */}
                                        <StarRating stars={review.rating}></StarRating>   
                                    </div> 
                                    <label>
                                        <em>
                                            {review.created_at}
                                        </em>
                                    </label>
                                    <blockquote className="supplier-profile__review-comment">{review.review_comment}</blockquote>
                                </div>))
                            }
                        </div>                    
                    </div>                
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,        
    isLoggedIn: session.isLoggedIn
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),    
    loadAllSupplierReviews: () => dispatch(loadAllSupplierReviews()),
    fetchAllReviewsForSupplierProfile: () => dispatch(fetchAllReviewsForSupplierProfileInAction()),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalReviewsListing)