import React, { Component } from 'react'
import Rodal from 'rodal'
import { connect } from "react-redux"
import { TextField } from '@material-ui/core'

import { supplierContactFormUpdate } from '../../../actions/generalActions'

import '../../../screens/ProfileScreen/EditProfileScreen.css'
import './styles/find-pro-modal.css'

class FindProContactModal extends Component {

    render () {

        const {
            touched,
            handleBlur,
            errorFields,
            contactEmail,
            onCloseHandler,
            errorsInTheForm,
            showContactModal,
            updateFormHandler,
            contactTheSupplier,
            supplierEmailMessage
        } = this.props 

        return (
            <Rodal className="rodal--contact_modal" visible={showContactModal} onClose={onCloseHandler} animation={'slideDown'}>
                <header className="rodal__header">
                    <h4 className="rodal__heading">Contact Supplier</h4>
                </header>                
                <p className="profile__rodal-text">Please fill the message you want to communicate with this supplier!</p>
                <div className="rodal__main-container">
                    <div className="profile__section-content">
                        <form className="eb-forms eb-forms--profile">
                            <div className="eb-forms__form-group">                                
                                <TextField                                                                                        
                                    rows={1}
                                    fullWidth
                                    multiline
                                    rowsMax={2}                                            
                                    margin="small"                                                                                            
                                    name="message"
                                    label={'Message'} 
                                    variant="outlined"                                                                                                                                                                                                                                                         
                                    placeholder="Message"
                                    inputProps={{style: {fontSize: 12}}}                                                                                        
                                    onBlur={handleBlur('supplierEmailMessage')}    
                                    value={(supplierEmailMessage ? supplierEmailMessage : '')}
                                    InputLabelProps={{style: {fontSize: 12}}, { required: true }}                                       
                                    onChange={(event, newValue) => updateFormHandler('supplierEmailMessage', event)}                                                    
                                    error={((touched.supplierEmailMessage && !supplierEmailMessage) || (errorFields.supplierEmailMessage))}                                                
                                />                                  
                                {((touched.supplierEmailMessage && !supplierEmailMessage) || (errorFields.supplierEmailMessage)) && <span className="error">Please Enter the message!</span>}
                            </div>
                        </form>                            
                    </div>
                </div>
                <div className="rodal__btn-container">
                    <input type="button" className="btn btn-gold image-crop-complete" value="Contact" disabled={!supplierEmailMessage || errorsInTheForm} onClick={() => contactTheSupplier(contactEmail, supplierEmailMessage)} />
                </div>
            </Rodal>
        )
    }
}

const mapStateToProps = ({ general }) => ({
    supplierEmailMessage: general.supplierEmailMessage
})
  
const mapDispatchToProps = dispatch => ({
    supplierContactFormUpdate: (field, value) => dispatch(supplierContactFormUpdate(field, value))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(FindProContactModal)