import i18next from 'i18next'

import common_en from "./translations/en/common.json"
import common_it from "./translations/it/common.json"

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',                            
    resources: {
        en: {
            common: common_en               
        },
        it: {
            common: common_it
        }
    },
})

export default i18next