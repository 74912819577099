import React, { Component } from 'react'
import { connect } from "react-redux"
import Rodal from 'rodal'
import arrayMove from "array-move"
import { DndProvider } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import TouchBackend from "react-dnd-touch-backend"
import cuid from "cuid"
import { TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { retrieveAllEventTypes } from '../../actions/galleryActions'
import { checkLoginStatus } from '../../actions/sessionActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"
import {
    processSupplierAddEvent,
    processSupplierPhotosUpload,
    supplierProfileEventInformationFormUpdate,
} from '../../actions/profileActions'

import ImageList from '../../reusables/ImageList/ImageList'
import Dropzone from '../../reusables/Dropzone/Dropzone'
import Header from '../Global/Header'

import { isTouchDevice } from '../../utils/misc'

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend

class Upload extends Component {

    constructor() {
        super()
        this.state = {
            eventTypeId: "",
            allSuppliers: [],
            imagesAll: false,
            otherCredits: "",
            eventLocation: "",
            allEventTypes: [],
            selectedImages: [],
            imagesProfile: true,
            connectedSuppliers: [],
            folderInsideImages: [],
            photographerCredits: "",
            selectedImagesPreviews: [],
            errorFields: {
                eventLocation: true,
                eventDescription: true
            },
            touched: {
                eventLocation: false,
                eventDescription: false
            },
            imagesAppearIn: "profile",
            projectBudgetTypes: [
                {
                    "key": "0-20",
                    "text": "under $20,000"
                },
                {
                    "key": "20-30",
                    "text": "$20,000-$30,000"
                },
                {
                    "key": "30-50",
                    "text": "$20,000-$50,000"
                },
                {
                    "key": "50-100",
                    "text": "over $50,000"
                }
            ],
            imagesCurrentCountUploaded: [],
            photosUploadingInProcess: false,
            modalBoxVisbileForAddedNewEvent: false
        }
        this.keyPress = this.keyPress.bind(this);
    }

    componentDidMount() {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                setTimeout(() => { this.setState({ overlay: false, selectedImages: [], photosUploadingInProcess: false, imagesCurrentCountUploaded: [] }) }, 100)
                this.props.retrieveAllEventTypes().then(allEventTypes => this.setState({ allEventTypes }))
            }
        })
    }

    componentWillUnmount() {
        this.setState({ selectedImages: [], photosUploadingInProcess: false, imagesCurrentCountUploaded: [] })
    }

    updateFormHandler = field => event => {
        if (field === 'eventDescription') {
            const eventDescription = event.target.value
            this.setState({ [field]: eventDescription.replace(/^\s+/, '') })
        } else {
            // if ((event) && (event.target) && (event.target.value)) {
                this.setState({ [field]: event.target.value })
            // }
        }
        this.props.supplierProfileEventInformationFormUpdate([field], event.target.value)
    }

    handleBlur = field => event => {
        this.setState({
            touched: {
                ...this.state.touched,
                [field]: true
            },
            errorFields: {
                ...this.state.errorFields,
                [field]: (!event.target.value) ? true : false
            },
        })
    }

    hideModalBoxForBasicProfile = () => this.setState({ modalBoxVisbileForAddedNewEvent: false })

    imagesAppearance = field => {
        if (field === 'all') {
            this.setState({ imagesAll: true, imagesProfile: true, imagesAppearIn: 'all' })
        } else {
            this.setState({ imagesAll: false, imagesProfile: true, imagesAppearIn: 'profile' })
        }
    }

    removeSelectedImages = index => {
        const { selectedImages } = this.state
        if (selectedImages && selectedImages.length > 0) {
            this.setState(prev => ({
                selectedImages: prev.selectedImages.filter(el => el.id !== index),
                selectedImagesPreviews: prev.selectedImagesPreviews.filter(el => el.id !== index),
            }))
        }
    }

    onDrop = acceptedFiles => {
        acceptedFiles.map((file, i) => {
            const fileSize = (file.size / (1024 * 1024))
            const reader = new FileReader()
            reader.readAsDataURL(file)
            if (fileSize <= 5) {
                reader.onload = e => {
                    const image = new Image
                    image.src = reader.result
                    image.onload = () => {
                        const imageObjectForSubmit = {
                            id: cuid(),
                            current_order: i + 1,
                            src: file,
                            width: image.width,
                            height: image.height,
                        }
                        const imageObjectPreview = {
                            id: cuid(),
                            current_order: i + 1,
                            src: reader.result
                        }
                        this.setState({
                            selectedImages: this.state.selectedImages.concat(imageObjectForSubmit),
                            selectedImagesPreviews: this.state.selectedImagesPreviews.concat(imageObjectPreview)
                        })
                    }
                }
            } else {
                this.props.showNotification('The selected image is not compatible with the file uploading size / dimentions!', 'error')
                setTimeout(() => this.props.hideNotification(), 1500)
            }
        })
    }

    moveImage = (dragIndex, hoverIndex) => {
        const { selectedImages, selectedImagesPreviews } = this.state
        const updatedImagesSet = arrayMove(selectedImages, hoverIndex, dragIndex)
        const updatedImagesSetPreview = arrayMove(selectedImagesPreviews, hoverIndex, dragIndex)
        const modifiedImageSet = updatedImagesSet.map((eachImage, i) => {
            eachImage.current_order = i + 1
            return eachImage
        })
        const modifiedImageSetPreview = updatedImagesSetPreview.map((eachImage, i) => {
            eachImage.current_order = i + 1
            return eachImage
        })
        this.setState({ selectedImages: modifiedImageSet, selectedImagesPreviews: modifiedImageSetPreview })
    }

    addEvent = () => {
        let postData = {}
        let notToProceedTheForm = false
        console.log(this.state.errorFields);
        Object.values(this.state.errorFields).map(eachField => {
            console.log(eachField);
            if (eachField) notToProceedTheForm = true
        })
        console.log("notToProceedTheForm" + notToProceedTheForm);
        console.log("this.state.selectedImages" + this.state.selectedImages);
        if ((notToProceedTheForm === false) || (this.state.selectedImages > 0)) {
            const {
                eventTypeId,
                otherCredits,
                eventLocation,
                imagesAppearIn,
                selectedImages,
                eventDescription
            } = this.state
            if (imagesAppearIn === 'all') {
                postData = {
                    eventTypeId: eventTypeId,
                    otherCredits: otherCredits,
                    eventLocation: eventLocation,
                    imagesAppearIn: imagesAppearIn,
                    eventDescription: eventDescription,
                    imageCount: (selectedImages && selectedImages.length !== 0) ? selectedImages.length : 0
                }
            } else {
                postData = {
                    otherCredits: otherCredits,
                    eventLocation: eventLocation,
                    imagesAppearIn: imagesAppearIn,
                    eventDescription: eventDescription,
                    imageCount: (selectedImages && selectedImages.length !== 0) ? selectedImages.length : 0
                }
            }
            this.props.processSupplierAddEvent(postData, selectedImages).then(data => {
                if (data.status === 'supplier_event_information_added') {
                    this.props.showNotification('Your event information has been added! Please wait while until we upload the photos.', 'success')
                    this.setState({
                        eventTypeId: "",
                        otherCredits: "",
                        eventLocation: "",
                        imagesAppearIn: "",
                        photographerCredits: "",
                        errorFields: {
                            eventLocation: false,
                            eventDescription: false
                        },
                        touched: {
                            eventLocation: false,
                            eventDescription: false
                        },
                        photosUploadingInProcess: true,
                        modalBoxVisbileForAddedNewEvent: (imagesAppearIn === 'all' ? true : false)
                    })
                    setTimeout(() => this.props.hideNotification(), 6000)
                    if (selectedImages.length > 0) {
                        selectedImages.map((eachFile, i) => {
                            this.props.processSupplierPhotosUpload(eachFile.src, eachFile.current_order, eachFile.id, eachFile.width, eachFile.height, data.eventId, eventTypeId, data.currentTimeStamp).then(data => {
                                if (data.imageurl !== '') {
                                    this.setState({ imagesCurrentCountUploaded: this.state.imagesCurrentCountUploaded.concat(data.imageurl) })
                                }
                                if (selectedImages.length - 1 === i) {
                                    setTimeout(() => window.location.href = "/add-event", 4500)
                                }
                            })
                        })
                    }
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 6000)
                }
            }).catch(() => {
                this.props.showNotification('Something went wrong! Please contact support...', 'error')
                setTimeout(() => this.props.hideNotification(), 6000)
            })
        } else {
            console.log("All required fileds not completed.")
        }
    }

    keyPress(e) {
        if (e.keyCode === 13) {
            console.log('value', e.target.value);
            // put the login here
            this.addEvent();
            // console.log(this.state);
        }
    }

    render() {

        const { loading } = this.props

        const {
            touched,
            imagesAll,
            eventTypeId,
            errorFields,
            otherCredits,
            imagesProfile,
            allEventTypes,
            eventLocation,
            imagesAppearIn,
            selectedImages,
            eventDescription,
            selectedImagesPreviews,
            photosUploadingInProcess,
            imagesCurrentCountUploaded,
            modalBoxVisbileForAddedNewEvent
        } = this.state

        let notToProceedTheForm = false

        if (imagesAppearIn === 'all') {
            if ((eventLocation === '') || (eventTypeId === null) || (imagesAppearIn === '') || (eventDescription === '')) {
                notToProceedTheForm = true
            }
        } else {
            if ((eventLocation === '') || (imagesAppearIn === '') || (eventDescription === '')) {
                notToProceedTheForm = true
            }
        }
        return (
            <>
                <Header />
                {(!photosUploadingInProcess) &&
                    <div className={loading ? "overlay" : "overlay hide"}>
                        <div className="profile__loader-container">
                            <div className="loader"><span>Loading...</span></div>
                        </div>
                    </div>}
                {(photosUploadingInProcess) &&
                    <div className={(imagesCurrentCountUploaded.length !== selectedImages.length) ? "overlay" : "overlay hide"}>
                        <div className="profile__loader-container">
                            <div className="loader"><span>Please wait while we upload the photos...</span></div>
                        </div>
                    </div>}
                <div className="profile__add-events">
                    <div className="profile__section">
                        <h5 className="profile__sub-title">Upload photos of an event</h5>
                        <div className="profile__event-container">
                            <div className="profile__first-event-container">
                                <h6 className="profile__sub-title">An event is a collection of photos of your work. Events are one of the main ways event organisers can find you on Eventbuzz360</h6>
                                <span className="profile__section-content-tips">* Indicates a required field</span>
                            </div>
                        </div>
                        <div id="addEventSection" className="profile__section-content">
                            <div className="profile__form-container">
                                <div className="eb-forms eb-forms--profile">
                                    <div className="eb-forms__form-group">
                                        <div className="appear-in-container">
                                            <div className="appear-in-btn-container">
                                                <button
                                                    onClick={() => this.imagesAppearance('all')}
                                                    className={imagesAll ? "btn btn-appear-in active" : "btn btn-appear-in"}
                                                >Submit to Image Gallery</button>
                                                <button className={imagesProfile ? "btn btn-appear-in active" : "btn btn-appear-in"} onClick={() => this.imagesAppearance('profile')} value="profile">Submit to Profile Page Only</button>
                                            </div>
                                            {imagesAll && <div className="appear-in-help">
                                                <p>Images submitted to the Gallery will automatically be uploaded to your profile page. Please note that all submissions to the Gallery must first be approved by the team at EventBuzz360</p>
                                            </div>}
                                            <label className="fullwidth">Upload Event Images <span className="required__indicator">*</span></label>
                                        </div>
                                    </div>
                                    <div className="add__events-internal-wrapper">
                                        <span className="profile__section-content-tips">* Maximum file upload: 5MB (JPG / PNG)</span>
                                        <div className="eb-forms__form-group eb-forms__form-group--photo-upload">
                                            <div className="dropzoneContainer">
                                                <Dropzone onDrop={this.onDrop} accept={"image/*"} />
                                            </div>
                                        </div>
                                        {selectedImagesPreviews && selectedImagesPreviews.length > 0 && (
                                            <h6 className="text-center">Drag the Images to change positions</h6>
                                        )}
                                        <DndProvider backend={backendForDND}>
                                            <ImageList images={selectedImagesPreviews} moveImage={this.moveImage} removeImage={this.removeSelectedImages} />
                                        </DndProvider>
                                        {imagesAppearIn === 'all' &&
                                            <div className="eb-forms__form-group leftMarginEmpty">
                                                <div className="eb-forms--not-fullwidth">
                                                    <FormControl fullWidth variant="outlined">
                                                        <InputLabel id="eventType">{'Event Type'}</InputLabel>
                                                        <Select
                                                            value={eventTypeId}
                                                            labelId="eventType"
                                                            label={"Event Type"}
                                                            style={{ width: 300 }}
                                                            inputProps={{ style: { fontSize: 12 } }}
                                                            InputLabelProps={{ style: { fontSize: 12 } }}
                                                            onChange={this.updateFormHandler('eventTypeId')}
                                                        >
                                                            {allEventTypes.map(eventType => <MenuItem value={eventType.id}>{eventType.event_type_name}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        }
                                        <div className="eb-forms__form-group">
                                            <TextField
                                                rows={1}
                                                fullWidth
                                                multiline
                                                rowsMax={2}
                                                margin="small"
                                                variant="outlined"
                                                name="eventDescription"
                                                label="Event Description"
                                                inputProps={{ style: { fontSize: 12 } }}
                                                onBlur={this.handleBlur('eventDescription')}
                                                placeholder="Write a great event image description"
                                                onChange={this.updateFormHandler('eventDescription')}                                                
                                                error={((touched.eventDescription && !eventDescription))}
                                                value={eventDescription != 'null' ? eventDescription : ''}
                                                InputLabelProps={{ style: { fontSize: 12 } }, { required: true }}
                                            />
                                        </div>
                                        <div className="eb-forms__form-group">
                                            <div className="profile__input-container">
                                                <TextField
                                                    fullWidth
                                                    margin="small"
                                                    variant="outlined"
                                                    label="Event Location"
                                                    inputProps={{ style: { fontSize: 12 } }}
                                                    onBlur={this.handleBlur('eventLocation')}
                                                    onChange={this.updateFormHandler('eventLocation')}
                                                    error={((touched.eventLocation && !eventLocation))}
                                                    value={eventLocation != 'null' ? eventLocation : ''}
                                                    InputLabelProps={{ style: { fontSize: 12 } }, { required: true }}                                                    
                                                />
                                            </div>
                                        </div>
                                        <div className="eb-forms__form-group">
                                            <TextField
                                                fullWidth
                                                margin="small"
                                                variant="outlined"
                                                label="Other Supplier Credits"
                                                inputProps={{ style: { fontSize: 12 } }}
                                                placeholder={'Other Supplier Credits'}
                                                onBlur={this.handleBlur('otherCredits')}
                                                InputLabelProps={{ style: { fontSize: 12 } }}
                                                onChange={this.updateFormHandler('otherCredits')}
                                                value={otherCredits != 'null' ? otherCredits : ''}
                                                onKeyDown={this.keyPress}
                                            />
                                        </div>
                                        <div className="profile__button-container profile__button-container--single">
                                            <button className="btn btn-gold profile__button profile__button-next" disabled={notToProceedTheForm || selectedImages.length < 1} onClick={this.addEvent}>Upload Images</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile__tips-section"></div>
                        </div>
                    </div>
                </div>
                <Rodal className="rodal--profile-pic rodal__modal-box-for-add-event" visible={modalBoxVisbileForAddedNewEvent} onClose={this.hideModalBoxForBasicProfile.bind(this)} animation={'slideDown'}>
                    <header className="rodal__header">
                        <h4 className="rodal__heading">GREAT JOB!</h4>
                    </header>
                    <p className="profile__rodal-text">Your photos will now be <span className="normal-color__text__bold">{'reviewed'}</span> for <span className="normal-color__text__bold">{'approval'}</span> to appear in the gallery!</p>
                    <p className="profile__rodal-text">We will try to do this as quickly as possible for you!.</p>
                    <header className="rodal__header alternative__header-container">
                        <h4 className="rodal__heading">Go on... Add some more now!!!</h4>
                    </header>
                    <p className="profile__rodal-text">The more photos you add the more exposure you get!.</p>
                </Rodal>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierEventInformation.formUpdated
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    hideNotification: () => dispatch(hideNotification()),
    retrieveAllEventTypes: () => dispatch(retrieveAllEventTypes()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    processSupplierAddEvent: (postObject, imageSet) => dispatch(processSupplierAddEvent(postObject, imageSet)),
    supplierProfileEventInformationFormUpdate: (field, value) => dispatch(supplierProfileEventInformationFormUpdate(field, value)),
    processSupplierPhotosUpload: (image, order, id, width, height, eventId, eventName, eventTypeId, currentTimeStamp) => dispatch(processSupplierPhotosUpload(image, order, id, width, height, eventId, eventName, eventTypeId, currentTimeStamp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Upload)
