import 'date-fns';
import React, { Component } from 'react';
import { connect } from "react-redux"
import Rodal from 'rodal';
import { TextField, withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import './styles/supplier-contact.css';

import { Formik } from "formik";
import { ContactSupplierValidationSchema } from "./ContactSupplierValidationSchema";

import profile_image from '../../../assets/images/Person-Icon.png';

import { sendContactMessage, loadSupplierCategories } from '../../../actions/profileActions';

import { showNotification, hideNotification } from "../../../actions/notificationActions";

import { refactorTheProfileOrCoverImage } from "../../../utils/misc"
import { profilePhotosImagesUploadingPath } from '../../../utils/config'

const useStyles = customTheme => ({
    checkBoxLabel: {
        marginLeft: '-8px',
    },
    checkboxRoot: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#bf9000',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#bf9000',
        },
    },

});

const thisClassInitialState = {
    full_name: '',
    email: '',
    phone: '',
    eventTypeData: [],
    sct_event_type: '',
    // guestCountData: getGuestCount(),
    sct_guest_count: '',
    // preferredDate: new Date('2014-08-18T21:11:54'),
    preferredDate: null,
    message: '',
    networkChecked: false,
    dateFlexibleChecked: false,

};

class ContactSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = thisClassInitialState;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNetworkCheckedChange = this.handleNetworkCheckedChange.bind(this);
        this.handleDateFlexiblehange = this.handleDateFlexiblehange.bind(this);
    }



    handleChange = (field, event) => {
        this.setState({ [field]: event.target.value })
    }

    handleSubmit() {
        const {
            full_name,
            email,
            phone,
            sct_event_type,
            sct_guest_count,
            preferredDate,
            message,
            networkChecked,
            dateFlexibleChecked
        } = this.state;

        const postData = {
            full_name: full_name,
            email: email,
            phone: phone,
            sct_event_type: sct_event_type,
            sct_guest_count: sct_guest_count,
            preferredDate: preferredDate,
            message: message,
            networkChecked: networkChecked,
            dateFlexibleChecked: dateFlexibleChecked,
            emailTo: this.props.supplier.email_address
        }

        this.props.sendMessage(postData).then(data => {
            if (data.status === "contact_message_sending_success") {
                this.props.onCloseHandler();
                this.props.showNotification('Thanks for contacting. You will be replied shortly.', 'success');
                setTimeout(() => this.props.hideNotification(), 5000);
                this.clearFormState();
            }
        });
    }

    clearFormState = () => {
        this.setState(thisClassInitialState);
    }

    componentDidMount() {
        this.props.loadSupplierCategories().then(data => {
            this.setState({
                eventTypeData: data,
            });
        });
    }



    renderEventTypeOptions() {

        return this.state.eventTypeData.map((dt, i) => {
                // console.log(dt.value)
                return (

                    <MenuItem
                        label="Select Event Type"
                        value={dt.value}
                        key={i}
                        name={dt.label}
                    >
                        {dt.label}
                    </MenuItem>
                )
            });
    }

    renderGuestCountOptions() {
        return this.state.guestCountData.map((dt, i) => {
            return (
                <MenuItem
                    label="Select Guest Count"
                    value={dt.value}
                    key={i}
                    name={dt.guestCount}
                >
                    {dt.guestCount}
                </MenuItem>
            );
        });
    }

    handleDateChange = (date) => {
        this.setState({
            preferredDate: date
        });
    }

    handleNetworkCheckedChange = (event) => {
        this.setState({
            networkChecked: event.target.checked
        })
    }

    handleDateFlexiblehange = (event) => {
        this.setState({
            dateFlexibleChecked: event.target.checked
        })
    }

    render() {
        const {
            classes,
            contactFormVisbile,
            onCloseHandler,
            supplier
        } = this.props;
        const formikProps = {
            initialValues: this.state,
            validationSchema: ContactSupplierValidationSchema,
            enableReinitialize: true, //this will reinitialize form after some changes in the state with (ComponentDidMount) //Control whether Formik should reset the form if the wrapped component props change (using deep equality)
            onSubmit: this.handleSubmit,
        }

        return (
            <Rodal className="rodal--write-review rodal-contact" visible={contactFormVisbile} onClose={onCloseHandler} animation={'slideDown'}>
                <header className="contact-rodal__header">
                    <p className="rodal__heading bebas-font letter-spacing-2 xl-font-size">Send Message</p>
                </header>
                <div className="rodal-body-container">
                    <Grid
                        spacing={3}
                        direction="row"
                        container
                    >
                        <Grid item xs={12}>
                            <div className="send-message-header">
                                <div className="send-message-logo-container">
                                    {(supplier.profile_photo) ? (
                                        <img src={refactorTheProfileOrCoverImage(supplier.profile_photo, profilePhotosImagesUploadingPath)} className="write-review__logo" alt="send-message" />
                                    ) : (
                                        <img className="send-message-supploer-logo-dummy" src={profile_image} alt="profile" />
                                    )}
                                </div>
                                <div className="send-message-heading-container">
                                    {/* <p className="profile__section-content-tips overrided__tips-section">* All fields are required!</p> */}
                                    <Typography className="bebas-font word-spacing-2" variant="h3" gutterBottom>
                                        {supplier.company_name}
                                    </Typography>
                                    {/* <AddStarRating stars={rating} onStarChange={onStarChange} /> */}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Formik {...formikProps}>
                        {({
                            values,
                            handleBlur,
                            handleChange,
                            isSubmitting,
                            errors,
                            handleSubmit,
                            touched
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid
                                    spacing={2}
                                    direction="row"
                                    container
                                >

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className="margin-bottom-10"
                                            fullWidth
                                            id="full_name"
                                            name="full_name"
                                            label="Full Name*"
                                            variant="outlined"
                                            value={values.full_name}
                                            // onChange={handleChange}
                                            onChange={(event, newValue) => this.handleChange('full_name', event)}
                                            error={touched.full_name && Boolean(errors.full_name)}
                                            helperText={touched.full_name && errors.full_name}
                                        />

                                        <TextField
                                            className="margin-top-10 margin-bottom-10"
                                            fullWidth
                                            id="phone"
                                            name="phone"
                                            label="Phone"
                                            variant="outlined"
                                            value={values.phone}
                                            // onChange={handleChange}
                                            onChange={(event, newValue) => this.handleChange('phone', event)}
                                            error={touched.phone && Boolean(errors.phone)}
                                            helperText={touched.phone && errors.phone}
                                        />

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                className="margin-top-10 date-input-border "
                                                fullWidth
                                                // disableToolbar
                                                // variant="outlined"
                                                inputVariant="outlined"
                                                format="EEEE, dd MMMM, yyyy"
                                                id="preferredDate"
                                                label="Date of Event"
                                                value={values.preferredDate}
                                                name="preferredDate"
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                            {(Boolean(errors.preferredDate)) ? (
                                                <FormHelperText className="select-box-error">{errors.preferredDate}</FormHelperText>
                                            ) : null}
                                        </MuiPickersUtilsProvider>

                                        <FormControlLabel
                                            className={classes.checkBoxLabel}
                                            control={
                                                <Checkbox
                                                    className={classes.checkboxRoot}
                                                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                    icon={<span className={classes.icon} />}
                                                    checked={values.dateFlexibleChecked}
                                                    onChange={this.handleDateFlexiblehange}
                                                    name="dateFlexibleChecked"
                                                />
                                            }
                                            label="This date is flexible."
                                        />


                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            className="margin-bottom-10"
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Your Email*"
                                            variant="outlined"
                                            value={values.email}
                                            // onChange={handleChange}
                                            onChange={(event, newValue) => this.handleChange('email', event)}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />

                                        <FormControl className="margin-top-10 margin-bottom-10" fullWidth>
                                            <InputLabel className="select-box-label" id="sct_event_type">Type of Event*</InputLabel>
                                            <Select
                                                fullWidth
                                                label="Type of Event*"
                                                labelId="sct_event_type"
                                                name="sct_event_type"
                                                id="sct_event_type"
                                                variant="outlined"
                                                value={values.sct_event_type}
                                                onChange={(event, newValue) => this.handleChange('sct_event_type', event)}
                                                error={touched.sct_event_type && Boolean(errors.sct_event_type)}
                                                helperText={touched.sct_event_type && errors.sct_event_type}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.renderEventTypeOptions()}
                                            </Select>
                                            {(Boolean(errors.sct_event_type)) ? (
                                                <FormHelperText className="select-box-error">{errors.sct_event_type}</FormHelperText>
                                            ) : null}

                                        </FormControl>

                                        <TextField
                                            type="number"
                                            className="margin-top-10"
                                            fullWidth
                                            id="sct_guest_count"
                                            name="sct_guest_count"
                                            label="Number of Guests*"
                                            variant="outlined"
                                            value={values.sct_guest_count}
                                            // onChange={handleChange}
                                            onChange={(event, newValue) => this.handleChange('sct_guest_count', event)}
                                            error={touched.sct_guest_count && Boolean(errors.sct_guest_count)}
                                            helperText={touched.sct_guest_count && errors.sct_guest_count}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}
                                        container
                                        direction="column"
                                        justify="flex-start"
                                        alignItems="center">
                                        <TextField
                                            className="margin-top-10 margin-bottom-10"
                                            fullWidth
                                            multiline
                                            rows={15}
                                            id="message"
                                            name="message"
                                            label="Message*"
                                            placeholder="I found your company EventBuzz360"
                                            variant="outlined"
                                            value={values.message}
                                            // onChange={handleChange}
                                            onChange={(event, newValue) => this.handleChange('message', event)}
                                            error={touched.message && Boolean(errors.message)}
                                            helperText={touched.message && errors.message}
                                        />

                                        <Divider className="margin-bottom-30 margin-top-40 divider-fullwidth" variant="fullWidth" />

                                        <div>
                                            <center>
                                                <Button className="send-message-send-button" variant="contained" fullWidth type="submit">SEND</Button>
                                            </center>
                                        </div>

                                        <div className="checkbox-container">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        className={classes.checkboxRoot}
                                                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                        icon={<span className={classes.icon} />}
                                                        checked={values.networkChecked}
                                                        onChange={this.handleNetworkCheckedChange}
                                                        name="networkChecked"
                                                    />
                                                }
                                                label="I am in the industry and looking to add you to my network or to collaborate."
                                            />
                                        </div>

                                        <div className="margin-top-20">
                                            <Typography className="send-message-end-credit" variant="h5" gutterBottom>
                                                {supplier.company_name} will reply directly to you.
                                            </Typography>
                                        </div>

                                    </Grid>

                                </Grid>
                            </form>
                        )}
                    </Formik>
                </div>

            </Rodal>

        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendMessage: postData => dispatch(sendContactMessage(postData)),
        showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
        hideNotification: () => dispatch(hideNotification()),
        loadSupplierCategories: () => dispatch(loadSupplierCategories()),
    }
}
// export default ContactSupplier
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ContactSupplier))
