import jwt_decode from 'jwt-decode'

import { backendBaseUrl } from "../utils/config"
import { promiseReject } from "../utils/misc"

class BackendClient {
    
    checkStatusAndGetJSON = fetchResponse => fetchResponse.ok ? fetchResponse.json() : fetchResponse.json().then(promiseReject)
    
    checkForTokenExpiry = error => promiseReject(error) 
    
    checkTokenExpireThenRenewToken = () => {
        const accessToken = localStorage.getItem('accessToken')
        const accessTokenData = jwt_decode(accessToken)   
        if (Date.now() >= accessTokenData.exp * 1000) {
            const options = {
                method: 'POST',
                body: {},
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken}`
                }
            }            
            return fetch(backendBaseUrl + 'auth/revoke-token', options).then(this.checkStatusAndGetJSON)                        
        } else {
            return false
        } 
    }

    get = (path, token = "") => {
        let options = {}
        if (token !== "") {
            const refreshTokenPromise = this.checkTokenExpireThenRenewToken()
            if (typeof refreshTokenPromise.then === 'function') {
                return refreshTokenPromise.then(data => {
                    localStorage.setItem('accessToken', data.token)
                    options = {
                        method: 'GET',
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${data.token}`
                        }
                    }    
                    return fetch(backendBaseUrl + path, options).then(this.checkStatusAndGetJSON).catch(this.checkForTokenExpiry)
                })                        
            } else {
                options = {
                    method: 'GET',
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }    
            }
        } else {
            options = {
                method: 'GET',
                headers: {
                    Accept: "application/json"
                }
            }   
        }                                
        return fetch(backendBaseUrl + path, options).then(this.checkStatusAndGetJSON).catch(this.checkForTokenExpiry)
    }

    postForAuth = (path, body = {}, token = "", mediaUpload = false) => {
        let options = {}        
        const formData = new FormData()
        if (!mediaUpload) {
            Object.keys(body).forEach(key => formData.append(key, body[key]))
        } else {  
            formData.append('file', body)
        }                
        if (token !== "") {
            options = {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`
                }
            }
        } else {
            options = {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: "application/json",
                }
            }        
        }
        return fetch(backendBaseUrl + path, options).then(this.checkStatusAndGetJSON).catch(this.checkForTokenExpiry)
    }

    post = (path, body = {}, token = "", mediaUpload = false, explicit = false) => {
        let options = {}
        const formData = new FormData()
        if (!mediaUpload) {
            if (explicit) {
                formData.append('body', body)
            } else {
                Object.keys(body).forEach(key => formData.append(key, body[key]))
            }            
        } else {  
            formData.append('file', body)
        }                
        if (token !== "") {
            const refreshTokenPromise = this.checkTokenExpireThenRenewToken()
            if (typeof refreshTokenPromise.then === 'function') {
                return refreshTokenPromise.then(data => {
                    localStorage.setItem('accessToken', data.token)
                    options = {
                        method: 'POST',
                        body: formData,
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${data.token}`
                        }
                    }    
                    return fetch(backendBaseUrl + path, options).then(this.checkStatusAndGetJSON).catch(this.checkForTokenExpiry)
                }) 
            } else {
                options = {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }        
            }
        } else {
            options = {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: "application/json"
                }
            }
        }                 
        return fetch(backendBaseUrl + path, options).then(this.checkStatusAndGetJSON).catch(this.checkForTokenExpiry)
    }
}

export default new BackendClient()