import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../actions/sessionActions'

import { BackToTop } from '../../reusables/BackToTop/BackToTop'

import mailIcon from '../../assets/images/icons/icon_mail.png'
import facebookIcon from '../../assets/images/icons/icon_facebook.png'
import instagramIcon from '../../assets/images/icons/icon_instagram.png'
import linkedInIcon from '../../assets/images/icons/icon_linkedin.png'
import twitterIcon from '../../assets/images/icons/icon_twitter.png'
import pinterestIcon from '../../assets/images/icons/icon_pinterest.png'
import dblArrow from '../../assets/images/icons/dbl-arrow.svg'

import './styles/footer.css'

class Footer extends Component  {

    state = {
        social: {
            facebook: "www.facebook.com/EventBuzz360",
            linkedin: "www.linkedin.com/company/eventbuzz360-2",
            instagram: "www.instagram.com/eventbuzz360/",
            pinterest: "www.pinterest.com.au/eventbuzz360/",
            twitter: "twitter.com/EventBuzz360"
        },
        footerOpen: false
    }
    
    openFooter = () => {
        this.setState({ footerOpen: !this.state.footerOpen })
    }

    componentDidMount() {
        this.props.checkLoginStatus().then(data => data)
    }

    render () {

        const { social, footerOpen } = this.state

        const { isLoggedIn } = this.props

        return (
            <section id="footer" className={footerOpen ? "footer--open" : null}>
                <button onClick={() => this.openFooter()} className="footer__toggle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.8 490.8" className="footer__toggle-icon">
                        <g fill="#ffffff">
                            <path d="M231.696 3.128c-4.237-4.093-10.99-3.975-15.083.262-3.992 4.134-3.992 10.687 0 14.82l227.115 227.136-227.136 227.115c-4.237 4.093-4.355 10.845-.262 15.083 4.093 4.237 10.845 4.354 15.083.262.089-.086.176-.173.262-.262l234.667-234.667c4.164-4.165 4.164-10.917 0-15.083L231.696 3.128z"/>
                            <path d="M274.363 237.795L39.696 3.128a10.668 10.668 0 00-15.083 15.083l227.115 227.136L24.592 472.461c-4.237 4.093-4.354 10.845-.262 15.083 4.093 4.237 10.845 4.354 15.083.262.089-.086.176-.173.262-.262l234.667-234.667c4.169-4.159 4.179-10.911.021-15.082z"/>
                        </g>
                        <path d="M224.144 490.68a10.667 10.667 0 01-7.552-18.219l227.136-227.115L216.592 18.232c-4.093-4.237-3.975-10.99.262-15.083 4.134-3.992 10.687-3.992 14.82 0l234.667 234.667c4.164 4.165 4.164 10.917 0 15.083L231.675 487.565a10.66 10.66 0 01-7.531 3.115z"/>
                        <path d="M32.144 490.68a10.667 10.667 0 01-7.552-18.219l227.136-227.115L24.592 18.232c-4.171-4.171-4.171-10.933 0-15.104s10.933-4.171 15.104 0l234.667 234.667c4.164 4.165 4.164 10.917 0 15.083L39.696 487.544a10.665 10.665 0 01-7.552 3.136z"/>
                    </svg>

                </button>
                <BackToTop />
                <div className="footer__top">
                    <div className="footer__top-col">
                        <h3 className="footer__top-heading">
                            <a href="/company" className="footer__top-heading-link">Company</a>
                        </h3>
                        <ul className="footer__top-list">
                            <li className="footer__top-list-item">
                                <a href="/company" className="footer__top-link">About Us</a>
                            </li>
                            <li className="footer__top-list-item">
                                <a href="/privacy-policy" className="footer__top-link">Privacy Policy</a>
                            </li>
                            <li className="footer__top-list-item">
                                <a href="/acceptable-use-policy" className="footer__top-link">Acceptable Use Policy</a>
                            </li>
                            <li className="footer__top-list-item">
                                <a href="/copy-right-trademark" className="footer__top-link">Copyright &amp; Trademark</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__top-col">
                        <h3 className="footer__top-heading">
                            <a href="/explore" className="footer__top-heading-link">Explore</a>
                        </h3>
                        <ul className="footer__top-list">
                            <li className="footer__top-list-item">
                                <a href="/explore" className="footer__top-link">Advertise</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__top-col">
                        <h3 className="footer__top-heading">
                            <a href="/contact-us" className="footer__top-heading-link">Contact</a>
                        </h3>
                        <ul className="footer__top-list">
                            <li className="footer__top-list-item footer__top-list-item--inline">
                                {/* <img src={mailIcon} />&nbsp;&nbsp; */}
                                <a href="/contact-us" className="footer__top-link">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__top-col">
                        <h3 className="footer__top-heading">Location</h3>
                        <ul className="footer__top-list footer__top-list--util">
                            <li className="footer__top-list-item footer__top-list-item--util">
                                {/* <i className="fa fa-language" aria-hidden="true"></i> */}
                                <span className="footerMenuOptions">Language English</span>
                            </li>
                            <li className="footer__top-list-item footer__top-list-item--util">
                                {/* <i className="fa fa-globe"></i> */}
                                <span className="footerMenuOptions">Country Australia</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer__bottom">
                    <h3 className="footer__bottom-heading">CONNECT WITH US</h3>
                    <div className="footer__social-media-container">
                        <ul className="footer__social-media">
                            {social.instagram && 
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.instagram}>
                                        <img src={instagramIcon} alt="instagram" />
                                    </a>
                                </li>)}
                            {social.facebook &&
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.facebook}>
                                        <img src={facebookIcon} alt="facebook" />
                                    </a>
                                </li>)}
                            {social.linkedin &&
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.linkedin}>
                                        <img src={linkedInIcon} alt="linkedIn" />
                                    </a>
                                </li>)}
                            {social.pinterest &&
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.pinterest}>
                                        <img src={pinterestIcon} alt="pinterest" />
                                    </a>
                                </li>)}  
                            {social.twitter &&
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.twitter}>
                                        <img src={twitterIcon} alt="twitter" />
                                    </a>
                                </li>)}                                    
                        </ul>
                    </div>
                    <p className="footer-links">
                        <span>TM + &copy; 2021 EVENTBUZZ 360, Inc.&nbsp;|&nbsp; All rights reserved.</span>
                    </p>
                </div>
            </section>
        )
    }
}

const mapStateToProps = ({ session }) => ({      
    isLoggedIn: session.isLoggedIn,
}) 

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)