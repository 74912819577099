import React, { Component } from 'react'
import { connect } from "react-redux"

import { showNotification, hideNotification } from '../../../actions/notificationActions'
import { checkLoginStatus } from '../../../actions/sessionActions'
import { 
    loadSubCategoriesForParentCategory,    
    loadSupplierOtherSubServicesForMainService,
    loadSupplierSubServicesForMainServicePublicProfile,
    loadMainServicesInformationForPublicProfileAction,
    loadSupplierOtherSubServicesForMainServicePublicProfile
} from "../../../actions/profileActions"
import {
    supplierPublicProfileReviewFormUpdate,
    addSupplierPublicProfileReviewInAction,    
    checkTheGivenCompanyNameIsValidInAction,
    loadAllEventInformationFromEventPhotoId,
    fetchSupplierEventPhotosForPublicInAction,
    fetchSupplierPublicProfileServiceInformationInAction,
    fetchSupplierProfileBasicInformationForPublicInAction,
    fetchAllReviewsForSelectedSupplierPublicProfileInAction,
    fetchSupplierPublicProfileOtherServiceInformationInAction,
    fetchSupplierPublicProfileLocationMainInformationInAction,
    fetchSupplierPublicProfileOtherLocationInformationInAction,
    openGalleryModal,
    likesAndCommentsCount
} from '../../../actions/galleryActions'

import { SupplierHeader } from './SupplierHeader'
import SupplierFilter from './SupplierFilter'
import { Overview } from './Overview'
import { Photos } from './Photos'
import Reviews from './Reviews'
import Header from '../../Global/Header'
import ContactSupplier from './ContactSupplier'

import '../supplier/styles/supplier-profile.css'

class PublicProfile extends Component {

    state = {
        areas: [],        
        basic: {}, 
        reviews: [],
        services: [],  
        supplier: {
            basic: {},
            photos: [],
            reviews: [],
        },   
        folders: [],
        currentTab: 0,        
        otherAreas: [],   
        otherServices: [], 
        writeReviewVisbile: false,   
        contactFormVisbile: false,     
        tabs: [ 'overview', 'photos', 'reviews' ],
        starExplanations: [
            "Would not recommend this pro",
            "Just ok, could have been better",
            "Pretty good overall",
            "Great job, recommend",
            "Excellent job, highly recommend"
        ],
        reviewerRelationshipTypes: [
            {
                "key": "I hired this company",
                "text": "I hired this company"
            },
            {
                "key": "I am a professional who worked with this company",
                "text": "I am a professional who worked with this company"
            },
            {
                "key": "I received an estimate/consultation but did not hire them",
                "text": "I received an estimate/consultation but did not hire them"
            },
            {
                "key": "Other",
                "text": "Other"
            }
        ],        
        rating: 0,
        reviwerEmail: "",        
        acceptTerms: false, 
        reviewerCompany: "",
        reviewerJobTitle: "",       
        reviewerLastName: "",
        reviewerFirstName: "",
        reviewerDescription: "",        
        reviewerProjectCost: "",
        reviewerRelationship: "",
        reviewerStreetAddress: "",
        reviewerProjectCompletionYear: null,   
        reviewerProjectCompletionMonth: null,
        errorFields: {
            reviwerEmail: false,
            reviewerLastName: false,
            reviewerFirstName: false,
            reviewerDescription: false,        
            reviewerProjectCost: false,
            reviewerRelationship: false,
            reviewerStreetAddress: false,            
            reviewerProjectCompletionYear: false,       
            reviewerProjectCompletionMonth: false,
        },
        touched: {
            reviwerEmail: false,
            reviewerLastName: false,
            reviewerFirstName: false,
            reviewerDescription: false,        
            reviewerProjectCost: false,
            reviewerRelationship: false,
            reviewerStreetAddress: false,            
            reviewerProjectCompletionYear: false,   
            reviewerProjectCompletionMonth: false,
        },          
        reviewerProjectCompletionMonthsList: [
            {
                "key": "January",
                "text": "January"
            },
            {
                "key": "February",
                "text": "February"
            },
            {
                "key": "March",
                "text": "March"
            },
            {
                "key": "April",
                "text": "April"
            },
            {
                "key": "May",
                "text": "May"
            },
            {
                "key": "June",
                "text": "June"
            },
            {
                "key": "July",
                "text": "July"
            },
            {
                "key": "Augest",
                "text": "Augest"
            },
            {
                "key": "September",
                "text": "September"
            },
            {
                "key": "Octomber",
                "text": "Octomber"
            },
            {
                "key": "November",
                "text": "November"
            },
            {
                "key": "December",
                "text": "December"
            },
        ],
        reviewerProjectCompletionYearsList: [
            {
                "key": "2014",
                "text": "2014"
            },
            {
                "key": "2015",
                "text": "2015"
            },
            {
                "key": "2016",
                "text": "2016"
            },
            {
                "key": "2017",
                "text": "2017"
            },
            {
                "key": "2018",
                "text": "2018"
            },
            {
                "key": "2019",
                "text": "2019"
            },
            {
                "key": "2020",
                "text": "2020"
            },
            {
                "key": "2021",
                "text": "2021"
            },
            {
                "key": "2022",
                "text": "2022"
            },
            {
                "key": "2023",
                "text": "2023"
            },
            {
                "key": "2024",
                "text": "2024"
            },
            {
                "key": "2025",
                "text": "2025"
            },
        ],
        projectCostTypes: [
            {
                "key": "Less than $1,000",
                "text": "Less than $1,000"
            },
            {
                "key": "$1,000 - $9,9990",
                "text": "$1,000 - $9,999"
            },
            {
                "key": "$10,000 - $49,999",
                "text": "$10,000 - $49,999"
            },
            {
                "key": "$50,000 - $100,000",
                "text": "$50,000 - $100,000"
            },
            {
                "key": "over $100,000",
                "text": "over $100,000"
            },
        ]
    }

    componentWillMount() {
        const currentUrl = window.location.href
        const splittedUrl = currentUrl.split('/')
        this.props.checkLoginStatus().then(data => data)        
        this.props.checkTheGivenCompanyNameIsValidInAction(decodeURI(splittedUrl[4])).then(data => {
            if (data.email) {
                this.setState({ 
                    supplierUserEmail: data.email, 
                    relationshipTypeIntialLoad: true 
                })
                this.props.fetchSupplierProfileBasicInformationForPublicInAction(data.email).then(basic => this.setState({ basic }))
                this.props.fetchAllReviewsForSelectedSupplierPublicProfile(data.email).then(reviews => this.setState({ reviews }))
                this.props.fetchSupplierEventPhotosForPublicInAction(data.email).then(data => {
                    let group = data.reduce((r, a) => {
                        r[a.event_id] = [...r[a.event_id] || [], a];
                        return r;
                       }, {});

                    Object.keys(group).map((key) => {
                        this.props.likesAndCommentsCount(key).then(result => {
                            const event = {
                                id: key,
                                likes: result.likes,
                                comments: result.comments,
                                images: group[key]
                            }

                            this.setState({
                                ...this.state,
                                folders: [ ...this.state.folders, event]
                            })
                        })
                    })

                })
                this.props.fetchSupplierPublicProfileLocationMainInformationInAction(data.email).then(areas => this.setState({ areas: areas.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i) })) //This will filter all duplicate values in array and set state
                this.props.fetchSupplierPublicProfileOtherLocationInformationInAction(data.email).then(otherAreas => {
                    this.setState({ otherAreas: otherAreas.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i) }) //This will filter all duplicate values in array and set state
                })
                    
                this.props.loadMainServicesInformationForPublicProfileAction(data.email).then(mainServices => this.setState({ mainServices }))
                this.props.loadSubCategoriesForParentCategory(data.supplier_type_id).then(data => {
                    if (data.length > 0) {                                     
                        this.setState({ 
                            supplierSubTypes: data,
                            isSupplierTypeDisabled: true,
                        }) 
                    }
                })
            } else {
                window.location.href = "/gallery"
            }
        })
    }

    selectedTab = key => this.setState({ currentTab: key })

    showWriteReview = () => this.setState({ writeReviewVisbile: true, currentTab: 2 })

    hideWriteReview = () => this.setState({ writeReviewVisbile: false })

    showContactForm = () => this.setState({ contactFormVisbile: true })

    hideContactForm = () => this.setState({ contactFormVisbile: true })

    goToTab = key => {
        this.setState({ currentTab: key })
        this.selectedTab(key)
    }

    activateTab = (index, mainServiceId) => {
        const { activeTab, supplierUserEmail } = this.state
        if (activeTab === parseInt(mainServiceId) ) {
            this.setState({ activeTab: 0 })
        } else { 
            this.setState({ activeTab: parseInt(mainServiceId) }, () => (
                this.props.loadSupplierSubServicesForMainServicePublicProfile(mainServiceId, supplierUserEmail).then(subServicesInfo => {                
                    this.props.loadSupplierOtherSubServicesForMainServicePublicProfile(mainServiceId, supplierUserEmail).then(otherSubServicesInfo => {
                        this.setState({ 
                            subServicesInfo: subServicesInfo,
                            otherSubServicesInfo: otherSubServicesInfo
                        })
                    })
                })    
            ))
        }
    }

    onStarChange = input => e => {
        this.setState({ rating: input })
        this.props.supplierPublicProfileReviewFormUpdate('rating', input)
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    handleChange = field => event => {          
        this.setState({ 
            [field]: event.target.value, 
            errorFields: {
                [field]: (!event.target.value) ? true : false
            },
            errorsInTheForm: (!event.target.value) ? true : false,
        })
        this.props.supplierPublicProfileReviewFormUpdate([field], event.target.value) 
    }

    onCloseContactModalBoxHandler = () => {
        this.setState({             
            rating: 0,
            reviwerEmail: "",
            acceptTerms: false,
            reviewerCompany: "",
            reviewerJobTitle: "",
            hideReviewForm: true,        
            reviewerLastName: "",
            reviewerContactNo: "",
            reviewerFirstName: "",
            reviewerDescription: "",        
            reviewerProjectCost: "",
            reviewerRelationship: "",
            reviewerStreetAddress: "",      
            writeReviewVisbile: false,   
            contactFormVisbile: false,   
            reviewerProjectCompletionYear: null,   
            reviewerProjectCompletionMonth: null,
            errorFields: {
                reviwerEmail: false,
                reviewerLastName: false,
                reviewerContactNo: false,
                reviewerFirstName: false,
                reviewerDescription: false,        
                reviewerProjectCost: false,
                reviewerRelationship: false,
                reviewerStreetAddress: false,                
                reviewerProjectCompletionYear: false,       
                reviewerProjectCompletionMonth: false,
            },
            touched: {
                reviwerEmail: false,
                reviewerLastName: false,
                reviewerFirstName: false,
                reviewerContactNo: false,
                reviewerDescription: false,        
                reviewerProjectCost: false,
                reviewerRelationship: false,
                reviewerStreetAddress: false,                
                reviewerProjectCompletionYear: false,   
                reviewerProjectCompletionMonth: false,
            },                
        }) 
        this.props.supplierPublicProfileReviewFormUpdate("supplierEmailMessage", "")         
    }

    submitReview = () => {
        const { emailAddress } = this.props
        const { supplierUserEmail } = this.state
        this.props.addSupplierPublicProfileReview(emailAddress, supplierUserEmail).then(data => {
            if (data === "supplier_review_success") {
                this.props.showNotification('Thanks for your review!.', 'success')      
                this.props.fetchAllReviewsForSelectedSupplierPublicProfile(supplierUserEmail).then(reviews => this.setState({ reviews }))
                this.goToTab(2)
                this.hideWriteReview()
                setTimeout(() => {                    
                    this.props.hideNotification()
                    this.setState({
                        rating: 0,
                        reviwerEmail: "",
                        acceptTerms: false,
                        reviewerCompany: "",
                        hideReviewForm: true,        
                        reviewerJobTitle: "",
                        reviewerLastName: "",
                        reviewerContactNo: "",
                        reviewerFirstName: "",
                        reviewerDescription: "",        
                        reviewerProjectCost: "",
                        reviewerRelationship: "",
                        reviewerStreetAddress: "",
                        reviewerProjectCompletionMonth: null,
                        reviewerProjectCompletionYear: null,   
                    })
                }, 6000)      
            } else {
                this.props.showNotification('Something went wrong! Please contact support...', 'error')
                setTimeout(() => this.props.hideNotification(), 6000)                
            }
        })
    }   
    
    openGalleryModal = image_id => {
        console.log(image_id);

        const {
            openGalleryModal,
            loadAllEventInformationFromEventPhotoId
        } = this.props
        openGalleryModal()
        loadAllEventInformationFromEventPhotoId(image_id)
        document.querySelector('body').classList.add('modal-open')
    }

    render() {

        let activeComponent = null
        const { loading } = this.props
        const { 
            tabs,
            basic,
            areas,
            folders,
            rating,
            reviews,
            touched,
            services,
            activeTab,
            otherAreas,
            currentTab,
            errorFields,            
            acceptTerms,
            mainServices,
            reviwerEmail,
            otherServices,
            reviewerCompany,
            subServicesInfo,
            projectCostTypes,
            reviewerLastName,
            reviewerJobTitle,
            supplierUserEmail,
            reviewerFirstName,
            reviewerContactNo,
            writeReviewVisbile,
            contactFormVisbile,
            reviewerDescription,
            reviewerProjectCost,
            reviewerRelationship,
            reviewerStreetAddress,
            reviewerRelationshipTypes,
            reviewerProjectCompletionYear,
            reviewerProjectCompletionMonth,
            reviewerProjectCompletionYearsList,
            reviewerProjectCompletionMonthsList,
        } = this.state
        const { emailAddress } = this.props

        if (currentTab === 1) {
            activeComponent = <Photos folders={folders} openGalleryModal={this.openGalleryModal}/>
        }
        if (currentTab === 2) {
            activeComponent = <Reviews 
                                    rating={rating}
                                    touched={touched}
                                    reviews={reviews}                                      
                                    acceptTerms={acceptTerms}    
                                    errorFields={errorFields}                               
                                    logo={basic.profile_photo}     
                                    reviwerEmail={reviwerEmail}
                                    handleBlur={this.handleBlur}                                        
                                    submitReview={this.submitReview}
                                    companyName={basic.company_name}  
                                    onStarChange={this.onStarChange}  
                                    handleChange={this.handleChange}
                                    reviewerCompany= {reviewerCompany}                                                                    
                                    reviewerJobTitle={reviewerJobTitle}
                                    projectCostTypes={projectCostTypes}
                                    reviewerLastName={reviewerLastName}
                                    reviewerContactNo={reviewerContactNo}
                                    reviewerFirstName={reviewerFirstName}
                                    supplierUserEmail={supplierUserEmail}                                                                
                                    showWriteReview={this.showWriteReview} 
                                    writeReviewVisbile={writeReviewVisbile}    
                                    reviewerDescription={reviewerDescription}             
                                    reviewerProjectCost={reviewerProjectCost}                                     
                                    reviewerRelationship={reviewerRelationship}            
                                    reviewerStreetAddress={reviewerStreetAddress}
                                    reviewerRelationshipTypes={reviewerRelationshipTypes}          
                                    reviewerProjectCompletionYear={reviewerProjectCompletionYear}
                                    reviewerProjectCompletionMonth={reviewerProjectCompletionMonth}                                    
                                    onCloseContactModalBoxHandler={this.onCloseContactModalBoxHandler}    
                                    reviewerProjectCompletionYearsList={reviewerProjectCompletionYearsList}
                                    reviewerProjectCompletionMonthsList={reviewerProjectCompletionMonthsList}
                                />                                                
        }
        if (!currentTab) {
            activeComponent = <Overview
                                areas={areas}                                
                                supplier={basic}
                                services={services} 
                                activeTab={activeTab}
                                otherAreas={otherAreas}                                                                  
                                mainServices={mainServices}                                
                                otherServices={otherServices}
                                activateTab={this.activateTab}
                                subServicesInfo={subServicesInfo}
                            />
        }

        return (
            <>
                <Header />   
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>      
                <div className="main">
                    <SupplierHeader 
                        supplier={basic}
                        reviews={reviews}
                        loggedUserEmail={emailAddress}                                                                   
                        showWriteReview={this.showWriteReview} 
                        showContactForm = {this.showContactForm}
                    />
                    <SupplierFilter 
                        tabs={tabs} 
                        number={basic.tel_no}                           
                        goToTab={this.goToTab}
                        currentTab={currentTab}                    
                        website={basic.website}  
                        country={basic.country}                                          
                        activeTab={this.selectedTab}
                        numberExtra={basic.mobile_no}
                    />
                    {activeComponent}
                    <ContactSupplier 
                        contactFormVisbile={contactFormVisbile}  
                        onCloseHandler={this.onCloseContactModalBoxHandler}   
                        supplier={basic}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ session, profile }) => ({
    loading: profile.loading,
    emailAddress: session.emailAddress,
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),    
    hideNotification: () => dispatch(hideNotification()),    
    openGalleryModal: () => dispatch(openGalleryModal()),
    likesAndCommentsCount: event_id => dispatch(likesAndCommentsCount(event_id)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),  
    loadAllEventInformationFromEventPhotoId: imageId => dispatch(loadAllEventInformationFromEventPhotoId(imageId)),      
    loadSubCategoriesForParentCategory: parentCatId => dispatch(loadSubCategoriesForParentCategory(parentCatId)),    
    supplierPublicProfileReviewFormUpdate: (field, value) => dispatch(supplierPublicProfileReviewFormUpdate(field, value)),
    fetchSupplierEventPhotosForPublicInAction: userEmail => dispatch(fetchSupplierEventPhotosForPublicInAction(userEmail)),
    checkTheGivenCompanyNameIsValidInAction: companyName => dispatch(checkTheGivenCompanyNameIsValidInAction(companyName)),
    loadSupplierOtherSubServicesForMainService: userEmail => dispatch(loadSupplierOtherSubServicesForMainService(userEmail)),    
    fetchSupplierPublicProfileServiceInformation: userEmail => dispatch(fetchSupplierPublicProfileServiceInformationInAction(userEmail)),    
    loadMainServicesInformationForPublicProfileAction: userEmail => dispatch(loadMainServicesInformationForPublicProfileAction(userEmail)),
    addSupplierPublicProfileReview: (userEmail, supplierEmail) => dispatch(addSupplierPublicProfileReviewInAction(userEmail, supplierEmail)),    
    fetchAllReviewsForSelectedSupplierPublicProfile: userEmail => dispatch(fetchAllReviewsForSelectedSupplierPublicProfileInAction(userEmail)),           
    fetchSupplierProfileBasicInformationForPublicInAction: userEmail => dispatch(fetchSupplierProfileBasicInformationForPublicInAction(userEmail)),    
    fetchSupplierPublicProfileLocationMainInformationInAction: userEmail => dispatch(fetchSupplierPublicProfileLocationMainInformationInAction(userEmail)),
    fetchSupplierPublicProfileOtherServiceInformationInAction: userEmail => dispatch(fetchSupplierPublicProfileOtherServiceInformationInAction(userEmail)),
    fetchSupplierPublicProfileOtherLocationInformationInAction: userEmail => dispatch(fetchSupplierPublicProfileOtherLocationInformationInAction(userEmail)),        
    loadSupplierSubServicesForMainServicePublicProfile: (mainServiceId, userEmail) => dispatch(loadSupplierSubServicesForMainServicePublicProfile(mainServiceId, userEmail)),
    loadSupplierOtherSubServicesForMainServicePublicProfile: (mainServiceId, userEmail) => dispatch(loadSupplierOtherSubServicesForMainServicePublicProfile(mainServiceId, userEmail))
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicProfile)