import React, { Component } from 'react'
import { connect } from "react-redux"

import {     
    loadTheGalleryImages,
    retrievePaginatedImagesOnLoad,
    checkKeywordValidAsActualEventType,
    loadAllRegisteredEventsIndexPhotosMax,
} from '../../../actions/galleryActions'

import HomePageFilter from './HomePageFilter'
import GalleryImages from './GalleryImages'
import Header from '../../Global/Header'

class Home extends Component {

    state = {
        allImages: [],
        allStates: [],        
        postFilter: '',
        comments_count: 0,
        stateFilter: 'ALL',
        actualIndexUrl: "",
        currentEventTypeId: null,
    }

    componentWillMount() {
        const currneturl = window.location.pathname.split('/')        
        if (currneturl[2]) {
            this.props.checkKeywordValidAsActualEventType(currneturl[2]).then(data => {
                if (data) {
                    this.setState({ currentEventTypeId: data.id })
                    this.props.retrievePaginatedImagesOnLoad(this.state.currentEventTypeId)
                } else {
                    window.location.href = '/'
                }
            })            
        } else {
            this.props.retrievePaginatedImagesOnLoad()
        }    
    }

    setActivePostsFilter = postFilter => this.setState({ postFilter })

    setActiveState = stateFilter => this.setState({ stateFilter })

    setActiveLayout = layout => this.setState({ layout })

    getStates = allStates => this.setState({ allStates })

    render() {  

        const {                        
            allStates, 
            postFilter,  
            stateFilter,
            currentEventTypeId
        } = this.state

        return (
            <>
                <Header />
                <div className="main">
                    <div className={this.props.loading ? "overlay overlay--full" : "overlay hide"}>
                        <div className="profile__loader-container">
                            <div className="loader">
                                <span>Loading...</span>
                            </div>
                        </div>
                    </div>
                    {/* <HomePageFilter 
                        states={allStates}                      
                        setActiveState={this.setActiveState}
                        setActiveLayout={this.setActiveLayout}
                        setActivePostsFilter={this.setActivePostsFilter} 
                    /> */}
                    <GalleryImages                    
                        postFilter={postFilter} 
                        stateFilter={stateFilter}
                        currentEventTypeId={currentEventTypeId}
                    />                
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ session, gallery }) => ({
    loading: gallery.loading,
    isLoggedIn: session.isLoggedIn,
})
  
const mapDispatchToProps = dispatch => ({
    loadTheGalleryImages: () => dispatch(loadTheGalleryImages()),
    retrievePaginatedImagesOnLoad: eventType => dispatch(retrievePaginatedImagesOnLoad(eventType)),
    checkKeywordValidAsActualEventType: keyword => dispatch(checkKeywordValidAsActualEventType(keyword)),
    loadAllRegisteredEventsIndexPhotosMax: eventType => dispatch(loadAllRegisteredEventsIndexPhotosMax(eventType)),    
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Home)