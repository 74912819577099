import React, { Component } from 'react'
import { connect } from 'react-redux'

import { PostsFilter } from '../../Global/PostsFilter'
import img1 from '../../../assets/images/icons/side_view.png'
import img2 from '../../../assets/images/icons/2_grid_view.png'
import img3 from '../../../assets/images/icons/3_grid_view.png'
import img11 from '../../../assets/images/icons/side_view_active.png'
import img22 from '../../../assets/images/icons/2_grid_view_active.png'
import img33 from '../../../assets/images/icons/3_grid_view_active.png'
import './styles/filter-home-page.css'

class HomePageFilter extends Component {

  state = { 
        currentLayout: 2,
        LayoutImages: [
            { 
                key: 0,
                image: img1,
                active: img11
            },
            {
                key: 1,
                image: img2,
                active: img22
            },
            {
                key: 2,
                image: img3,
                active: img33
            }
        ],
        postsFilters: [
            {
                value: 'Popular Today',
                code: 'PT'
            },
            {
                value: 'Latest Activity',
                code: 'LA'
            },
            {
                value: 'All Time Popular',
                code: 'ATP'
            },
            {
                value: 'Newly Featured',
                code: 'NF'
            }
        ]
    }

    componentDidMount() {
        this.props.setActivePostsFilter(this.state.postsFilters[0].code)
    }

    goToLayout = index => this.setState({ currentLayout: index })

    filteredState = filteredState => this.props.setActiveState(filteredState)

    filteredPost = filteredPost => this.props.setActivePostsFilter(filteredPost)

    // nFormatter = num => {
    //     if (num >= 1000000000) {
    //         return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
    //     }
    //     if (num >= 1000000) {
    //         return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    //     }
    //     if (num >= 1000) {
    //         return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    //     }
    //     return num
    // }

    render() {

        const heading = 'Gallery'
        const { 
            LayoutImages,
            postsFilters, 
        } = this.state
        let { photosCount } = this.props
    
        // if ((!allImages) || (allImages.length === 0)) return null

        return (
            <div className="filter filter--home-page">
                <div className="filter__filters">
                    <PostsFilter heading={heading} filteredPost={this.filteredPost} postsFilters={postsFilters}/>
                    {/* <StatesFilter states={states} filteredState={this.filteredState} /> */}
                </div>
                <div className="filter__layouts">
                    <label className="filter__stats-count">{`${photosCount} Photo(s)`}</label>
                    {/* <ul className="filter__layouts-list">
                    {LayoutImages.map((image, index) => 
                        <li key={index} onClick={e => this.goToLayout(index)} className= {
                            index ===  this.state.currentLayout
                                ? "filter__layouts-item filter__layouts-item--active"
                                : "filter__layouts-item"
                        }>{index ===  this.state.currentLayout ? <img key={index} src={image.active} /> : <img key={index} src={image.image} />}</li>
                    )}</ul> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ gallery }) => ({      
    photosCount: gallery.photosCount,
}) 

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(HomePageFilter)