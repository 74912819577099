import { 
    getAuthToken,
    checkUsername, 
    postSubscribe, 
    supplierRegister, 
    supplierActivation
} from "../services/authServices"

export const REGISTER_FORM_UPDATE = "REGISTER_FORM_UPDATE"
export const SEND_REGISTER_CLEAR_FORM = "SEND_REGISTER_CLEAR_FORM"
export const SEND_REGISTER_FORM_REQUEST = "SEND_REGISTER_FORM_REQUEST"
export const SEND_REGISTER_FORM_SUCCESS = "SEND_REGISTER_FORM_SUCCESS"
export const SEND_REGISTER_FORM_FAILURE = "SEND_REGISTER_FORM_FAILURE"
export const SEND_USERNAME_EXISTS_REQUEST = "SEND_USERNAME_EXISTS_REQUEST"
export const UPDATE_USER_TYPE_ON_REGISTER = "UPDATE_USER_TYPE_ON_REGISTER"
export const SEND_SUPPLIER_REGISTER_FORM_REQUEST = "SEND_SUPPLIER_REGISTER_FORM_REQUEST"
export const SEND_USERNAME_EXISTS_REQUEST_SUCCESS = "SEND_USERNAME_EXISTS_REQUEST_SUCCESS"
export const SEND_USERNAME_EXISTS_REQUEST_FAILURE = "SEND_USERNAME_EXISTS_REQUEST_FAILURE"
export const SEND_SUPPLIER_ACTIVATION_FORM_REQUEST = "SEND_SUPPLIER_ACTIVATION_FORM_REQUEST"
export const SEND_SUPPLIER_REGISTER_FORM_REQUEST_SUCCESS = "SEND_SUPPLIER_REGISTER_FORM_REQUEST_SUCCESS"
export const SEND_SUPPLIER_REGISTER_FORM_REQUEST_FAILURE = "SEND_SUPPLIER_REGISTER_FORM_REQUEST_FAILURE"
export const SEND_SUPPLIER_ACTIVATION_FORM_REQUEST_SUCCESS = "SEND_SUPPLIER_ACTIVATION_FORM_REQUEST_SUCCESS"
export const SEND_SUPPLIER_ACTIVATION_FORM_REQUEST_FAILURE = "SEND_SUPPLIER_ACTIVATION_FORM_REQUEST_FAILURE"

const sendRegisterFormRequest = () => ({
    type: SEND_REGISTER_FORM_REQUEST
})

const sendLoginFormSuccess = () => ({
    type: SEND_REGISTER_FORM_SUCCESS
})

const sendRegisterFormFailure = () => ({
    type: SEND_REGISTER_FORM_FAILURE
})

const sendRegisterClearForm = () => ({
    type: SEND_REGISTER_CLEAR_FORM
})

const sendSupplierRegisterFormRequest = () => ({
    type: SEND_SUPPLIER_REGISTER_FORM_REQUEST
})

const sendSupplierRegisterFormRequestSuccess = () => ({
    type: SEND_SUPPLIER_REGISTER_FORM_REQUEST_SUCCESS
})

const sendSupplierRegisterFormRequestFailure = () => ({
    type: SEND_SUPPLIER_REGISTER_FORM_REQUEST_FAILURE
})

const sendSupplierActivationFormRequest = () => ({
    type: SEND_SUPPLIER_ACTIVATION_FORM_REQUEST
})

const sendSupplierActivationFormRequestSuccess = () => ({
    type: SEND_SUPPLIER_ACTIVATION_FORM_REQUEST_SUCCESS
})

const sendSupplierActivationFormRequestFailure = () => ({
    type: SEND_SUPPLIER_ACTIVATION_FORM_REQUEST_FAILURE
})

const sendUsernameExistsReuqest = () => ({
    type: SEND_USERNAME_EXISTS_REQUEST
})

const sendUsernameExistsReuqestSuccess = () => ({
    type: SEND_USERNAME_EXISTS_REQUEST_SUCCESS
})

const sendUsernameExistsReuqestFailure = () => ({
    type: SEND_USERNAME_EXISTS_REQUEST_FAILURE
})

export const updateUserTypeOnRegister = userType => ({
    type: UPDATE_USER_TYPE_ON_REGISTER,
    userType
}) 

export const registerFormUpdate = ({ field, value }) => ({
    type: REGISTER_FORM_UPDATE,
    field,
    value
})

export const sendClearedRegisterForm = () => dispatch => {
    dispatch(sendRegisterClearForm())
    return new Promise((resolve, reject) => resolve(true))
}

export const sendRegisterForm = () => (dispatch, getState) => {
    dispatch(sendRegisterFormRequest())
    const { emailAddress, firstName, lastName, company } = getState().register.user
    return postSubscribe({ firstName, lastName, emailAddress, company })
        .then(data => {
            dispatch(sendLoginFormSuccess())
            return data
        })
        .catch(error => {
            dispatch(sendRegisterFormFailure())
            return error
        })
}

export const sendSupplierRegisterForm = userType => (dispatch, getState) => {
    dispatch(sendSupplierRegisterFormRequest())
    const { emailAddress, password, firstName, lastName, company } = getState().register  
    return supplierRegister(emailAddress, password, userType, firstName, lastName, company)
        .then(data => {
            dispatch(sendSupplierRegisterFormRequestSuccess())
            return data
        })
        .catch(error => {
            dispatch(sendSupplierRegisterFormRequestFailure())
            return error
        })
}

export const accountActivation = activationKey => (dispatch, getState) => {
    dispatch(sendSupplierActivationFormRequest())
    return supplierActivation(activationKey)
        .then(data => {
            dispatch(sendSupplierActivationFormRequestSuccess())
            return data
        })
        .then(data => {
            return getAuthToken(data).then(tokenDetails => {
                localStorage.setItem('email', data)
                localStorage.setItem('accessToken', tokenDetails.token)
                localStorage.setItem('expires', tokenDetails.expires)
                return tokenDetails
            })
        })
        .catch(error => {
            dispatch(sendSupplierActivationFormRequestFailure())
            return error
        })
}

export const checkUsernameExists = email => dispatch => {
    dispatch(sendUsernameExistsReuqest())
    return checkUsername(email)
        .then(data => {
            dispatch(sendUsernameExistsReuqestSuccess())
            return data
        })
        .catch(error => {
            dispatch(sendUsernameExistsReuqestFailure())
            return error
        })
}