import React, { Component } from 'react'
import { connect } from "react-redux"

import Wizard from './Wizard'
import Header from '../Global/Header'

import './EditProfileScreen.css'

class EditProfileScreen extends Component {

    render() {  
        
        setTimeout(() => {
            if (!this.props.isLoggedIn) {
                window.location.href = "/"
            }            
        }, 1500)  
        return (
            <>
                <Header />
                <Wizard />
            </>
        )
    }
}

const mapStateToProps = ({ session }) => ({      
    isLoggedIn: session.isLoggedIn
}) 

export default connect(mapStateToProps, null)(EditProfileScreen)