import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
    nextStep,
    // newORexisting,
    // setUserType,
    gotoSecondary,
    resetPassword,
    setNewUserType,
    setSecondaryUserType,
    changeStepOnUserType, 
    registrationCompleted
} from '../../../actions/newLoginActions'

import { LoginInitial } from './LoginInitial'
import SignUp from './SignUp'
import SignIn from './SignIn'
import ForgotPassword from './ForgotPassword'
// import { UserType } from './UserType'
import UserApprovalNotification from '../../Global/loginPopup/UserApprovalNotification'
import { UserTypePrimary } from './UserTypePrimary'
import { UserTypeSecondary } from './UserTypeSecondary'

class LoginModalWizard extends Component {

    nextStep = () => this.props.nextStep()

    newORexisting = userType => setTimeout(() => this.props.changeStepOnUserType(userType), 500)

    newFilteredUserType = (userType, section) => this.props.setNewUserType(userType, section)

    secondaryUserType = userType => this.props.setSecondaryUserType(userType)

    gotoSecondary = () => this.props.gotoSecondary()

    resetPassword = () => this.props.resetPassword()

    registrationCompletedNotify = () => this.props.registrationCompleted()

    render () {
        const {
            step,
            jobs,
            company,
            individual,
            newUserType,
            primaryUserTypes,
        } = this.props

        switch(step) {
            case 1:
                return <LoginInitial newORexisting={this.newORexisting} />
            case 2:
                return <SignIn resetPassword={this.resetPassword} />
            case 3:
                return <UserTypePrimary
                            jobs={jobs}
                            company={company}
                            individual={individual}
                            userTypes={primaryUserTypes}
                            newUserType={newUserType}
                            gotoSecondary={this.gotoSecondary}
                            newFilteredUserType={this.newFilteredUserType}
                        />
            case 4:
                return <UserTypeSecondary
                            nextStep={this.nextStep}
                            newUserType={newUserType}                        
                            secondaryUserType={this.secondaryUserType}
                            userTypes={primaryUserTypes.company[0].subCategories}
                        />
            case 5:
                return <SignUp nextStep={this.nextStep} completedNotification={this.registrationCompletedNotify} />
            case 6:
                return <UserApprovalNotification />
            case 7:
                return <ForgotPassword nextStep={this.nextStep} />
    
        }
    }
}

const mapStateToProps = ({ newLogin }) => ({
    step: newLogin.step,
    jobs: newLogin.jobs,
    company: newLogin.company,
    individual: newLogin.individual,
    newUserType: newLogin.newUserType,
    primaryUserType: newLogin.primaryUserType,
    primaryUserTypes: newLogin.primaryUserTypes,
})

const mapDispatchToProps = dispatch => ({
    nextStep: () => dispatch(nextStep()),
    resetPassword: () => dispatch(resetPassword()),
    gotoSecondary: () => dispatch(gotoSecondary()),
    // setUserType: userType => dispatch(setUserType(userType)),
    changeStepOnUserType: () => dispatch(changeStepOnUserType()),
    registrationCompleted: () => dispatch(registrationCompleted()),    
    changeStepOnUserType: userType => dispatch(changeStepOnUserType(userType)),
    setSecondaryUserType: userType => dispatch(setSecondaryUserType(userType)),
    setNewUserType: (userType, section) => dispatch(setNewUserType(userType, section)),    
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginModalWizard)
