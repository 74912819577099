import React, { Component } from 'react'
import { Popper, Fade, Divider, Button } from '@material-ui/core'

import { LinearProgressWithLabel } from './LinearProgressWithLabel'
import { ProfileProgress } from './ProfileProgress'
export class ProfileCompletionProgress extends Component {

    state = {
        anchorEl: null
    }

    handleClick = event => this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget })

    handleClose = () => this.setState({ anchorEl: null })

    render() {

        const { anchorEl } = this.state
        const { profileProgress } = this.props        

        const style = `conic-gradient(#a47c00 0 ${profileProgress}%, white 0 ${100 - profileProgress}%)`
        const open = Boolean(anchorEl)
        const id = open ? 'transitions-popper' : undefined

        return (
            <div className="header__profile-progress">
                <button aria-describedby={id} type="button" className="header__profile-progress-btn" onClick={e => this.handleClick(e)}>                    
                    <div className="conic circle" style={{background: style}}></div>
                    <div className="percentage-value">{profileProgress}%</div>
                </button>
                <Popper 
                    id={id} 
                    open={open} 
                    anchorEl={anchorEl} 
                    transition 
                    className="header__popover"
                >
                    {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div className="header__popover-container">
                            <header className="header__popover-header">
                                <h5 className="header__popover-title">Let's go</h5>
                                <p>Here are a few things to help you set up for success!</p>
                            </header>
                            <LinearProgressWithLabel value={profileProgress} />
                            <Divider className="header__popover-divider" />
                            <ProfileProgress />
                            <footer className="header__popover-footer">
                                <Button className="header__popover-close" onClick={this.handleClose}>Dismiss</Button>
                            </footer>
                        </div>
                    </Fade>
                    )}
                </Popper>
            </div>
        )
    }
}