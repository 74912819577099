import React, { Component } from 'react'
import PhoneInput from 'react-phone-input-2'
import LanguageIcon from '@material-ui/icons/Language'

import { 
    formatUrl,
    cleanTheWebSiteUrlAndReturnPlainURL 
} from '../../../utils/misc'
export default class SupplierFilter extends Component {

    state = {
        currentTab: 0
    }

    componentDidMount() {
        this.props.activeTab(this.state.currentTab)
    }

    goToTab = key => {
        this.setState({ currentTab: key })
        this.props.activeTab(key)
    }

    render() {

        let showMobileOrTelNo = false
        const { currentTab } = this.state
        const { 
            tabs,
            supplier
        } = this.props

        if ((supplier.basic) && (supplier.basic.tel_no || supplier.basic.mobile_no)) {
            showMobileOrTelNo = true
        }

        return (
            <section className="filter filter--supplier-profile">
                <div className="supplier-profile__contact-details">
                    {supplier && supplier.basic && showMobileOrTelNo && (
                        <div className="supplier__telephone-container">
                            <a href={`${'tel:' + (supplier.basic.tel_no || supplier.basic.mobile_no)}`}>
                                {supplier.basic.tel_no || supplier.basic.mobile_no && (
                                    <PhoneInput
                                        country={'au'}
                                        color="primary"
                                        disabled="true"
                                        value={supplier.basic.tel_no || supplier.basic.mobile_no}
                                    />
                                )}
                            </a>
                        </div>
                    )}
                    {
                        supplier.basic.website &&
                        <a target="_blank" href={supplier && supplier.basic && supplier.basic.website ? `${formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.basic.website))}` : '#'} className="supplier-profile__website">
                            <LanguageIcon className="customIconsStyle" />Website
                        </a>
                    }
                </div>
                <ul className="supplier-profile__tabs">
                    {tabs.map((tab, key) => <li key={key} onClick={e => this.goToTab(key)} className={key ===  currentTab ? "supplier-profile__list supplier-profile__list--active" : "supplier-profile__list"}>{tab}</li>)}
                </ul>
            </section>
        )
    }
}