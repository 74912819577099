import { 
    loadAllCities,
    loadAllStates,
    loadAllCountries,
    sendSubmitContactUsData,
    connectOtherSupplierByEmail,
    fetchRelatedSubServicesService,
} from "../services/generalServices"
import { 
    loadSupplierFilledSubCategoriesIds, 
    loadRelatedSuppliersForFindProOrHire,
    loadRelatedSuppliersForFindProOrHireAsMain,
    loadAllSupliersCompanyName
} from "../services/mediaServices"

export const LOAD_ALL_STATES = "LOAD_ALL_STATES"
export const LOAD_ALL_CITIES = "LOAD_ALL_CITIES"
export const LOAD_ALL_COUNTRIES = "LOAD_ALL_COUNTRIES"
export const LOAD_ALL_CITIES_SUCCESS = "LOAD_ALL_CITIES_SUCCESS"
export const LOAD_ALL_CITIES_FAILURE = "LOAD_ALL_CITIES_FAILURE"
export const LOAD_ALL_STATES_SUCCESS = "LOAD_ALL_STATES_SUCCESS"
export const LOAD_ALL_STATES_FAILURE = "LOAD_ALL_STATES_FAILURE"
export const LOAD_ALL_COUNTRIES_SUCCESS = "LOAD_ALL_COUNTRIES_SUCCESS"
export const LOAD_ALL_COUNTRIES_FAILURE = "LOAD_ALL_COUNTRIES_FAILURE"
export const SUPPLIER_CONTACT_FORM_UPDATE = "SUPPLIER_CONTACT_FORM_UPDATE"
export const SEND_SUBMIT_CONTACT_DATA_REQUEST = "SEND_SUBMIT_CONTACT_DATA_REQUEST"
export const SEND_SUBMIT_CONTACT_DATA_REQUEST_SUCCESS = "SEND_SUBMIT_CONTACT_DATA_REQUEST_SUCCESS"
export const SEND_SUBMIT_CONTACT_DATA_REQUEST_FAILURE = "SEND_SUBMIT_CONTACT_DATA_REQUEST_FAILURE"
export const CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST = "CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST"
export const CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST_SUCCESS = "CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST_SUCCESS"
export const LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST = "LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST"
export const CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST_FAILURE = "CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST_FAILURE"
export const LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST = "LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST"
export const LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST_SUCCESS = "LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST_SUCCESS"
export const LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST_FAILURE = "LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST_FAILURE"
export const LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST_SUCCESS = "LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST_SUCCESS"
export const LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST_FAILURE = "LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST_FAILURE"
export const LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST = "LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST"
export const LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST_SUCCESS = "LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST_SUCCESS"
export const LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST_FAILURE = "LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST_FAILURE"

const sendSubmitContactUsDataRequest = _ => ({
    type: SEND_SUBMIT_CONTACT_DATA_REQUEST
})

const sendSubmitContactUsDataSuccess = _ => ({
    type: SEND_SUBMIT_CONTACT_DATA_REQUEST_SUCCESS
})

const sendSubmitContactUsDataFailure = _ => ({
    type: SEND_SUBMIT_CONTACT_DATA_REQUEST_FAILURE
})

const sendLoadCountriesRequest = () => ({
    type: LOAD_ALL_COUNTRIES
})

const sendLoadCountriesRequestSuccess = () => ({
    type: LOAD_ALL_COUNTRIES_SUCCESS
})

const sendLoadCountriesRequestFailure = () => ({
    type: LOAD_ALL_COUNTRIES_FAILURE
})

const sendLoadStatesRequest = () => ({
    type: LOAD_ALL_STATES
})

const sendLoadStatesRequestSuccess = () => ({
    type: LOAD_ALL_STATES_SUCCESS
})

const sendLoadStatesRequestFailure = () => ({
    type: LOAD_ALL_STATES_FAILURE
})

const sendLoadCitiesRequest = () => ({
    type: LOAD_ALL_CITIES
})

const sendLoadCitiesRequestSuccess = () => ({
    type: LOAD_ALL_CITIES_SUCCESS
})

const sendLoadCitiesRequestFailure = () => ({
    type: LOAD_ALL_CITIES_FAILURE
})

const loadRelatedSuppliersForFindProOrHireRequest = () => ({
    type: LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST
})

const loadRelatedSuppliersForFindProOrHireRequestSuccess = () => ({
    type: LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST_SUCCESS
})

const loadRelatedSuppliersForFindProOrHireRequestFailure = () => ({
    type: LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST_FAILURE
})

const loadRelatedSuppliersForFindProOrHireAsMainRequest = () => ({
    type: LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST
})

const loadRelatedSuppliersForFindProOrHireAsMainRequestSuccess = () => ({
    type: LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST_SUCCESS
})

const loadRelatedSuppliersForFindProOrHireAsMainRequestFailure = () => ({
    type: LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_AS_MAIN_REQUEST_FAILURE
})

const connectOtherSupplierByEmailRequest = () => ({
    type: CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST
})

const connectOtherSupplierByEmailRequestSuccess = () => ({
    type: CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST_SUCCESS
})

const connectOtherSupplierByEmailRequestFailure = () => ({
    type: CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST_FAILURE
})

const fetchAllSupliersCompanyNameRequest = () => ({
    type: LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST
})
const fetchAllSupliersCompanyNameRequestSuccess = () => ({
    type: LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST_SUCCESS
})
const fetchAllSupliersCompanyNameRequestFailure = () => ({
    type: LOAD_ALL_SUPPLIERS_COMPANY_NAME_REQUEST_FAILURE
})

export const supplierContactFormUpdate = (field, value) => ({
    type: SUPPLIER_CONTACT_FORM_UPDATE,
    field,
    value
})

export const retrieveAllCountries = () => dispatch => {
    dispatch(sendLoadCountriesRequest())
    return loadAllCountries().then(data => {
        dispatch(sendLoadCountriesRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendLoadCountriesRequestFailure())
        return error
    })
}

export const retrieveAllStatesByCountry = countryId => dispatch => {
    dispatch(sendLoadStatesRequest())
    return loadAllStates(countryId).then(data => {
        dispatch(sendLoadStatesRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendLoadStatesRequestFailure())
        return error
    })
}

export const retrieveAllCitiesByStateAndCountry = (countryId, stateId) => dispatch => {
    dispatch(sendLoadCitiesRequest())
    return loadAllCities(countryId, stateId).then(data => {
        dispatch(sendLoadCitiesRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendLoadCitiesRequestFailure())
        return error
    })
}

export const submitContactUsData = postData => dispatch => {
    dispatch(sendSubmitContactUsDataRequest())
    return sendSubmitContactUsData(postData).then(data => {
        dispatch(sendSubmitContactUsDataSuccess())
        return data
    }).catch(error => {
        dispatch(sendSubmitContactUsDataFailure())
        return error
    }) 
}

export const fetchRelatedSuppliersForFindProOrHire = (main, categoryName) => dispatch => {
    dispatch(loadRelatedSuppliersForFindProOrHireRequest())
    return loadRelatedSuppliersForFindProOrHire(main, categoryName).then(data => {
        dispatch(loadRelatedSuppliersForFindProOrHireRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(loadRelatedSuppliersForFindProOrHireRequestFailure())
        return error
    })
}

export const fetchRelatedSubServices = mainServiceId => () => fetchRelatedSubServicesService(mainServiceId).then(data => data)

export const connectWithTheSelectedSupplier = (emailToConnect, emailConnectFrom, emailConnectFromName) => (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')
    const supplierEmailMessage = getState().general.supplierEmailMessage
    const postData = {                
        message: supplierEmailMessage
    } 
    dispatch(connectOtherSupplierByEmailRequest())
    return connectOtherSupplierByEmail(emailToConnect, emailConnectFrom, emailConnectFromName, postData, accessToken).then(data => {
        dispatch(connectOtherSupplierByEmailRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(connectOtherSupplierByEmailRequestFailure())
        return error
    })
}

export const fetchFilledSubCategoryIdsForPublic = () => () => loadSupplierFilledSubCategoriesIds().then(data => data)

export const fetchRelatedSuppliersForFindProOrHireAsMainCategory = main => dispatch => {
    dispatch(loadRelatedSuppliersForFindProOrHireAsMainRequest())
    return loadRelatedSuppliersForFindProOrHireAsMain(main).then(data => {
        dispatch(loadRelatedSuppliersForFindProOrHireAsMainRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(loadRelatedSuppliersForFindProOrHireAsMainRequestFailure())
        return error
    })
}

export const fetchAllSupliersCompanyName = () => dispatch => {
    dispatch(fetchAllSupliersCompanyNameRequest())
    return loadAllSupliersCompanyName().then(data => {
        dispatch(fetchAllSupliersCompanyNameRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(fetchAllSupliersCompanyNameRequestFailure())
        return error
    })
}

// export const fetchAllSupliersCompanyName = () => () => loadAllSupliersCompanyName().then(data => data)