import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha"

import { showNotification, hideNotification } from "../../../actions/notificationActions"
import { submitContactUsData } from "../../../actions/generalActions"

import Header from '../../Global/Header'

import { loadCountries } from '../../../utils/misc'

import image from '../../../assets/images/frontpage.jpg'

const DELAY = 1500
const useStyles = theme => ({
    option: {
      fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
})
class Explore extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {            
            email: '',
            phone: '',
            load: false,
            subject: '',
            company: '',
            country: '',
            message: '',
            lastName: '',
            firstName: '',
            expired: false,
            acceptTerms: false,
            recaptchaToken: "",            
            recaptchaToken: "",
            callback: "not fired",  
            errorsInTheForm: true,
            touched: {
                email: false,
                subject: false,
                message: false,
                lastName: false,
                firstName: false,
            },
            errorFields: {
                email: true,
                subject: true,
                message: true,
                lastName: true,
                firstName: true,
            }, 
            loading: false                      
        } 
        this._reCaptchaRef = React.createRef()  
    }

    componentDidMount() {
        setTimeout(() => this.setState({ load: true }), DELAY);
    }

    handleChange = recaptchaToken => {
        this.setState({ recaptchaToken })
        if (recaptchaToken === null) this.setState({ expired: "true" })
    }
    
    asyncScriptOnLoad = () => {
        this.setState({ callback: "called!" })
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef)
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    updateFormHandler = (field, event) => { 
        if (field != "phone") {
            this.setState({ 
                ...this.state,
                [field]: event.target.value, 
                errorFields: {
                    ...this.state.errorFields,
                    [field]: event.target.value === '' ? true : false
                },
            }, () => this.formErrors())    
        } else {
            this.setState({ 
                ...this.state,
                [field]: event, 
                errorFields: {
                    ...this.state.errorFields,
                    [field]: event === '' ? true : false
                },
            }, () => this.formErrors())    
        }        
    }

    formErrors = () => {
        let errors = {}
        const {
            email,
            subject,
            message,
            lastName,
            firstName,
            errorFields,
        } = this.state        
        errors = (!firstName && (errorFields.firstName)) || (!lastName && (errorFields.lastName)) || (!email && (errorFields.email)) || (!subject && (errorFields.subject)) || (!message && (errorFields.message))
        this.setState({ ...this.state, errorsInTheForm: errors })
    }

    handleAutoCompleteChangeWithKey = value => this.setState({ 'country': value })               

    countryToFlag = isoCode => {
        return typeof String.fromCodePoint !== 'undefined'
          ? isoCode
              .toUpperCase()
              .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
          : isoCode
    }

    sendMessage = e => {
        e.preventDefault()
        const {
            email,
            phone,
            company,
            subject,
            message,
            country,
            lastName,
            firstName,
        } = this.state
        const postData = {
            email: email,
            phone: phone,
            company: company,
            subject: subject,
            country: country.label,
            message: message,
            lastName: lastName,
            firstName: firstName,
        }
        if (!this.state.errorsInTheForm) { 
            this.setState({ ...this.state, loading: true })
            this.props.submitContactUsData(postData).then(data => {
                this.setState({
                    email: '',
                    phone: '',
                    country: {},
                    message: '',
                    subject: '',
                    company: '',
                    lastName: '',
                    firstName: '',
                    ...this.state,
                    loading: false,
                })
                if (data === "contactus_query_created_mail_success") {
                    this.props.showNotification('Thanks for your enquiry! We will get back to you soon', 'success')
                    setTimeout(() => {
                        this.props.hideNotification()
                        window.location.href = '/'
                    }, 1000)
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 2000)                
                }
            })  
        }
    }

    render () {

        const { classes } = this.props   
        const { 
            load, 
            email,
            phone,
            loading,
            touched,
            message,
            country,
            company,
            subject,
            lastName,
            firstName,
            errorFields,
            recaptchaToken,
            errorsInTheForm,
        } = this.state
        
        return (
            <>
                <Header />
                <div className="contact-us">
                    <div className={loading ? "overlay" : "overlay hide"}>
                        <div className="profile__loader-container">
                            <div className="loader">
                                <span>Loading...</span>
                            </div>
                        </div>
                    </div>
                    <header className="contact-us__header">Advertise with EventBuzz360</header>
                    <div className="contact-us__main">
                        <div className="contact-us__form-container">
                            <form className="eb-forms eb-forms--contact-us">
                                <div className="eb-forms__form-group">                                
                                    <TextField                                                                                        
                                        fullWidth
                                        rowsMax={2}                                            
                                        margin="small"                                                                                            
                                        name="firstName"
                                        variant="outlined"                                                                                                                                                                                                                     
                                        label={'First Name'} 
                                        placeholder="First Name"
                                        inputProps={{style: {fontSize: 12}}}                                                                                        
                                        onBlur={this.handleBlur('firstName')}    
                                        value={firstName != 'null' ? firstName : ''}
                                        InputLabelProps={{style: {fontSize: 12}}, { required: true }}   
                                        error={((touched.firstName && !firstName) && (errorFields.firstName))}                                                
                                        onChange={(event, newValue) => this.updateFormHandler('firstName', event)}                                                    
                                    />              
                                    {((touched.firstName && !firstName) && (errorFields.firstName)) && <span className="error">Firstname is required!</span>}                                        
                                </div>
                                <div className="eb-forms__form-group">  
                                    <TextField                                                                                        
                                        fullWidth
                                        rowsMax={2}                                            
                                        margin="small"                                                                                            
                                        name="lastName"
                                        variant="outlined"                                                                                                                                                                                                                     
                                        label={'Last Name'} 
                                        placeholder="Last Name"
                                        inputProps={{style: {fontSize: 12}}}                                                                                        
                                        onBlur={this.handleBlur('lastName')}    
                                        value={lastName != 'null' ? lastName : ''}
                                        InputLabelProps={{style: {fontSize: 12}}, { required: true }}   
                                        error={((touched.lastName && !lastName) && (errorFields.lastName))}                                                
                                        onChange={(event, newValue) => this.updateFormHandler('lastName', event)}                                                    
                                    />              
                                    {((touched.lastName && !lastName) && (errorFields.lastName)) && <span className="error">Lastname is required!</span>}                                                                                                         
                                </div>
                                <div className="eb-forms__form-group">
                                    <div className="profile__input-container">
                                        <TextField                                                                                        
                                            fullWidth
                                            rowsMax={2}                                                                                                                                                                                  
                                            name="email"
                                            margin="small"  
                                            label={'Email'} 
                                            variant="outlined"                                                                                                                                                                                                                                                                 
                                            placeholder="Email"
                                            onBlur={this.handleBlur('email')}    
                                            inputProps={{style: {fontSize: 12}}}                                                                                                                                    
                                            value={email != 'null' ? email : ''}
                                            InputLabelProps={{style: {fontSize: 12}}, { required: true }}   
                                            error={((touched.email && !email) && (errorFields.email))}                                                
                                            onChange={(event, newValue) => this.updateFormHandler('email', event)}                                                    
                                        />              
                                        {((touched.email && !email) && (errorFields.email)) && <span className="error">Email address is required!</span>}                                            
                                    </div>
                                </div>
                                <div className="eb-forms__form-group">
                                    <div className="profile__input-container">
                                        <TextField                                                                                        
                                            fullWidth
                                            rowsMax={2}                                                                                                                                                                                  
                                            name="email"
                                            margin="small"                                              
                                            label={'Subject'}                                             
                                            variant="outlined"                                                                                                                                                                                                                                                                                                                                                         
                                            placeholder="Subject"                                            
                                            onBlur={this.handleBlur('subject')}    
                                            inputProps={{style: {fontSize: 12}}}                                                                                                                                    
                                            value={subject != 'null' ? subject : ''}
                                            InputLabelProps={{style: {fontSize: 12}}, { required: true }}   
                                            error={((touched.subject && !subject) && (errorFields.subject))}                                                
                                            onChange={(event, newValue) => this.updateFormHandler('subject', event)}                                                    
                                        />              
                                        {((touched.subject && !subject) && (errorFields.subject)) && <span className="error">Subject is required!</span>}                                         
                                    </div>
                                </div>                                
                                <div className="eb-forms__form-group">                                    
                                    <div className="profile__input-container">
                                        <TextField                                                                                        
                                            fullWidth
                                            rowsMax={2}                                                                                                                                                                                  
                                            name="email"
                                            margin="small"                                              
                                            variant="outlined"                                                                                                                                                                                                                                                                                                             
                                            label={'Company Name'} 
                                            placeholder="Company Name"                                            
                                            onBlur={this.handleBlur('company')}    
                                            inputProps={{style: {fontSize: 12}}}                                                                                                                                    
                                            value={company != 'null' ? company : ''}
                                            InputLabelProps={{style: {fontSize: 12}}}   
                                            onChange={(event, newValue) => this.updateFormHandler('company', event)}                                                    
                                        />              
                                    </div>
                                </div>

                                <div className="eb-forms__form-group">
                                    <div className="profile__input-container">
                                        <PhoneInput
                                            fullWidth
                                            value={phone}
                                            country={'au'}
                                            color="primary"  
                                            variant="outlined"
                                            placeholder="Contact no"                                     
                                            onChange={(event, newValue) => this.updateFormHandler('phone', event)} 
                                        />
                                    </div>
                                </div>
                                <div className="eb-forms__form-group">                                    
                                    <div className="profile__input-container">
                                        <Autocomplete
                                            fullWidth
                                            options={loadCountries()}
                                            classes={{
                                                option: classes.option,
                                            }}
                                            autoHighlight                                                    
                                            getOptionLabel={option => option.label}
                                            value={country != 'null' ? country : ''}
                                            renderOption={option => (
                                                <React.Fragment>
                                                    <span>{this.countryToFlag(option.code)}</span>
                                                    {option.label} ({option.code}) +{option.phone}
                                                </React.Fragment>
                                            )}
                                            InputLabelProps={{style: {fontSize: 12}}}
                                            onChange={(event, newValue) => this.handleAutoCompleteChangeWithKey(newValue)}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Country"                                                            
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                    InputLabelProps={{style: {fontSize: 12}}}
                                                />
                                            )}                                                    
                                        />                                            
                                    </div>
                                </div>
                                <div className="eb-forms__form-group">
                                    <TextField                                                                                        
                                        rows={1}
                                        fullWidth
                                        multiline
                                        rowsMax={2}                                            
                                        margin="small"                                       
                                        name="message"                                                     
                                        label={'Message'}                                                                            
                                        variant="outlined"                                                                                                                                                                                                                                                                                                                                                                                 
                                        onBlur={this.handleBlur('message')}    
                                        inputProps={{style: {fontSize: 12}}}                 
                                        value={message != 'null' ? message : ''}
                                        placeholder="Send us a message. What's on your mind?"
                                        InputLabelProps={{style: {fontSize: 12}}, { required: true }}   
                                        error={((touched.message && !message) && (errorFields.message))}      
                                        onChange={(event, newValue) => this.updateFormHandler('message', event)}                                                    
                                    />              
                                    {((touched.message && !message) && (errorFields.message)) && <span className="error">Message is required!</span>}                                        
                                </div>
                                <div>
                                {load && (
                                    <ReCAPTCHA
                                        ref={this._reCaptchaRef}
                                        onChange={this.handleChange}
                                        style={{ display: "inline-block" }}                                                     
                                        asyncScriptOnLoad={this.asyncScriptOnLoad}           
                                        sitekey={'6LeyatQZAAAAAHS76lrdn3m43EWCgePyHqedpCOB'}                                                                        
                                    />
                                )}
                            </div>                                  
                            </form>
                            <div className="profile__button-container profile__button-container--single">                           
                                <button                                                               
                                    onClick={this.sendMessage}
                                    disabled={errorsInTheForm}
                                    className="btn btn-gold profile__button profile__button-next"                                                                 
                                >SEND</button>                            
                            </div>
                        </div>
                        <div className="contact-us__image-container">
                            <div className="contact-us__image-outer">
                                <span>Follow Us</span>
                                <img src={image} alt="Contact us" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    isLoggedIn: session.isLoggedIn,
})
  
const mapDispatchToProps = dispatch => ({ 
    hideNotification: () => dispatch(hideNotification()),
    submitContactUsData: postData => dispatch(submitContactUsData(postData)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Explore))