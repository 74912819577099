import React, { Component } from 'react'
import Rodal from 'rodal'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { TextField, makeStyles } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import ReCAPTCHA from "react-google-recaptcha"

import { AddStarRating } from '../../Global/AddStarRating'

import profile_image from '../../../assets/images/Person-Icon.png'

import { refactorTheProfileOrCoverImage } from '../../../utils/misc'
import { profilePhotosImagesUploadingPath } from '../../../utils/config'

import './styles/write-review.css'

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const DELAY = 1500

const styles = theme => ({
    cssShrink: {
        transform: 'translate(14px, -6px) scale(0.90) !important',
    },
});

class WriteReview extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            load: false,
            expired: false,
            acceptTerms: false,
            recaptchaToken: "",
            callback: "not fired",
        }
        this._reCaptchaRef = React.createRef()
    }



    componentDidMount() {
        setTimeout(() => this.setState({ load: true }), DELAY);
    }

    handleChange = recaptchaToken => {
        this.setState({ recaptchaToken })
        if (recaptchaToken === null) this.setState({ expired: "true" })
    }

    asyncScriptOnLoad = () => {
        this.setState({ callback: "called!" })
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef)
    }

    acceptTermsAndConditions = () => this.setState(prevState => ({ acceptTerms: !prevState.acceptTerms }))

    render() {
        const { classes } = this.props;
        const {
            load,
            acceptTerms,
            recaptchaToken
        } = this.state

        const {
            logo,
            rating,
            visible,
            touched,
            handleBlur,
            errorFields,
            reviwerEmail,
            handleChange,
            onStarChange,
            submitReview,
            onCloseHandler,
            reviewerCompany,
            projectCostTypes,
            reviewerLastName,
            reviewerJobTitle,
            reviewerContactNo,
            reviewerFirstName,
            reviewerProjectCost,
            reviewerDescription,
            reviewerRelationship,
            reviewerStreetAddress,
            reviewerRelationshipTypes,
            reviewerProjectCompletionYear,
            reviewerProjectCompletionMonth,
            reviewerProjectCompletionYearsList,
            reviewerProjectCompletionMonthsList,
        } = this.props

        return (
            <Rodal className="rodal--write-review" visible={visible} onClose={onCloseHandler} animation={'slideDown'}>
                <header className="rodal__header">
                    <h4 className="rodal__heading">Write a Review</h4>
                </header>
                <div className="supplier-profile__write-review-container">
                    <div className="rodal__main-container">
                        <form className="eb-forms eb-forms--write-review">
                            <div className="write-review__header">
                                <div className="write-review__logo-container">
                                    {logo ? <img src={refactorTheProfileOrCoverImage(logo, profilePhotosImagesUploadingPath)} className="write-review__logo" alt="supplier logo image" /> : <img className="supplier-header__logo--dummy" src={profile_image} />}
                                </div>
                                <div className="write-review__rate">
                                    <p className="profile__section-content-tips overrided__tips-section">* All fields are required!</p>
                                    <AddStarRating stars={rating} onStarChange={onStarChange} />
                                </div>
                            </div>
                            <div className="write-review__main">
                                <div className="eb-forms__form-group">
                                    <TextField
                                        InputLabelProps={{
                                            classes: {
                                                shrink: classes.cssShrink
                                            },
                                        }}
                                        rows={1}
                                        fullWidth
                                        multiline
                                        rowsMax={2}
                                        margin="normal"
                                        variant="outlined"
                                        name="review-description"
                                        label='Review Description'
                                        inputProps={{ style: { fontSize: 12 } }}
                                        onBlur={handleBlur('reviewerDescription')}
                                        onChange={handleChange('reviewerDescription')}
                                        InputLabelProps={{ style: { fontSize: 12 } }, { required: true }}
                                        value={reviewerDescription != 'null' ? reviewerDescription : ''}
                                        error={((touched.reviewerDescription && !reviewerDescription) || (errorFields.reviewerDescription))}
                                        placeholder="Write your review here... help others decide on the right professional. Minimum 30 words"
                                    />
                                    {((touched.reviewerDescription && !reviewerDescription) || (errorFields.reviewerDescription)) && <span className="error">Review Description is required!</span>}
                                </div>
                                <div className="eb-forms__form-group">
                                    <FormControl fullWidth variant="outlined" >
                                        <InputLabel id="relationship-type">Your relationship to the professional</InputLabel>
                                        <Select
                                            style={{ width: 265 }}
                                            labelId="relationship-type"
                                            inputProps={{ style: { fontSize: 12 } }}
                                            label={"Your relationship to the professional"}
                                            value={reviewerRelationship != 'null' ? reviewerRelationship : ''}
                                            textFieldProps={{
                                                label: "Label",
                                                InputLabelProps: reviewerRelationship ? { shrink: true } : {}
                                            }}
                                            onBlur={handleBlur('reviewerRelationship')}
                                            onChange={handleChange('reviewerRelationship')}
                                        >
                                            {reviewerRelationshipTypes.map(eachType => <MenuItem value={eachType.key}>{eachType.text}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="eb-forms--review-not-fullwidth">
                                    <div className="write-review__form-col">
                                        <div className="eb-forms__form-group">
                                            <TextField
                                                fullWidth
                                                margin="small"
                                                variant="outlined"
                                                label={'First Name'}
                                                placeholder="First Name"
                                                inputProps={{ style: { fontSize: 12 } }}
                                                onBlur={handleBlur('reviewerFirstName')}
                                                onChange={handleChange('reviewerFirstName')}
                                                value={reviewerFirstName != 'null' ? reviewerFirstName : ''}
                                                InputLabelProps={{ style: { fontSize: 12 } }, { required: true }}
                                                error={((touched.reviewerFirstName && !reviewerFirstName) || (errorFields.reviewerFirstName))}
                                            />
                                            {((touched.reviewerFirstName && !reviewerFirstName) || (errorFields.reviewerFirstName)) && <span className="error">First name is required!</span>}
                                        </div>
                                    </div>
                                    <div className="write-review__form-col">
                                        <div className="eb-forms__form-group">
                                            <TextField
                                                fullWidth
                                                margin="small"
                                                variant="outlined"
                                                label={'Last Name'}
                                                placeholder="Last Name"
                                                inputProps={{ style: { fontSize: 12 } }}
                                                onBlur={handleBlur('reviewerLastName')}
                                                onChange={handleChange('reviewerLastName')}
                                                value={reviewerLastName != 'null' ? reviewerLastName : ''}
                                                InputLabelProps={{ style: { fontSize: 12 } }, { required: true }}
                                                error={((touched.reviewerLastName && !reviewerLastName) || (errorFields.reviewerLastName))}
                                            />
                                            {((touched.reviewerLastName && !reviewerLastName) || (errorFields.reviewerLastName)) && <span className="error">Last name is required!</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="eb-forms--review-not-fullwidth">
                                    <div className="write-review__form-col">
                                        <div className="eb-forms__form-group">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="completion-date">{'Project Completion Month'}</InputLabel>
                                                <Select
                                                    labelId="completion-date"
                                                    label={"Project Completion Month"}
                                                    inputProps={{ style: { fontSize: 12 } }}
                                                    value={reviewerProjectCompletionMonth != 'null' ? reviewerProjectCompletionMonth : ''}
                                                    textFieldProps={{
                                                        label: "Label",
                                                        InputLabelProps: reviewerProjectCompletionMonth ? { shrink: true } : {}
                                                    }}
                                                    onBlur={handleBlur('reviewerProjectCompletionMonth')}
                                                    onChange={handleChange('reviewerProjectCompletionMonth')}
                                                >
                                                    {reviewerProjectCompletionMonthsList.map(eachType => <MenuItem value={eachType.key}>{eachType.text}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="write-review__form-col">
                                        <div className="eb-forms__form-group">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="completion-year">{'Project Completion Year'}</InputLabel>
                                                <Select
                                                    labelId="completion-year"
                                                    label={"Project Completion Year"}
                                                    inputProps={{ style: { fontSize: 12 } }}
                                                    value={reviewerProjectCompletionYear != 'null' ? reviewerProjectCompletionYear : ''}
                                                    textFieldProps={{
                                                        label: "Label",
                                                        InputLabelProps: reviewerProjectCompletionYear ? { shrink: true } : {}
                                                    }}
                                                    onBlur={handleBlur('reviewerProjectCompletionYear')}
                                                    onChange={handleChange('reviewerProjectCompletionYear')}
                                                >
                                                    {reviewerProjectCompletionYearsList.map(eachType => <MenuItem value={eachType.key}>{eachType.text}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="eb-forms--review-not-fullwidth">
                                    <div className="eb-forms__form-group">
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="review-cost">{'Approximate Project or Purchase Cost'}</InputLabel>
                                            <Select
                                                style={{ width: 265 }}
                                                labelId="review-cost"
                                                inputProps={{ style: { fontSize: 12 } }}
                                                label={"Approximate Project or Purchase Cost"}
                                                value={reviewerProjectCost != 'null' ? reviewerProjectCost : ''}
                                                textFieldProps={{
                                                    label: "Label",
                                                    InputLabelProps: reviewerProjectCost ? { shrink: true } : {}
                                                }}
                                                onBlur={handleBlur('reviewerProjectCost')}
                                                onChange={handleChange('reviewerProjectCost')}
                                            >
                                                {projectCostTypes.map(eachType => <MenuItem value={eachType.key}>{eachType.text}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="write-review__verification">
                                    <p>Verification (not publicly shown)</p>
                                    <div className="eb-forms__form-group eb-forms__form-group--write-review">
                                        <TextField
                                            fullWidth
                                            margin="small"
                                            variant="outlined"
                                            label={'Your Company'}
                                            placeholder="Your Company"
                                            inputProps={{ style: { fontSize: 12 } }}
                                            onBlur={handleBlur('reviewerCompany')}
                                            InputLabelProps={{ style: { fontSize: 12 } }}
                                            onChange={handleChange('reviewerCompany')}
                                            value={reviewerCompany != 'null' ? reviewerCompany : ''}
                                        />
                                    </div>
                                    <div className="eb-forms__form-group eb-forms__form-group--write-review">
                                        <TextField
                                            fullWidth
                                            margin="small"
                                            variant="outlined"
                                            label={'Your Job Title'}
                                            placeholder="Your Job Title"
                                            inputProps={{ style: { fontSize: 12 } }}
                                            onBlur={handleBlur('reviewerJobTitle')}
                                            InputLabelProps={{ style: { fontSize: 12 } }}
                                            onChange={handleChange('reviewerJobTitle')}
                                            value={reviewerJobTitle != 'null' ? reviewerJobTitle : ''}
                                        />
                                    </div>
                                    <div className="eb-forms__form-group eb-forms__form-group--write-review">
                                        <TextField
                                            fullWidth
                                            margin="small"
                                            variant="outlined"
                                            label={'Your Email'}
                                            placeholder="Your Email"
                                            inputProps={{ style: { fontSize: 12 } }}
                                            onBlur={handleBlur('reviwerEmail')}
                                            onChange={handleChange('reviwerEmail')}
                                            value={reviwerEmail != 'null' ? reviwerEmail : ''}
                                            InputLabelProps={{ style: { fontSize: 12 } }, { required: true }}
                                            error={((touched.reviwerEmail && !reviwerEmail) || (errorFields.reviwerEmail))}
                                        />
                                        {((touched.reviwerEmail && !reviwerEmail) || (errorFields.reviwerEmail)) && <span className="error">Email address is required!</span>}
                                    </div>
                                    <div className="eb-forms__form-group eb-forms__form-group--write-review">
                                        <TextField
                                            fullWidth
                                            margin="small"
                                            variant="outlined"
                                            label={'Contact No'}
                                            inputProps={{ style: { fontSize: 12 } }}
                                            onBlur={handleBlur('reviewerContactNo')}
                                            onChange={handleChange('reviewerContactNo')}
                                            value={reviewerContactNo != 'null' ? reviewerContactNo : ''}
                                            InputLabelProps={{ style: { fontSize: 12 } }, { required: true }}
                                            placeholder="Enter project street name, locally or full address (will not be shared)"
                                            error={((touched.reviewerContactNo && !reviewerContactNo) || (errorFields.reviewerContactNo))}
                                        />
                                        {((touched.reviewerContactNo && !reviewerContactNo) || (errorFields.reviewerContactNo)) && <span className="error">Contact no is required!</span>}
                                    </div>
                                    <div className="eb-forms__form-group eb-forms__form-group--write-review">
                                        <TextField
                                            fullWidth
                                            margin="small"
                                            variant="outlined"
                                            label={'Project location'}
                                            inputProps={{ style: { fontSize: 12 } }}
                                            onBlur={handleBlur('reviewerStreetAddress')}
                                            onChange={handleChange('reviewerStreetAddress')}
                                            InputLabelProps={{ style: { fontSize: 12 } }, { required: true }}
                                            value={reviewerStreetAddress != 'null' ? reviewerStreetAddress : ''}
                                            placeholder="Enter project street name, locally or full address (will not be shared)"
                                            error={((touched.reviewerStreetAddress && !reviewerStreetAddress) || (errorFields.reviewerStreetAddress))}
                                        />
                                        {((touched.reviewerStreetAddress && !reviewerStreetAddress) || (errorFields.reviewerStreetAddress)) && <span className="error">Project location is required!</span>}
                                    </div>
                                </div>
                                <div className="eb-forms__form-group eb-forms__form-group--review-terms">
                                    <div className="profile__supplier-subcategories eb-checkbox">
                                        <input
                                            id='terms'
                                            type='checkbox'
                                            onChange={this.acceptTermsAndConditions}
                                        />
                                        <span className="checkmark"></span>
                                        <label htmlFor='terms'>I confirm that the information here is true and accurate. I confirm that I do not work for, am not in competition with and am not related to this service provider</label>
                                    </div>
                                </div>
                                <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                            </div>
                            <div>
                                {load && (
                                    <ReCAPTCHA
                                        ref={this._reCaptchaRef}
                                        onChange={this.handleChange}
                                        style={{ display: "inline-block" }}
                                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                                        sitekey={'6LeyatQZAAAAAHS76lrdn3m43EWCgePyHqedpCOB'}
                                    />
                                )}
                            </div>
                            <div className="rodal__btn-container">
                                <input
                                    type="button"
                                    value="Submit"
                                    onClick={submitReview}
                                    className="btn btn-gold image-crop-complete"
                                    disabled={
                                        !acceptTerms ||
                                        !rating ||
                                        !reviewerDescription ||
                                        !reviewerFirstName ||
                                        !reviewerLastName ||
                                        !reviewerRelationship ||
                                        !reviewerProjectCompletionMonth ||
                                        !reviewerProjectCompletionYear ||
                                        !reviewerProjectCost ||
                                        !reviwerEmail ||
                                        !reviewerStreetAddress ||
                                        !reviewerContactNo ||
                                        !recaptchaToken
                                    }
                                />
                            </div>
                        </form>
                    </div>
                    <div className="profile__tips-section write-review__tips-section">
                        <div className="write-review__tips-container">
                            <header className="write-review__tips-header">
                                <h5>Review Quality</h5>
                            </header>
                            <ul className="write-review__tips-list">
                                <li className="write-review__tips-list-item">20+ Words</li>
                                <li className="write-review__tips-list-item">Project date and cost</li>
                                <li className="write-review__tips-list-item">Project photos</li>
                            </ul>
                            <p className="write-review__tip-description tip-text__container">When writing a review, consider things like the Pro's professionalism, knowledge, work quality and the ability to meet deadlines and budget</p>
                        </div>
                    </div>
                </div>
            </Rodal>
        )
    }
}

WriteReview.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WriteReview);