import React, { Component } from 'react'

import loader from  '../../assets/images/lazy_loader.gif'

export class LazyLoad extends Component {
     
    constructor(props) {
        super(props)
        this.loadTimer = null
        this.image = new Image()
    }    
  
    componentDidMount() {
        const { loaderIcon } = this.props
        this.image.src = loaderIcon ? loaderIcon : loader
        this.image.addEventListener("load", this.onImgLoaded)
        window.onscroll = window.addEventListener("scroll", this.lazyLoad)
    }
  
    componentWillUnmount() {
        window.removeEventListener("scroll", this.lazyLoad)
    }
  
    onImgLoaded = () => {
        if (this.loadTimer !== null) {
            clearTimeout(this.loadTimer)
        }
        if (!this.image.complete) {
            this.loadTimer = setTimeout(() => this.onImgLoaded(), 1)
        } else {
            this.lazyLoad()
        }
    }
  
    lazyLoad = e => {
        Object.values(this.refs).forEach(el => {
            if (this.elementInViewPort(el)) {
                const clientRect = el.getBoundingClientRect()
                el.setAttribute("src", el.getAttribute("data-src"))
                el.setAttribute("class", el.getAttribute("data-class"))
            }
        })
    }
  
    elementInViewPort(el) {
        const clientRect = el.getBoundingClientRect()
        return (
            clientRect.top >= 0 && clientRect.top <= (window.innerHeight || document.documentElement.clientHeight) || clientRect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        )
    }
  
    render() {

      const { src, style, alter } = this.props
      return (
            <img 
                alt={alter}                
                src={loader}
                ref={"image"}
                data-src={src}
                data-class={style}
                className="lazyload"                
            />
        )
    }
}