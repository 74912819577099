import React, { Component } from 'react'

export class ProfileProgress extends Component {
  
  state = {
    sections: [
      { id: 1, section: "Business Overview", completed: true, url: "/profile" },
      { id: 2, section: "Contact Information", completed: false, url: "/profile" },
      { id: 3, section: "Add social media", completed: false, url: "/profile" },
      { id: 4, section: "Supplier Categories", completed: false, url: "/services" },
      { id: 5, section: "Locations you service", completed: false, url: "/locations" },
      { id: 6, section: "References", completed: false, url: "/my-reviews" },
      { id: 7, section: "Upload Photos", completed: false, url: "/add-event" }
    ]
  }

  render () {

    const { sections } = this.state

    return (
      <div className="header__popover-content eb-forms">
      {
        sections.map(each => (
          <div className={each.completed ? "header__popover-check header__popover-check--checked eb-checkbox" : "header__popover-check eb-checkbox" } key={each.id}>
              <input 
                  type='checkbox' 
                  id={each.id}                                                               
              />
              <span className="checkmark"></span>
              <a className="header__popover-link" href={each.url}>{each.section}</a>
          </div>
        ))
      }
      </div>
    )
  }
}