import React from 'react';
import * as yup from 'yup';

export const ContactSupplierValidationSchema = yup.object({
    full_name: yup
        .string('Enter your Full Name')
        .required('Full Name is required'),
    email: yup
        .string('Enter your Email')
        .required('Email is required'),
    phone: yup
        .string('Enter your Phone'),
    sct_event_type: yup
        .string('Select Event Type')
        .required('Select Event Type'),
    sct_guest_count: yup
        .string('Select Guest Count')
        .required('Select Guest Count'),
    preferredDate: yup
        .date()
        .nullable()
        .required("Select preferred Date"),
    message: yup
        .string('Enter your message')
        .required('Message is required'),
    networkChecked: yup.boolean(),
    // .required("The terms and conditions must be accepted.")
    // .oneOf([true], "The terms and conditions must be accepted.")
});
