import React, { Component } from 'react'
import { connect } from "react-redux"
import arrayMove from "array-move"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import Gallery from "react-photo-gallery"
import { DndProvider } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import TouchBackend from "react-dnd-touch-backend"
import cuid from "cuid"
import { TextField } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { retrieveAllEventTypes } from '../../actions/galleryActions'
import { checkLoginStatus } from '../../actions/sessionActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"
import {
    removeCurrentEvent,
    removeSelectedImage,
    processSupplierUpdateEvent,
    profileSupplierImageFolders,
    handleUploadNewPhotosUpdateEvent,
    retrieveSupplierEventInformation,
    loadSubCategoriesForParentCategory,
    updateTheOrderForTheExistingImages,
    retrieveAllSupplierBusinessInformation,
    profileSupplierImageFoldersFetchPhotos,
    supplierProfileEventInformationFormUpdate
} from '../../actions/profileActions'

import { LazyLoad } from '../../reusables/LazyLoad/LazyLoad'
import PhotosOrdering from '../../reusables/PhotosOrdering/PhotosOrdering'
import ImageList from '../../reusables/ImageList/ImageList'
import Dropzone from '../../reusables/Dropzone/Dropzone'
import Header from '../Global/Header'

import { isTouchDevice } from '../../utils/misc'

import multiple from '../../assets/images/icons/multiple.png'
import arrowIcon from '../../assets/images/icons/arrow_black.svg'

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend
const SortablePhoto = SortableElement(item => <PhotosOrdering {...item} />)
const SortableGallery = SortableContainer(({ items, removeImage }) => <Gallery photos={items} renderImage={props => <SortablePhoto removeImage={removeImage} items={items} {...props} />} />)
class MyFolders extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
        this.state = {
            folders: [],
            eventId: null,
            formUpdated: false,
            errorFields: {
                eventTypeId: false,
                eventLocation: false,
                imagesAppearIn: true
            },
            touched: {
                eventTypeId: false,
                eventLocation: false,
                imagesAppearIn: false
            },
            suggestions: [],
            otherCredits: "",
            allEventTypes: [],
            eventLocation: "",
            imagesAppearIn: "",
            selectedImages: [],
            selectedFolder: {},
            eventDescription: "",
            eventInformation: {},
            inspectFolder: false,
            errorsInTheForm: true,
            folderInsideImages: [],
            current_time_stamp: "",
            imageSetReordered: false,
            loggedInRealStatus: true,
            selectedImagesPreviews: [],
            imagesCurrentCountUploaded: [],
            photosUploadingInProcess: false,
            projectBudgetTypes: [
                {
                    "key": "0-20",
                    "text": "under $20,000"
                },
                {
                    "key": "20-30",
                    "text": "$20,000-$30,000"
                },
                {
                    "key": "30-50",
                    "text": "$20,000-$50,000"
                },
                {
                    "key": "50-100",
                    "text": "over $50,000"
                }
            ]
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ overlay: false })
            this.myRef.current.scrollIntoView({ behavior: 'smooth', block: "end" })
        }, 100)
        this.props.checkLoginStatus().then(data => {
          console.log(data)
            if (data) {
                this.props.profileSupplierImageFolders().then(folders => {
                    folders.map(folder => {
                        this.props.profileSupplierImageFoldersFetchPhotos(folder.event_id).then(imageSet => {
                            this.props.retrieveSupplierEventInformation(folder.event_id).then(eventInfo => {
                                const eachFolder = eventInfo[0]
                                eachFolder.images = imageSet
                                eachFolder.date = folder.created_at
                                eachFolder.current_time_stamp = folder.current_time_stamp
                                this.setState({
                                    ...this.state,
                                    folders: [...this.state.folders, eachFolder]
                                })
                            })
                        })
                    })
                })
                this.props.retrieveAllEventTypes().then(allEventTypes => this.setState({ allEventTypes }))
            }
        })
    }

    componentWillUnmount() {
        this.setState({ selectedImages: [], photosUploadingInProcess: false, imagesCurrentCountUploaded: [] })
    }

    onDrop = acceptedFiles => {
        acceptedFiles.map((file, i) => {
            const fileSize = (file.size / (1024 * 1024))
            const reader = new FileReader()
            reader.readAsDataURL(file)
            if (fileSize <= 5) {
                reader.onload = e => {
                    const image = new Image
                    image.src = reader.result
                    image.onload = () => {
                        const imageObjectForSubmit = {
                            id: cuid(),
                            src: file,
                            current_order: this.state.folderInsideImages.length + (i + 1),
                            width: image.width,
                            height: image.height,
                        }
                        const imageObjectPreview = {
                            id: cuid(),
                            current_order: this.state.folderInsideImages.length + (i + 1),
                            src: reader.result
                        }
                        this.setState({
                            notToProceedTheForm: false,
                            selectedImages: this.state.selectedImages.concat(imageObjectForSubmit),
                            selectedImagesPreviews: this.state.selectedImagesPreviews.concat(imageObjectPreview)
                        })
                    }
                }                
            } else {
                this.props.showNotification('The selected image is not compatible with the file uploading size / dimentions!', 'error')
                setTimeout(() => this.props.hideNotification(), 1500)                        
            }
        })
    }

    removeImage = (i, items) => {
        if (items.length <= 1) {
            this.props.showNotification('You need to keep at least one image with your event!', 'error')
            setTimeout(() => this.props.hideNotification(), 1500)                        
        } else {
            this.props.removeSelectedImage(items[i].image_id, this.state.eventId).then(() => {
                const modifiedArr = items.filter((item, n) => n !== i)
                this.setState({ folderInsideImages: modifiedArr })
                this.props.showNotification('Your event information have been updated!', 'success')
                setTimeout(() => this.props.hideNotification(), 6000)
            })
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { folderInsideImages } = this.state
        const updatedImagesSet = arrayMove(folderInsideImages, oldIndex, newIndex)
        const modifiedImageSet = updatedImagesSet.map((eachImage, i) => {
            eachImage.order = i + 1
            return eachImage
        })
        this.setState({ folderInsideImages: modifiedImageSet, imageSetReordered: true })
    }

    inspectFolder = folderId => {
        const selectedFolder = this.state.folders.find(x => x.id == folderId)
        this.setState({
            eventId: folderId,
            folderInsideImages: selectedFolder.images,
            current_time_stamp: selectedFolder.current_time_stamp,
            eventDescription: selectedFolder && selectedFolder.event_description,
            eventTypeId: selectedFolder && selectedFolder.event_type_id,
            eventLocation: selectedFolder && selectedFolder.event_location,
            imagesAppearIn: selectedFolder && selectedFolder.photos_appear_in,
            otherCredits: selectedFolder && selectedFolder.supplier_credits,
        })
        this.imagesAppearance(selectedFolder.photos_appear_in)
        this.props.retrieveAllSupplierBusinessInformation().then(supplierInfo => {
            if (supplierInfo !== null) {
                this.setState({ serviceType: supplierInfo.supplier_type_id, inspectFolder: true })
            }
        })
    }

    handleFileUpload = selectedImages => {
        selectedImages.map((eachFile, i) => {
            const reader = new FileReader()
            reader.readAsDataURL(eachFile)
            reader.onload = () => {
                const image = new Image
                image.src = reader.result
                image.onload = () => {
                    const imageObject = {
                        src: reader.result,
                        width: image.width,
                        height: image.height,
                        current_order: i
                    }
                    this.setState({
                        notToProceedTheForm: false,
                        selectedImages: this.state.selectedImages.concat(eachFile),
                        selectedImagesPreviews: this.state.selectedImagesPreviews.concat(imageObject)
                    })
                }
            }
        })
    }

    removeSelectedImages = index => {
        const { selectedImages } = this.state
        if (selectedImages && selectedImages.length > 0) {
            this.setState(prev => ({
                selectedImages: prev.selectedImages.filter((el, i) => i !== index),
                selectedImagesPreviews: prev.selectedImagesPreviews.filter((el, i) => i !== index),
            }))
        }
    }

    updateFormHandler = field => event => {
        if ((event) && (event.target) && (event.target.value)) {
            this.setState({ [field]: event.target.value })
            this.props.supplierProfileEventInformationFormUpdate([field], event.target.value)
        }
    }

    handleBlur = field => event => this.setState({
        touched: {
            ...this.state.touched,
            [field]: true
        },
        errorFields: {
            ...this.state.errorFields,
            [field]: (!event.target.value) ? true : false
        },
        errorsInTheForm: (!event.target.value) ? true : false
    })

    imagesAppearance = field => {
        if (field === 'all') {
            this.setState({ imagesAll: true, imagesProfile: true, imagesAppearIn: 'all', errorFields: {...this.state.errorFields, imagesAppearIn: false } })
        } else {
            this.setState({ imagesAll: false, imagesProfile: true, imagesAppearIn: 'profile', errorFields: {...this.state.errorFields, imagesAppearIn: false } })
        }
    }

    removeEvent = () => {
        const { eventId, current_time_stamp } = this.state
        this.props.removeCurrentEvent(eventId, current_time_stamp).then(data => {
            this.props.showNotification('Your event have been removed!.', 'success')
            setTimeout(() => window.location.href = "/my-folders", 1500)
        })
    }

    saveAndContinue = () => {
        let postData = {}
        let notToProceedTheForm = false
        const {
            eventId,
            eventTypeId,
            otherCredits,
            eventLocation,
            imagesAppearIn,
            selectedImages,
            eventDescription,
            imageSetReordered,
            folderInsideImages,
            current_time_stamp
        } = this.state
        if (imagesAppearIn !== 'profile') {
            if ((eventLocation === '') || (eventTypeId === null) || (imagesAppearIn === '')) {
                notToProceedTheForm = true
            } else {
                postData = {
                    eventTypeId: eventTypeId,
                    otherCredits: otherCredits,
                    eventLocation: eventLocation,
                    imagesAppearIn: imagesAppearIn,
                    eventDescription: eventDescription,
                    imageCount: (selectedImages && selectedImages.length !== 0) ? selectedImages.length : 0,
                }
            }
        } else {
            if ((eventLocation === '') || (imagesAppearIn === '')) {
                notToProceedTheForm = true
            } else {
                postData = {
                    otherCredits: otherCredits,
                    eventLocation: eventLocation,
                    imagesAppearIn: imagesAppearIn,
                    eventDescription: eventDescription,
                    imageCount: (selectedImages && selectedImages.length !== 0) ? selectedImages.length : 0,
                }
            }
        }
        if (!notToProceedTheForm) {
            if (imageSetReordered) {
                this.props.updateTheOrderForTheExistingImages(eventId, folderInsideImages).then(() => this.setState({ imageSetReordered: false }))
            }
            this.props.processSupplierUpdateEvent(eventId, postData, selectedImages).then(data => {
                if (data.status === "supplier_event_information_updated") {
                    if (selectedImages && selectedImages.length > 0) {
                        this.props.showNotification('Your event information have been updated! Please wait while we upload the photos.', 'success')
                    } else {
                        this.props.showNotification('Your event information have been updated!', 'success')
                    }
                    setTimeout(() => this.props.hideNotification(), 6000)
                    this.setState({
                        touched: {
                            eventTypeId: false,
                            eventLocation: false,
                            imagesAppearIn: false
                        },
                        supplier: "",
                        formUpdated: false,
                        notToProceedTheForm: true,
                        photosUploadingInProcess: true
                    })
                    if (selectedImages && selectedImages.length > 0) {
                        selectedImages.map((eachFile, i) => {
                            this.props.handleUploadNewPhotosUpdateEvent(eachFile.src, eachFile.current_order, eachFile.id, eachFile.width, eachFile.height, eventId, eventTypeId, current_time_stamp).then(data => {
                                if ((data) && (data.imageUrl) && (data.imageUrl !== '') && (data.imageUrl !== null)) {
                                    this.setState({ imagesCurrentCountUploaded: this.state.imagesCurrentCountUploaded.concat(data.imageurl) })
                                }
                                if (selectedImages.length - 1 === i) {
                                    setTimeout(() => window.location.href = "/my-folders", 3000)
                                }
                            })
                        })
                    } else {
                        setTimeout(() => window.location.href = "/my-folders", 6000)
                    }
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 6000)
                }
            })
        }
    }

    goBack = () => this.setState({ inspectFolder: false, selectedFolder: {} })

    render() {

        const { loading } = this.props

        const {
            folders,
            touched,
            imagesAll,
            eventTypeId,
            errorFields,
            otherCredits,
            imagesProfile,
            inspectFolder,
            eventLocation,
            allEventTypes,
            selectedImages,
            imagesAppearIn,
            eventDescription,
            folderInsideImages,
            selectedImagesPreviews,
            photosUploadingInProcess,
            imagesCurrentCountUploaded
        } = this.state

        const allYears = []
        const maxOffset = 10
        let notToProceedTheForm = false
        const thisYear = (new Date()).getFullYear()

        if (!eventLocation || !imagesAppearIn || !eventDescription) {
            notToProceedTheForm = true
        }
        if (imagesAppearIn === 'all' && eventTypeId === null) {
            notToProceedTheForm = true
        }

        for (let x = 0; x <= maxOffset; x++) {
            allYears.push(thisYear - x)
        }

        return (
            <>
                <Header />   
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>
                {(photosUploadingInProcess) &&
                <div className={(imagesCurrentCountUploaded.length !== selectedImages.length) ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Please wait while we upload the photos...</span></div>
                    </div>
                </div>}
                <div ref={this.myRef} className="profile__my-folders">
                    <div className="profile__section">
                        {!inspectFolder &&
                            <div className="profile__folder-container">
                            {
                                (folders && folders.length > 0) && folders
                                .sort((a, b) => parseInt(a.id) - parseInt(b.id)).reverse()
                                .map(folder =>
                                    (
                                        <div
                                            key={folder.id}
                                            className="profile__folders"
                                            onClick={() => this.inspectFolder(folder.id)}
                                        >
                                            <div className="profile__folders-img-container">
                                                {folder.images != null && <LazyLoad src={folder && folder.images.map && folder.images[0].src} style="" alter="folder" />}
                                                {folder.images != null && folder.images.length > 1 && (
                                                    <div className="profile__folders-img--multiple">
                                                        <img className="" src={multiple} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="profile__folders-text-container">
                                                <pre className="profile__folders-date">{folder.date}</pre>
                                                <em className="profile__folders-location">Location: {folder.event_location}</em>
                                                <p className="profile__folders-description">{folder.event_description}</p>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>}
                        {inspectFolder &&
                        (
                            <>
                                <div>
                                    <img onClick={this.goBack} className="arrowBack" src={arrowIcon} />
                                </div>
                                <h5 className="profile__sub-title">View/Edit photos of an event</h5>
                                <div className="profile__section-content">
                                    <div className="profile__form-container">
                                        <div className="eb-forms eb-forms--profile">
                                            <div className="eb-forms__form-group">
                                                <div className="appear-in-container">
                                                    <div className="appear-in-btn-container">
                                                        <button
                                                            onBlur={this.handleBlur('imagesAppearIn')}
                                                            onClick={() => this.imagesAppearance('all')}
                                                            className={imagesAll ? "btn btn-appear-in active" : "btn btn-appear-in"}
                                                        >Image Gallery</button>
                                                        <button className={imagesProfile ? "btn btn-appear-in active" : "btn btn-appear-in"} onClick={() => this.imagesAppearance('profile')} value="profile">My profile page</button>
                                                    </div>
                                                    {/* {imagesAll && <div className="appear-in-help">
                                                        <p>Images in the gallery will automatically be uploaded to profile page and need to be approved by Eventbuzz360</p>
                                                    </div> } */}
                                                </div>
                                            </div>
                                            <div className="add__events-internal-wrapper">
                                                <div className="eb-forms__form-group">
                                                    <label>Uploaded Images</label>
                                                    {folderInsideImages && folderInsideImages.length > 0 ? <SortableGallery items={folderInsideImages} removeImage={this.removeImage} onSortEnd={this.onSortEnd} axis={"xy"} /> : <p>{'No uploaded images!'}</p>}
                                                </div>
                                                <label className="fullwidth">Add more Event Images <span className="required__indicator">*</span></label>
                                                <div className="eb-forms__form-group eb-forms__form-group--photo-upload">
                                                    <div className="dropzoneContainer">
                                                        <Dropzone onDrop={this.onDrop} accept={"image/*"} />                                                                                                                     
                                                    </div>
                                                </div>
                                                <span className="profile__section-content-tips">* Maximum file upload: 5MB (JPG / PNG)</span>
                                                {selectedImagesPreviews && selectedImagesPreviews.length > 0 && (
                                                    <h6 className="text-center">Drag the Images to change positions</h6>
                                                )}
                                                <DndProvider backend={backendForDND}>
                                                    <ImageList images={selectedImagesPreviews} moveImage={this.moveImage} removeImage={this.removeSelectedImages} />
                                                </DndProvider>
                                                {imagesAppearIn === 'all' &&
                                                <div className="eb-forms__form-group leftMarginEmpty">                                                    
                                                    <div className="eb-forms--not-fullwidth">
                                                        <FormControl fullWidth variant="outlined">
                                                            <InputLabel id="eventType">{'Event Type'}</InputLabel>
                                                            <Select                                                                                                                                                              
                                                                value={eventTypeId}                                                               
                                                                labelId="eventType"              
                                                                label={"Event Type"}   
                                                                style={{ width: 300 }}                                                    
                                                                inputProps={{style: {fontSize: 12}}}                                                                                                                                                                                                                                                             
                                                                InputLabelProps={{style: {fontSize: 12}}}                                                                
                                                                onChange={this.updateFormHandler('eventTypeId')}                                                            
                                                            >
                                                                {allEventTypes.map(eventType => <MenuItem value={eventType.id}>{eventType.event_type_name}</MenuItem>)}
                                                            </Select>
                                                        </FormControl>                                                    
                                                    </div>
                                                </div>
                                                }
                                                <div className="eb-forms__form-group">
                                                    <TextField                                                                                        
                                                        rows={1}
                                                        fullWidth
                                                        multiline
                                                        rowsMax={2}                                            
                                                        margin="small"        
                                                        variant="outlined"                                                 
                                                        name="eventDescription"                                                                                                                                                                     
                                                        label="Event Description"                                                 
                                                        inputProps={{style: {fontSize: 12}}}               
                                                        onBlur={this.handleBlur('eventDescription')}                                                                    
                                                        placeholder="Write a great event image description"
                                                        onChange={this.updateFormHandler('eventDescription')}                                                              
                                                        value={eventDescription != 'null' ? eventDescription : ''}
                                                        InputLabelProps={{style: {fontSize: 12}}, { required: true }}
                                                        error={((touched.eventDescription && !eventDescription) || (errorFields.eventDescription))}                                                                                                                                                                                                                           
                                                    />                                            
                                                </div>
                                                <div className="eb-forms__form-group">                                            
                                                    <div className="profile__input-container">
                                                        <TextField
                                                            fullWidth
                                                            margin="small"                                                
                                                            variant="outlined"                                                     
                                                            label="Event Location"   
                                                            inputProps={{style: {fontSize: 12}}}                                                                                                
                                                            onBlur={this.handleBlur('eventLocation')}
                                                            onChange={this.updateFormHandler('eventLocation')}
                                                            value={eventLocation != 'null' ? eventLocation : ''}                                                                                                                        
                                                            InputLabelProps={{style: {fontSize: 12}}, { required: true }}
                                                            error={((touched.eventLocation && !eventLocation) || (errorFields.eventLocation))}   
                                                        />                                                
                                                    </div>
                                                </div>
                                                <div className="eb-forms__form-group">
                                                    <TextField
                                                        fullWidth
                                                        margin="small"                                                
                                                        variant="outlined"                                                     
                                                        label="Other Supplier Credits"   
                                                        inputProps={{style: {fontSize: 12}}}     
                                                        placeholder={'Other Supplier Credits'}                                                                                           
                                                        onBlur={this.handleBlur('otherCredits')}                                                
                                                        InputLabelProps={{style: {fontSize: 12}}}
                                                        onChange={this.updateFormHandler('otherCredits')}
                                                        value={otherCredits != 'null' ? otherCredits : ''}                                                                                                                                                                        
                                                    />                                             
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile__button-container buttonLocationRight">
                                    <button className="btn btn-gold profile__button profile__button-next overrided-style__next_btn" onClick={this.removeEvent}>Delete Event</button>
                                    <button
                                        disabled={notToProceedTheForm}
                                        onClick={() => this.saveAndContinue()}
                                        className="btn btn-gold profile__button profile__button-next"
                                    >Save</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierEventInformation.formUpdated
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    hideNotification: () => dispatch(hideNotification()),
    retrieveAllEventTypes: () => dispatch(retrieveAllEventTypes()),
    profileSupplierImageFolders: () => dispatch(profileSupplierImageFolders()),
    removeSelectedImage: (imageId, eventId) => dispatch(removeSelectedImage(imageId, eventId)),
    retrieveAllSupplierBusinessInformation: () => dispatch(retrieveAllSupplierBusinessInformation()),
    retrieveSupplierEventInformation: eventId => dispatch(retrieveSupplierEventInformation(eventId)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    profileSupplierImageFoldersFetchPhotos: eventId => dispatch(profileSupplierImageFoldersFetchPhotos(eventId)),
    loadSubCategoriesForParentCategory: parentCatId => dispatch(loadSubCategoriesForParentCategory(parentCatId)),
    removeCurrentEvent: (event_id, current_time_stamp) => dispatch(removeCurrentEvent(event_id, current_time_stamp)),
    supplierProfileEventInformationFormUpdate: (field, value) => dispatch(supplierProfileEventInformationFormUpdate(field, value)),
    processSupplierUpdateEvent: (eventId, postData, selectedImages) => dispatch(processSupplierUpdateEvent(eventId, postData, selectedImages)),
    updateTheOrderForTheExistingImages: (event_id, folderInsideImages) => dispatch(updateTheOrderForTheExistingImages(event_id, folderInsideImages)),
    handleUploadNewPhotosUpdateEvent: (image, order, id, width, height, eventId, eventTypeId, folderId) => dispatch(handleUploadNewPhotosUpdateEvent(image, order, id, width, height, eventId, eventTypeId, folderId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyFolders)
