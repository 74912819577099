import React, { Component } from 'react'

import { StarRating } from '../../Global/StarRating'
import WriteReview from './WriteReview'

import { profilePhotosImagesUploadingPath } from '../../../utils/config'
import { refactorTheProfileOrCoverImage } from '../../../utils/misc'

import profile_image from '../../../assets/images/Person-Icon.png'

export default class Reviews extends Component {

    render() { 

        const { 
            logo, 
            rating,
            reviews, 
            touched,
            handleBlur,
            errorFields,
            acceptTerms,            
            reviwerEmail,
            submitReview,
            onStarChange,
            handleChange,
            showWriteReview, 
            projectCostTypes,
            reviewerLastName,
            reviewerFirstName,
            reviewerContactNo,
            writeReviewVisbile,
            reviewerDescription,             
            reviewerProjectCost,           
            reviewerRelationship,            
            reviewerStreetAddress,
            reviewerRelationshipTypes,
            onCloseContactModalBoxHandler,    
            reviewerProjectCompletionYear,
            reviewerProjectCompletionMonth,   
            reviewerProjectCompletionYearsList,
            reviewerProjectCompletionMonthsList,
        } = this.props 
        
        return (
            <div className="supplier-profile__reviews-container">
                <header className="supplier-profile__reviews-header">
                    <button className="btn supplier-profile__write-review" onClick={showWriteReview}>+ Write a Review</button>
                </header>
                {writeReviewVisbile &&
                    <WriteReview 
                        logo={logo}
                        rating={rating}
                        touched={touched}
                        handleBlur={handleBlur}
                        errorFields={errorFields}
                        acceptTerms={acceptTerms}
                        reviwerEmail={reviwerEmail}                                                
                        handleChange={handleChange}
                        onStarChange={onStarChange}
                        submitReview={submitReview}
                        visible={writeReviewVisbile}     
                        projectCostTypes={projectCostTypes}                                                                   
                        reviewerLastName={reviewerLastName}
                        reviewerContactNo={reviewerContactNo}
                        reviewerFirstName={reviewerFirstName}
                        reviewerProjectCost={reviewerProjectCost}
                        reviewerDescription={reviewerDescription}
                        reviewerRelationship={reviewerRelationship}
                        reviewerStreetAddress={reviewerStreetAddress}
                        onCloseHandler={onCloseContactModalBoxHandler}                        
                        reviewerRelationshipTypes={reviewerRelationshipTypes}
                        reviewerProjectCompletionYear={reviewerProjectCompletionYear}                    
                        reviewerProjectCompletionMonth={reviewerProjectCompletionMonth}   
                        reviewerProjectCompletionYearsList={reviewerProjectCompletionYearsList}
                        reviewerProjectCompletionMonthsList={reviewerProjectCompletionMonthsList}
                    />}
                <div className="supplier-profile__reviews">
                    <div className="supplier-profile__reviews-section">
                        {reviews && reviews.map(review => (
                            <div key={review.id} className="supplier-profile__review">
                                <div className="supplier-profile__review-header">
                                    <div className="supplier-profile__review-company-logo">
                                        {
                                            review.profile_photo ?
                                            <img src={refactorTheProfileOrCoverImage(review.profile_photo, profilePhotosImagesUploadingPath)} alt="company logo" /> :
                                            <img src={profile_image} alt="company logo" />
                                        }                                        
                                    </div>
                                    <div className="supplier-profile__company-details">
                                        <h5 className="supplier-profile__review-name">{review.first_name + ' ' + review.last_name}</h5>
                                        {
                                            review.reviewer_job_title && 
                                                <p className="supplier-profile__review-company-name">{review.reviewer_job_title} - {review.reviewer_company}</p>
                                        }                                        
                                    </div>
                                    <div className="supplier-profile__star-content">
                                        <div className="supplier-header__star-container">
                                            {/* {review.pro && <span className="supplier-header__pro">PRO</span>} */}
                                            <StarRating stars={review.rating}></StarRating>   
                                        </div> 
                                        <label>
                                            <em>
                                                {review.created_at}
                                            </em>
                                        </label>
                                    </div>                                    
                                </div>
                                <blockquote className="supplier-profile__review-comment">{review.review_comment}</blockquote>
                            </div>))
                        }                       
                    </div>         
                </div>
            </div>
        )
    }
}