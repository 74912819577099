import BackendClient from './backendClient'

export const loadAllCountries = () => BackendClient.get('general/load-all-countries') 

export const loadAllStates = country_id => BackendClient.get(`general/load-all-states?country_id=${country_id}`) 

export const loadAllCities = (country_id, state_id) => BackendClient.get(`general/load-all-cities?country_id=${country_id}&state_id=${state_id}`) 

export const fetchRelatedSubServicesService = mainServiceId => BackendClient.get(`supplier/list-sub-categories-per-main?main_service_id=${mainServiceId}`)

export const connectOtherSupplierByEmail = (contactEmail, emailConnectFrom, emailConnectFromName, message, accessToken) => BackendClient.post(`supplier/connect-other-supplier?contact_email=${contactEmail}&email_connect_from=${emailConnectFrom}&email_connect_from_name=${emailConnectFromName}`, message, accessToken)

export const sendSubmitContactUsData = postData => BackendClient.post('supplier/contact-us', postData)