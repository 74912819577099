import React, { Component } from "react"
import { connect } from "react-redux"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import WarningIcon from "@material-ui/icons/Warning"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"

import { showNotification, hideNotification } from '../../actions/notificationActions'

import './snackbars.css'

class SnackBars extends Component {

    state = {
        vertical: 'top',
        horizontal: 'right'
    }

    openSnackBar = state => _ => {
        this.props.showNotification()
        this.setState({ open: true })
    }

    handleClose = _ => this.props.hideNotification()

    render() {

        let icon = null
        const { open, message, notificationType } = this.props
        const { vertical, horizontal } = this.state
        switch(notificationType) {
            case "error":
                icon = <ErrorIcon />
            break
            case "info":
                icon = <InfoIcon />
            break
            case "warning":
                icon = <WarningIcon />
            break
            case "success":
                icon = <CheckCircleIcon />
            break
        }
        return (
            <div>
                <Snackbar
                    open={open}
                    variant={notificationType}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical, horizontal }}
                    className={`snackbars ${notificationType}`}
                    message={<>{icon}<span>{message}</span></>}
                    bodystyle={{
                        backgroundColor: '#fff',
                        color: '#444'
                    }}
                    action={[
                        <IconButton
                            key="close"
                            color="inherit"
                            aria-label="Close"
                            onClick={this.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ notification }) => ({
    message: notification.message,
    open: notification.showNotification,
    notificationType: notification.notificationType
})

const mapDispatchToProps = dispatch => ({
    hideNotification: _ => dispatch(hideNotification()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SnackBars)
