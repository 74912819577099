import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../../actions/sessionActions'
import { loadSupplierCategoriesAll } from '../../../actions/profileActions'
import {
    fetchRelatedSubServices,
    fetchFilledSubCategoryIdsForPublic,
    fetchRelatedSuppliersForFindProOrHire,
    fetchRelatedSuppliersForFindProOrHireAsMainCategory,
    fetchAllSupliersCompanyName
} from '../../../actions/generalActions'

// import { CountryStateFilter } from '../../../reusables/CountryStateFilter/CountryStateFilter'
import { FindProCategories } from '../find-pro/FindProCategories'
import { FindProSearch } from '../find-pro/FindProSearch'
import Header from '../../Global/Header'
import Footer from '../../Global/Footer'

class FindPro extends Component {

    state = {
        searchTerm: '',
        allCategories: [],
        initialCategories: [],
        filledSubCategories: [],
        suppliersName: [],
        searchedSuppliersName: [],
    }

    componentDidMount() {
        this.fetchFindPro()
        this.props.checkLoginStatus().then(data => data)
    }

    fetchFindPro = () => {
        this.props.fetchFilledSubCategoryIdsForPublic().then(filledSubCategories => {
            this.setState({ filledSubCategories })
            this.props.loadSupplierTypesAll('FIND_A_PRO').then(initialCategories => {
                this.setState({ initialCategories: initialCategories, allCategories: initialCategories })
                this.props.fetchAllSupliersCompanyName().then(suppliersName => {
                    this.setState({ suppliersName })
                })
            })

        })


    }

    redirectToDetails = (main, mainId, sub, subId) => {
        this.props.fetchRelatedSuppliersForFindProOrHire(mainId, subId).then(relatedSupplierInfo => this.setState({ relatedSupplierInfo: relatedSupplierInfo.suppliers }))
        this.props.history.push(`/find-pro-details/${main}/${mainId}/${sub}/${subId}`)
    }

    redirectToCategories = (category_name, mainId) => {
        this.props.fetchRelatedSuppliersForFindProOrHireAsMainCategory(mainId).then(relatedSupplierInfo => this.setState({ relatedSupplierInfo: relatedSupplierInfo.suppliers }))
        this.props.history.push(`/find-pro-categories/${category_name.toLowerCase()}/${mainId}`)
    }

    redirectToSupplierPage = (supplier_name) => {
        this.props.history.push(`/supplier-public/${supplier_name}`)
    }

    setSearchTerm = value => {
        let categories = []
        let subCategories = []
        let searchedSuppliersName = []
        this.setState({ ...this.state.searchTerm, searchTerm: value })

        categories = this.state.initialCategories.filter(item => item.category_name.toLowerCase().search(value.toLowerCase()) !== -1)
        subCategories = this.state.initialCategories.filter(item => {
            if (item.category_name.toLowerCase().search(value.toLowerCase()) == -1) {
                let hasSubcategories = 0
                item.subcategories.filter(sub => {
                    if (sub.category_name.toLowerCase().search(value.toLowerCase()) !== -1) {
                        hasSubcategories++
                    }
                })
                if (hasSubcategories > 0) {
                    return item
                }
            }
        })

        searchedSuppliersName = this.state.suppliersName && this.state.suppliersName.length > 0 && this.state.suppliersName.filter(item => {
            if (item !== null && item.toLowerCase().search(value.toLowerCase()) !== -1) {
                return item;
            }
        })
        if (searchedSuppliersName && searchedSuppliersName.length > 0 && this.state.suppliersName && this.state.suppliersName.length > 0) {
            this.setState({ searchedSuppliersName: searchedSuppliersName })
        } else {
            this.setState({ searchedSuppliersName: [] })
        }


        this.setState({ allCategories: [...categories, ...subCategories] })
    }

    clearSearchTerm = () => this.setState({ searchTerm: '', allCategories: this.state.initialCategories, searchedSuppliersName: [] })

    redirectToHome = () => this.props.history.push('/find-pro')

    render() {

        const { loading } = this.props

        const {
            searchTerm,
            allCategories,
            filledSubCategories,
            searchedSuppliersName
        } = this.state


        return (
            <>
                <Header />
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>
                <div className="main">
                    {/* <CountryStateFilter /> */}
                    <FindProSearch
                        searchTerm={searchTerm}
                        setSearchTerm={this.setSearchTerm}
                        clearSearchTerm={this.clearSearchTerm}
                    />
                    {allCategories &&
                        <FindProCategories
                            categories={allCategories}
                            filledSubCategories={filledSubCategories}
                            suppliersName={searchedSuppliersName}
                            redirectToDetails={this.redirectToDetails}
                            redirectToCategories={this.redirectToCategories}
                            redirectToSupplierPage={this.redirectToSupplierPage}
                        />}
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile }) => ({
    loading: profile.loading
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    loadSupplierTypesAll: mainType => dispatch(loadSupplierCategoriesAll(mainType)),
    fetchFilledSubCategoryIdsForPublic: () => dispatch(fetchFilledSubCategoryIdsForPublic()),
    fetchRelatedSubServices: mainServiceId => dispatch(fetchRelatedSubServices(mainServiceId)),
    fetchRelatedSuppliersForFindProOrHire: (main, categoryName) => dispatch(fetchRelatedSuppliersForFindProOrHire(main, categoryName)),
    fetchRelatedSuppliersForFindProOrHireAsMainCategory: mainCat => dispatch(fetchRelatedSuppliersForFindProOrHireAsMainCategory(mainCat)),
    fetchAllSupliersCompanyName: () => dispatch(fetchAllSupliersCompanyName()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FindPro)