import React, { Component } from 'react'
import 'rodal/lib/rodal.css'

// import LandingWizard from './LandingWizard'

import logo_transparent from '../../assets/images/logo.png'
import facebookIcon from '../../assets/images/icons/icon_facebook1.png'
import instagramIcon from '../../assets/images/icons/icon_instagram1.png'
import linkedInIcon from '../../assets/images/icons/icon_linkedin1.png'
import twitterIcon from '../../assets/images/icons/icon_twitter1.png'
import pinterestIcon from '../../assets/images/icons/icon_pinterest1.png'
import './LandingScreen.css'
import '../Global/styles/header-coming-soon.css'

export default class HoldingScreen extends Component {

    state = {
        social: {
            facebook: "www.facebook.com/EventBuzz360",
            linkedin: "www.linkedin.com/company/eventbuzz360-2",
            instagram: "www.instagram.com/eventbuzz360/",
            pinterest: "www.pinterest.com.au/eventbuzz360/",
            twitter: "twitter.com/EventBuzz360"
        }
    }

    render() {
        
        const { social } = this.state

        return (
            <div id="landing-page" className="landing-page landing-page--home">
                <div className="landing-page__main">
                    <div className="landing-page__main-container">
                        <header className="header-coming-soon__header">
                            <a href="/"><img className="header-coming-soon__logo" src={logo_transparent} alt="logo" /></a>
                        </header>
                        <div className="landing-page__middle-container">
                            <div className="landing-page__wizard">
                                <header className="landing-page__middle-header">
                                    <h4 className="landing-page__middle-title">Future home of</h4>
                                    <h4 className="landing-page__middle-title">something quite</h4>
                                    <h4 className="landing-page__middle-title">Cool!</h4>
                                </header>

                                <h5 className="landing-page__middle-title">Launching 14 July</h5>
                                <p className="landing-page__middle-subtext">hello@eventbuzz360.com</p>

                            </div>                         
                        </div>
                        <footer className="header-coming-soon__footer">
                            <fieldset className="fieldset">
                                <legend className="legend">follow us on social</legend>
                                <div className="landing-page__social-media-container">
                                    <ul className="landing-page__social-media">
                                        { 
                                            social.instagram && 
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link" target="_blank" href={"http://" + social.instagram}>
                                                    <img src={instagramIcon} alt="instagram" />
                                                </a>
                                            </li>)
                                        }
                                        { 
                                            social.facebook &&
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link" target="_blank" href={"http://" + social.facebook}>
                                                    <img src={facebookIcon} alt="facebook" />
                                                </a>
                                            </li>)
                                        }
                                        { 
                                            social.linkedin &&
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link landing-page__social-media-link--small" target="_blank" href={"http://" + social.linkedin}>
                                                    <img src={linkedInIcon} alt="linkedIn" />
                                                </a>
                                            </li>)
                                        }
                                        { 
                                            social.pinterest &&
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link landing-page__social-media-link--small" target="_blank" href={"http://" + social.pinterest}>
                                                    <img src={pinterestIcon} alt="pinterest" />
                                                </a>
                                            </li>)
                                        }  
                                        { 
                                            social.twitter &&
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link" target="_blank" href={"http://" + social.twitter}>
                                                    <img src={twitterIcon} alt="twitter" />
                                                </a>
                                            </li>)
                                        }
                                    </ul>
                                </div>
                            </fieldset>
                        </footer>
                    </div>
                </div>                    
            </div>
        )
    }
}
  