import React, { Component } from 'react'

export class UserTypeSecondary extends Component {

    nextStep = () => this.props.nextStep()

    onUserTypeSelect = input => this.props.secondaryUserType(input)

    render() {

        const { userTypes, newUserType } = this.props

        return (
            <div className="login-modal-user-types user-type">
                <div className="user-type__section">
                    <header className="landing-page__middle-header">
                        <h4 className="login-modal__title landing-page__middle-title">JOIN THE BUZZ</h4>
                        <p className="landing-page__middle-subtext">What would you like to do?</p>
                    </header>
                    <fieldset className="fieldset fieldset--inverse">
                        <legend className="legend legend--user-type">
                            <button className="btn new-login__legend"
                                onClick={() => this.nextStep()}
                                disabled={newUserType ? false : true} >JOIN THE BUZZ</button>
                            <span className="legend-light">It's free</span>
                        </legend>
                        <div className="landing-page__user-type-container eb-forms">
                            {userTypes.map(userType => (
                                <div key={userType.index}
                                    className="landing-page__user-type eb-radio">
                                    <input
                                        type='radio'
                                        name="userType"
                                        id={userType.index}
                                        onChange={() => this.onUserTypeSelect(userType.index)}
                                    />
                                    <span className="checkmark"></span>
                                    <label htmlFor={userType.index}>{userType.text}</label>
                                </div>
                            ))}
                        </div>
                    </fieldset>
                </div>
                {/*<div className="user-type__section">*/}
                {/*    <header className="landing-page__middle-header">*/}
                {/*        <p className="landing-page__middle-subtext">Match to Jobs</p>*/}
                {/*    </header>*/}
                {/*    <fieldset className="fieldset fieldset--inverse">*/}
                {/*        <legend className="legend legend--user-type">*/}
                {/*            <button className="btn new-login__legend" */}
                {/*                    onClick={() => this.nextStep()}*/}
                {/*                    disabled={jobs ? false : true} >JOIN THE BUZZ</button>*/}
                {/*            <span className="legend-light">It's free</span>*/}
                {/*        </legend>*/}
                {/*        <div className="landing-page__user-type-container eb-forms">*/}
                {/*        {*/}
                {/*            userTypes.jobs.map(userType => (*/}
                {/*                <div key={userType.index} className="landing-page__user-type eb-radio">*/}
                {/*                    <input */}
                {/*                        type='radio'*/}
                {/*                        name="userType" */}
                {/*                        id={userType.index} */}
                {/*                        onChange={() => this.onUserTypeSelect(userType.index, 'jobs')} */}
                {/*                    />*/}
                {/*                    <span className="checkmark"></span>*/}
                {/*                    <label htmlFor={userType.index}>{userType.text}</label>*/}
                {/*                </div>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*        </div>*/}
                {/*    </fieldset>*/}
                {/*</div>*/}
            </div>
        )
    }
}
