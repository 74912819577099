import React, { Component } from 'react'

import ComingSoon from '../../../reusables/ComingSoon/ComingSoon'
import Header from '../../Global/Header'
import Footer from '../../Global/Footer'

class People extends Component {

    render() {

        return (
            <>
                <Header />
                <div className="main">
                    <ComingSoon />
                </div>
            </>
        )
    }
}

export default People