import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import {
    registerFormUpdate,
    checkUsernameExists,
    sendSupplierRegisterForm,
} from '../../../actions/registerActions'
import { closeModal, resetPassword, } from '../../../actions/newLoginActions'
import { hideNotification, showNotification } from '../../../actions/notificationActions'
import { authLoginWithoutPasswordAfterActivation } from '../../../actions/sessionActions'

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: 0,
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    }
})
class SignUp extends Component {

    state = {
        user: {
            firstName: '',
            lastName: '',
            company: '',
            emailAddress: '',
            password: '',
            confirmpassword: '',
            userType: ''
        },
        touched: {
            firstName: false,
            lastName: false,
            emailAddress: false,
            password: false,
            confirmpassword: false,
        },
        errorFields: {
            firstName: false,
            lastName: false,
            emailAddress: false,
            password: false,
            confirmpassword: false,
        },
        showPassword: false,
        registrationCompleted: false,
        showPasswordForConfirm: false,
        emailRule: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }

    componentDidMount() {
        this.setState({ ...this.state, user: { ...this.state.user, userType: this.props.newUserType } })
        this.props.updateForm('userType', this.props.newUserType)
    }

    handleBlur = field => this.setState({ ...this.state, touched: { ...this.state.touched, [field]: true } })

    handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword })

    handleMouseDownPassword = event => event.preventDefault()

    handleClickShowPasswordForConfirm = () => this.setState({ showPasswordForConfirm: !this.state.showPasswordForConfirm })

    handleMouseDownPasswordForConfirm = event => event.preventDefault()

    updateFormHandler = (field, value) => {
        this.setState({ ...this.state, user: { ...this.state.user, [field]: value } })
        if (field === 'emailAddress') {
            this.props.checkUsernameExists(value).then(data => {
                if (data) {
                    this.setState({ emailAddressExists: true })
                    this.props.showNotification('Email address exists!', 'error')
                    setTimeout(() => this.props.hideNotification(), 6000)
                } else {
                    this.setState({ emailAddressExists: false })
                }
            })
        }
        this.props.updateForm(field, value)
    }

    formSubmitHandler = e => {
        e.preventDefault()
        const { emailAddressExists } = this.state
        const {
            company,
            lastName,
            password,
            userType,
            firstName,
            emailAddress,
            confirmpassword
        } = this.props
        if (userType === 1) {
            if ((password) && (confirmpassword) && (emailAddress) && (firstName) && (lastName) && (userType) && (!emailAddressExists)) {
                this.props.supplierRegister(userType).then(data => {
                    if (data.status === "user_created_mail_success") {
                        // this.props.completedNotification()
                        // this.props.showNotification('Please check your mail for account activation!', 'success')
                        // this.props.showNotification('Please login in order to proceed!', 'success')
                        // The above line needs to be changed later
                        this.props.authLoginWithoutPasswordAfterActivation(emailAddress).then(data => {
                            if (data.status === "user_credentials_ok") {
                                // window.location.href = '/profile'
                                // this.props.history.push('profile')
                                this.props.closeModal()
                                document.querySelector('body').classList.remove('modal-open')
                                this.setState({ registrationCompleted: true })
                            } else {
                                this.props.showNotification('Something went wrong! Please contact support...', 'error')
                                setTimeout(() => this.props.hideNotification(), 6000)
                            }
                        })
                    }
                })
            } else {
                this.props.showNotification('Please check the registration form again!', 'error')
            }
        } else {
            if ((password) && (confirmpassword) && (emailAddress) && (firstName) && (lastName) && (company) && (userType) && (!emailAddressExists)) {
                this.props.supplierRegister(userType).then(data => {
                    if (data.status === "user_created_mail_success") {
                        this.props.completedNotification()
                        // this.props.showNotification('Please check your mail for account activation!', 'success')
                        // this.props.showNotification('Please login in order to proceed!', 'success')
                        // The above line needs to be changed later
                        // this.props.authLoginWithoutPasswordAfterActivation(emailAddress).then(data => {
                        //     if (data.status === "user_credentials_ok") {                                            
                        //         window.location.href = '/profile'
                        //         // this.props.history.push('profile')
                        //     } else {
                        //         this.props.showNotification('Something went wrong! Please contact support...', 'error')
                        //         setTimeout(() => this.props.hideNotification(), 6000)  
                        //     }
                        // })    
                    }
                })
            } else {
                this.props.showNotification('Please check the registration form again!', 'error')
            }
        }
    }

    render() {

        const {
            user,
            touched,
            emailRule,
            errorFields,
            showPassword,
            emailAddressExists,
            registrationCompleted,
            showPasswordForConfirm
        } = this.state

        const { isCompany, classes } = this.props

        if (registrationCompleted) {
            // return <Redirect to={'/profile'} />
            window.location.href = '/profile'
            // This needs to be fixed later...
        }

        return (
            <>
                <header className="login-modal__header login-modal_step1_header">
                    <h4 className="login-modal__title landing-page__middle-title">JOIN THE BUZZ</h4>
                </header>
                <h5 className="login__title">Sign Up</h5>
                <div className="login__form-container">

                    <form className="eb-forms" onSubmit={this.formSubmitHandler}>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <TextField
                                fullWidth
                                margin="small"
                                label="First Name"
                                variant="outlined"
                                value={user.firstName}
                                inputProps={{ style: { fontSize: 15 } }}
                                InputLabelProps={{ style: { fontSize: 15 } }}
                                onBlur={() => this.handleBlur('firstName')}
                                onChange={e => this.updateFormHandler('firstName', e.target.value)}
                                error={((touched.firstName && !user.firstName) || (errorFields.firstName))}
                            />
                            {((touched.firstName && !user.firstName) || (errorFields.firstName)) && <span className="error">Please Enter a First Name</span>}
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <TextField
                                fullWidth
                                margin="small"
                                label="Last Name"
                                variant="outlined"
                                value={user.lastName}
                                inputProps={{ style: { fontSize: 15 } }}
                                InputLabelProps={{ style: { fontSize: 15 } }}
                                onBlur={() => this.handleBlur('lastName')}
                                onChange={e => this.updateFormHandler('lastName', e.target.value)}
                                error={((touched.lastName && !user.lastName) || (errorFields.lastName))}
                            />
                            {((touched.lastName && !user.lastName) || (errorFields.lastName)) && <span className="error">Please Enter a valid Last Name</span>}
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            {isCompany ?
                                <>
                                    <TextField
                                        fullWidth
                                        margin="small"
                                        variant="outlined"
                                        label="Company Name"
                                        value={user.company}
                                        inputProps={{ style: { fontSize: 15 } }}
                                        InputLabelProps={{ style: { fontSize: 15 } }}
                                        onBlur={() => this.handleBlur('company')}
                                        onChange={e => this.updateFormHandler('company', e.target.value)}
                                        error={((touched.company && !user.company) || (errorFields.company))}
                                    />
                                    {((touched.company && !user.company) || (errorFields.company)) && <span className="error">Please Enter a Company name</span>}
                                </> :
                                <TextField
                                    fullWidth
                                    margin="small"
                                    variant="outlined"
                                    value={user.company}
                                    label="Company Name (optional)"
                                    inputProps={{ style: { fontSize: 15 } }}
                                    InputLabelProps={{ style: { fontSize: 15 } }}
                                    onBlur={() => this.handleBlur('company')}
                                    onChange={e => this.updateFormHandler('company', e.target.value)}
                                />
                            }
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <TextField
                                fullWidth
                                margin="small"
                                labelWidth={70}
                                variant="outlined"
                                label="Email Address"
                                value={user.emailAddress}
                                inputProps={{ style: { fontSize: 15 } }}
                                InputLabelProps={{ style: { fontSize: 15 } }}
                                onBlur={() => this.handleBlur('emailAddress')}
                                onChange={e => this.updateFormHandler('emailAddress', e.target.value)}
                                error={((touched.emailAddress && !user.emailAddress) || (errorFields.emailAddress)) || emailAddressExists}
                            />
                            {((touched.emailAddress && !user.emailAddress) || (errorFields.emailAddress)) && <span className="error">Please Enter a valid Email Address</span>}
                            {emailAddressExists && <span className="error">Email address exists. If you have forgotten your password, please reset it</span>}
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <FormControl className={clsx(classes.margin)} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">{'Password'}</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    value={user.password}
                                    id="outlined-adornment-password"
                                    inputProps={{ style: { fontSize: 15 } }}
                                    InputLabelProps={{ style: { fontSize: 15 } }}
                                    type={showPassword ? 'text' : 'password'}
                                    onBlur={() => this.handleBlur('password')}
                                    onChange={e => this.updateFormHandler('password', e.target.value)}
                                    error={((touched.password && !user.password) || (errorFields.password))}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={60}
                                />
                            </FormControl>
                            {((touched.password && !user.password) || (errorFields.password)) && <span className="error">Please Enter a valid Password</span>}
                            <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <FormControl className={clsx(classes.margin)} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">{'Confirm Password'}</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    value={user.confirmpassword}
                                    id="outlined-adornment-password"
                                    inputProps={{ style: { fontSize: 15 } }}
                                    InputLabelProps={{ style: { fontSize: 15 } }}
                                    onBlur={() => this.handleBlur('confirmpassword')}
                                    type={showPasswordForConfirm ? 'text' : 'password'}
                                    onChange={e => this.updateFormHandler('confirmpassword', e.target.value)}
                                    error={((touched.confirmpassword && !user.confirmpassword) || (errorFields.confirmpassword))}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPasswordForConfirm}
                                                onMouseDown={this.handleMouseDownPasswordForConfirm}
                                                edge="end"
                                            >
                                                {showPasswordForConfirm ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={100}
                                />
                            </FormControl>
                            {((touched.confirmpassword && !user.confirmpassword) || (errorFields.confirmpassword) || (user.confirmpassword && user.confirmpassword != user.password)) && <span className="error">Please Enter a valid Password</span>}
                            <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-gold signup__button"
                            disabled={(
                                user.firstName === "" ||
                                user.lastName === "" ||
                                user.emailAddress === "" ||
                                user.password === "" ||
                                user.confirmpassword === "" ||
                                user.password.length < 8 ||
                                user.confirmpassword.length < 8 ||
                                emailAddressExists ||
                                (!emailRule.test(user.emailAddress)) ||
                                (user.password != user.confirmpassword)
                            )}
                        >Sign Up</button>
                    </form>
                </div>
                <footer className="signup-form__footer">
                    {emailAddressExists && <p className="login-form__footer-copy">Forget your password? <a onClick={() => this.props.resetPassword()}>Reset password</a></p>}
                    <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                </footer>
            </>
        )
    }
}

const mapStateToProps = ({ register, newLogin }) => ({
    loading: register.loading,
    company: register.company,
    userType: register.userType,
    password: register.password,
    lastName: register.lastName,
    isCompany: newLogin.company,
    firstName: register.firstName,
    newUserType: newLogin.newUserType,
    emailAddress: register.emailAddress,
    confirmpassword: register.confirmpassword
})

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModal()),
    resetPassword: () => dispatch(resetPassword()),
    hideNotification: () => dispatch(hideNotification()),
    checkUsernameExists: email => dispatch(checkUsernameExists(email)),
    supplierRegister: userType => dispatch(sendSupplierRegisterForm(userType)),
    updateForm: (field, value) => dispatch(registerFormUpdate({ field, value })),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    authLoginWithoutPasswordAfterActivation: email => dispatch(authLoginWithoutPasswordAfterActivation(email)), // This code to be removed later
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(SignUp))