import React, { Component } from 'react'

export class CountryPopover extends Component {

    render() {
        const { 
            t,
            changeLanguage, 
            defaultLanguage 
        } = this.props              
        return (
            <div className="popover popover--country">
                <div className="popover__arrow"></div>
                <div className="popover__content eb-forms eb-forms--country-popover">
                    {/* <label>Country</label>
                    <div className="container">
                        <Select 
                            isClearable 
                            options={countries} 
                            onChange={this.handleChange}
                            className="country-searchable-dropdown"
                        />
                    </div> */}
                    <label>{t('LANGUAGE')}</label>
                    <select onChange={changeLanguage}>
                        <option value={'en'} selected={defaultLanguage == 'en' ? 'selected' : null}>English</option>
                        <option value={'it'} selected={defaultLanguage == 'it' ? 'selected' : null}>Italian</option>
                    </select>
                    {/* <label>Currency</label>
                    <select>
                        <option>Australian Dollar (AUD)</option>
                    </select> */}
                </div>
            </div>
        )
    }
}