import React, { Component } from 'react'
import { connect } from "react-redux"
import { TextField } from '@material-ui/core'
import { Redirect } from 'react-router-dom'

import { closeModal } from '../../../actions/newLoginActions'
import { authLogin, loginFormUpdate } from '../../../actions/sessionActions'
import { showNotification, hideNotification } from '../../../actions/notificationActions'
import { retrieveAllSupplierBusinessInformation } from '../../../actions/profileActions'

class SignIn extends Component {

    state = {
        user: {
            username: '',
            password: ''
        },
        touched: {
            username: false,
            password: false
        },
        errorFields: {
            username: false,
            password: false
        },
        signUpSuccess: false,
        nextProfileScreenUrl: ""
    }

    handleBlur = field => this.setState({ ...this.state, touched: { ...this.state.touched, [field]: true } })

    updateFormHandler = (field, value) => {
        this.setState({ ...this.state, user: {...this.state.user, [field]: value } })
        this.props.updateForm(field, value)
    }

    closePopup = () => {
        this.props.closeModal()
        document.querySelector('body').classList.remove('modal-open')
    }

    formSubmitHandler = e => {
        e.preventDefault()
        const { user } = this.state
        if ((user.username) && (user.password)) {
            this.props.authLogin().then(data => {
                if (data.status === "user_credentials_ok") {
                    this.props.retrieveAllSupplierBusinessInformation().then(profileInfo => {
                        if (parseInt(data.user_type) !== 1) {
                            if (profileInfo.company_name && profileInfo.profile_photo && profileInfo.cover_image && profileInfo.company_bio && profileInfo.country) {
                                // this.props.history.push('add-event')
                                // window.location.href = '/profile'
                                this.closePopup()
                                this.setState({ signUpSuccess: true, nextProfileScreenUrl: '/add-event' })
                            } else {
                                // this.props.history.push('profile')
                                // window.location.href = '/add-event'  
                                this.closePopup()
                                this.setState({ signUpSuccess: true, nextProfileScreenUrl: '/profile' })                              
                            }    
                        } else {
                            // window.location.href = '/gallery'
                            this.closePopup()
                            this.setState({ signUpSuccess: true, nextProfileScreenUrl: '/profile' })
                        }
                    })
                } else if (data.status === "user_credentials_wrong") {
                    this.props.showNotification('Username or password incorrect!', 'error')
                    setTimeout(() => this.props.hideNotification(), 6000)
                } else if (data.status === "user_not_activated") {
                    this.props.showNotification('Please activate your account!', 'info')
                    setTimeout(() => this.props.hideNotification(), 6000)
                } else if (data.status === "user_rejected") {
                    this.props.showNotification('Your account rejected. Please contact for system support!', 'info')
                    setTimeout(() => this.props.hideNotification(), 6000)
                } else if (data.status === "user_not_approved") {
                    this.props.showNotification('Your account is not approved yet! One of our team members will approve your account soon. Please wait', 'info')
                    setTimeout(() => this.props.hideNotification(), 6000)
                }
            })
        }
    }

    render () {

        const { 
            user, 
            touched, 
            errorFields,
            signUpSuccess,
            nextProfileScreenUrl 
        } = this.state
        if (signUpSuccess && nextProfileScreenUrl) return <Redirect to={nextProfileScreenUrl} />

        return (
            <>
                <h5 className="login__title">Sign in</h5>
                <div className="login__form-container">
                    <form className="eb-forms" onSubmit={this.formSubmitHandler}>
                        <div className="eb-forms__form-group eb-forms__form-group--login">
                            <TextField
                                fullWidth
                                margin="small"
                                labelWidth={50}
                                label="Username"   
                                variant="outlined"                             
                                value={user.username}                                
                                onBlur={() => this.handleBlur('username')}
                                inputProps={{style: {fontSize: 15}}}
                                InputLabelProps={{style: {fontSize: 15}}}
                                onChange={e => this.updateFormHandler('username', e.target.value)}
                                error={((touched.username && !user.username) || (errorFields.username))}   
                            />    
                            {((touched.username && !user.username) || (errorFields.username)) && <span className="error">Enter a valid email address</span>}
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--login">
                            <TextField
                                fullWidth
                                margin="small"
                                labelWidth={50}
                                type="password"
                                label="Password"                                
                                variant="outlined"                                
                                value={user.password}                                
                                autoComplete="current-password"
                                inputProps={{style: {fontSize: 15}}}
                                InputLabelProps={{style: {fontSize: 15}}}
                                onBlur={() => this.handleBlur('password')}
                                onChange={e => this.updateFormHandler('password', e.target.value)}
                                error={((touched.password && !user.password) || (errorFields.password))}   
                            />                              
                            {((touched.password && !user.password) || (errorFields.password)) && <span className="error">Enter a valid password</span>}
                            <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>                            
                        </div>
                        <button type="submit" className="btn btn-gold signup__button" disabled={(user.email === "" || user.password === "")}>Sign In</button>
                    </form>
                </div>
                <footer className="login-form__footer">
                    <p className="login-form__footer-copy">Forgot your password? <a onClick={() => this.props.resetPassword()}>Reset password</a></p>
                    <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                </footer>
            </>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    username: session.username,
    password: session.password,
})

const mapDispatchToProps = dispatch => ({
    authLogin: () => dispatch(authLogin()),
    closeModal: () => dispatch(closeModal()),
    hideNotification: () => dispatch(hideNotification()),
    updateForm: (field, value) => dispatch(loginFormUpdate({ field, value })),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    retrieveAllSupplierBusinessInformation: userId => dispatch(retrieveAllSupplierBusinessInformation(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
